import { Component, OnInit } from '@angular/core';
import { HomePageService } from '../../../service/home-page.service';

@Component({
	selector: 'app-plan',
	templateUrl: './plan.component.html',
	styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
	language: string;
	public keywords;
	business: any = {};
	businessSection1: any;
	desktopImageUrl: any;
	mobileImageUrl: any;
	businessSection2: any = {};
	businessTitle: any;
	businessSubTitle: any;
	businessSteps: any = [];
	businessSection3: any;
	featureList: any;
	businessTable: any = {};
	constructor(private homePageService: HomePageService) {
	}

	ngOnInit() {

		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.getBusiness();
	}
	getBusiness() {
		this.homePageService.getBusiness(this.language).subscribe(data => {
			this.business = data;
			this.businessSection1 = this.business.section1;
			this.desktopImageUrl = this.businessSection1.desktopImageUrl;
			this.mobileImageUrl = this.businessSection1.mobileImageUrl;
			this.businessSection2 = this.business.section2;
			this.businessTitle = this.businessSection2.title;
			this.businessSubTitle = this.businessSection2.subTitle;
			this.businessSteps = this.businessSection2.steps;
			this.businessSection3 = this.business.section3;
			this.featureList = this.businessSection3.featureList;
			this.businessTable = this.businessSection3.table;
		});
	}
	preventTranslate(keyword) {
		return this.keywords && this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
