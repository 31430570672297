import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { AdminService } from '../../../service/admin.service';
import { CartsServiceService } from '../../../service/carts-service.service';
import { DevicesService } from '../../../service/devices.service';
import { UploadServiceService } from '../../../service/upload-service.service';
import swal from 'sweetalert2';

@Component({
	selector: 'app-success-buy-device',
	templateUrl: './success-buy-device.component.html',
	styleUrls: ['./success-buy-device.component.scss']
})
export class SuccessBuyDeviceComponent implements OnInit {
	language: string;
	id: string;
	_id: string;
	price = 'desc';
	name: string;
	devicePrice: number;
	spec: string;
	overal: string;
	carousel: string;
	limit = 13;
	orderId: any = [];
	msgErr: string;
	msgErr1: string;
	msgSuccess: string;
	msgSuccess1: string;
	myDate = new Date().toISOString();
	bsValue = new Date();
	bsRangeValue: Date[];
	maxDate = new Date();
	dayDate = new Date();
	bsConfig: any;
	minMode = new Date();
	datepickerMode = new Date();
	getCertType: string;
	getCertNumber: string;

	device: any = [];
	brands: any = [];
	orderSimCards: any = [];
	orderDevices: any = [];
	range: any = [];
	colors: any = [];
	deviceBrand: any = [];
	value: any;
	idType: any = [];
	checkIdTypeValue: string;
	fronId: string;
	backId: string;
	photo: string;
	photo1: string;
	fronId1: string;
	backId1: string;
	frontIdImage: any = [];
	frontIdImage1: any = [];
	backIdImage: any = [];
	backIdImage1: any = [];
	photoLocal: string;
	photoLocal1: string;
	submitted = false;
	submitted1 = false;
	phone: string;
	mainPhone: string;
	profile: any = [];

	frmInformation: FormGroup;
	frmInformation1: FormGroup;
	public keywords;
	type: string;
	idType1: any;
	id1: any;
	hasPromotion: any;
	orderIds: any = [];
	hasUncompletedOrders: string;
	defualPhoto: string;
	buttonDisabled: string;
	getName: any;
	constructor(
		private devicesServices: DevicesService,
		private route: ActivatedRoute,
		private router: Router,
		private cartService: CartsServiceService,
		private fileService: UploadServiceService,
		private datePipe: DatePipe,
		private adminService: AdminService
	) {
		this.maxDate.setDate(this.maxDate.getDate() + 7);
		this.bsRangeValue = [this.dayDate, this.maxDate];
		this.myDate = this.datePipe.transform(this.dayDate, 'yyyy-MM-dd');
		this.bsConfig = Object.assign(
			{},
			{
				viewMode: 'months',
				datepickerMode: 'year'
			}
		);
	}

	ngOnInit() {
		this.buttonDisabled = '';
		// this.hasPromotion = localStorage.getItem('hasPromotion');
		this.phone = localStorage.getItem('phone');
		this.mainPhone = localStorage.getItem('main-phone');
		this.orderSimCards = JSON.parse(localStorage.getItem('orderSimCards'));
		this.orderDevices = JSON.parse(localStorage.getItem('orderDevices'));
		this.orderId = localStorage.getItem('orderId');
		this.orderIds = localStorage.getItem('orderIds');
		this.hasUncompletedOrders = localStorage.getItem('hasUncompletedOrders');
		if (this.hasUncompletedOrders === 'true') {
			this.orderId = this.orderIds;
		}
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.type = localStorage.getItem('type');
		this.defualPhoto = localStorage.getItem('photoLocal1');
		// console.log('hi', this.defualPhoto);
		this.getCertification();
		this.getDeviceBrand();
		this.getProfiles();
		this.frmInformation = new FormGroup({
			idType: new FormControl('ID Type', Validators.required),
			id: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
			sex: new FormControl('male', Validators.required),
			date: new FormControl('', Validators.required),
			frontIDPhotoUrl: new FormControl('', Validators.required),
			backIDPhotoUrl: new FormControl('', Validators.required),
			photoUrl: new FormControl('', Validators.required)
		});
		if (this.frmInformation.get('idType').value === 'ID Type') {
			$('#id, #sex, #date, #month, #year').prop('disabled', true);
			this.checkIdTypeValue = 'ID Type';
		}
		// Register with current info

		const backIDPhotoUrl = this.backId1;
		this.frmInformation1 = new FormGroup({
			idType1: new FormControl(this.getCertType, Validators.required),
			id1: new FormControl(this.getCertNumber, Validators.required),
			name1: new FormControl(this.getName, Validators.required),
			sex1: new FormControl('male', Validators.required),
			date1: new FormControl('', Validators.required),
			frontIDPhotoUrl1: new FormControl('', [Validators.required]),
			backIDPhotoUrl1: new FormControl('', [Validators.required]),
			photoUrl1: new FormControl(this.defualPhoto || '')
		});
		// console.log('frmInformation1', this.frmInformation1);
	}

	// Register with current info
	getProfiles() {
		this.adminService.getProfile(this.phone).subscribe(res => {
			this.profile = res;
			this.getCertType = this.profile.certType;
			this.getCertNumber = this.profile.certNumber;
			this.photoLocal1 = this.profile.profilePicture;
			this.getName = this.profile.custName;
			localStorage.setItem('photoLocal1', this.profile.profilePicture);
		});
	}

	/**
	 * Upload front Id image
	 * @param event
	 * @param files
	 */
	uploadFrontId1(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.frontIdImage1 = events.target.result;
				$('label[for="file-input11"] .upload-icon').css('border', 'none');
				$('label[for="file-input11"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.fronId1 = data.data[0].filename;
				this.frmInformation1.controls.frontIDPhotoUrl1.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	/**
	 * Upload back Id image
	 * @param event
	 * @param files
	 */
	uploadBackId1(event: any, files: FileList) {
		// console.log('files', files);
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.backIdImage1 = events.target.result;
				$('label[for="file-input12"] .upload-icon').css('border', 'none');
				$('label[for="file-input12"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.backId1 = data.data[0].filename;
				this.frmInformation1.controls.backIDPhotoUrl1.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	/**
	 * upload profie picture
	 * @param event
	 * @param files
	 */
	uploadPhotoDefault(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.photoLocal1 = events.target.result;
				$('label[for="file-input13"] .upload-icon').css('border', 'none');
				$('label[for="file-input13"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				const body = {
					profilePicture: data.data[0].filename
				};
				this.photo1 = data.data[0].filename;
				this.frmInformation1.controls.photoUrl1.setValue(data.data[0].filename);
			},
			err => {
				console.log(err);
			}
		);
	}

	/**
	 * Submit update order data
	 */
	updateOrders1() {
		this.msgErr1 = '';
		this.msgSuccess1 = '';
		this.submitted1 = true;
		if (this.frmInformation1.invalid) {
			return;
		}
		const date = this.frmInformation1.get('date1').value;
		const obj = {
			orderIds: this.orderId.split(','),
			profile: {
				certName: this.frmInformation1.get('idType1').value,
				idCardNumber: this.frmInformation1.get('id1').value,
				gender: this.frmInformation1.get('sex1').value,
				name: this.frmInformation1.get('name1').value,
				dateOfBirth: date.toISOString(),
				frontIDPhotoUrl: this.fronId1,
				backIDPhotoUrl: this.backId1,
				photoUrl: this.defualPhoto || this.photo1
			}
		};
		this.cartService.updateOrder(obj).subscribe(
			res => {
				swal(
					'Successful',
					this.preventTranslate('Your order has been updated successfully.'),
					'success'
				);
				$('#go-to-order').removeClass('d-none');
				$('#btn-update').addClass('disabled');
				this.buttonDisabled = 'disabled';
				// this.router.navigate(['/user/my-order']);
			},
			err => {
				this.msgErr1 = err.error.message;
				swal('Error', this.msgErr1, 'error');
			}
		);
	}
	/**
	 * Control validate form
	 */
	get g() {
		return this.frmInformation1.controls;
	}

	// Register with new ID
	/**
	 * Upload front Id image
	 * @param event
	 * @param files
	 */
	uploadFrontId(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.frontIdImage = events.target.result;
				$('label[for="file-input"] .upload-icon').css('border', 'none');
				$('label[for="file-input"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.fronId = data.data[0].filename;
				this.frmInformation.controls.frontIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}

	/**
	 * Upload back Id image
	 * @param event
	 * @param files
	 */
	uploadBackId(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.backIdImage = events.target.result;
				$('label[for="file-input1"] .upload-icon').css('border', 'none');
				$('label[for="file-input1"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.backId = data.data[0].filename;
				this.frmInformation.controls.backIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}

	/**
	 * upload profie picture
	 * @param event
	 * @param files
	 */
	uploadPhoto(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.photoLocal = events.target.result;
				$('label[for="file-input2"] .upload-icon').css('border', 'none');
				$('label[for="file-input2"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.photo = data.data[0].filename;
				this.frmInformation.controls.photoUrl.setValue(data.data[0].filename);
			},
			err => {
				console.log(err);
			}
		);
	}

	/**
	 * Check correct selected Id type
	 */
	checkIdType() {
		this.checkIdTypeValue = this.frmInformation.get('idType').value;
		if (this.frmInformation.get('idType').value === 'ID Type') {
			$('#id, #sex, #date, #month, #year').prop('disabled', true);
		} else {
			$('#id, #sex, #date, #month, #year').prop('disabled', false);
		}
	}

	/**
	 * Submit update order data
	 */
	updateOrders() {
		this.msgErr = '';
		this.msgSuccess = '';
		this.submitted = true;
		if (this.frmInformation.invalid || this.checkIdTypeValue === 'ID Type') {
			return;
		}
		const date = this.frmInformation.get('date').value;
		// if (this.hasPromotion === 'true') {
		//     this.hasPromotion = 'true';
		// }
		const obj = {
			orderIds: this.orderId.split(','),
			profile: {
				dateOfBirth: date.toISOString(),
				gender: this.frmInformation.get('sex').value,
				certName: this.frmInformation.get('idType').value,
				idCardNumber: this.frmInformation.get('id').value,
				name: this.frmInformation.get('name').value,
				frontIDPhotoUrl: this.fronId,
				backIDPhotoUrl: this.backId,
				photoUrl: this.photo
				// hasPromotion: this.hasPromotion
			}
		};
		this.cartService.updateOrder(obj).subscribe(
			res => {
				swal(
					'Successful',
					this.preventTranslate('Your order has been updated successfully.'),
					'success'
				);
				$('#go-to-order').removeClass('d-none');
				$('#btn-update').addClass('disabled');
				this.buttonDisabled = 'disabled';
				// this.router.navigate(['/user/my-order']);
			},
			err => {
				console.log(err);
				this.msgErr = err.error.message;
				swal('Error', this.msgErr, 'error');
			}
		);
	}

	/**
	 * Control validate form
	 */
	get f() {
		return this.frmInformation.controls;
	}

	InternetStartDate(val): void {
		this.myDate = this.datePipe.transform(val, 'yyyy-MM-dd');
		// tslint:disable-next-line:quotemark
	}

	/**
	 * Get Id type from backend
	 */
	getCertification() {
		this.cartService.getCertification().subscribe(
			res => {
				this.idType = res;
			},
			err => {
				console.log(err);
			}
		);
	}

	/**
	 * Get related device
	 */
	getDeviceBrand() {
		if (this.orderDevices) {
			if (this.orderDevices.length === 0) {
				return;
			}
			this.name = this.orderDevices[0].brand;
		} else {
			return;
		}

		this.range = [0, 2000];
		this.devicesServices
			.getDevicesByBrand(
				this.language,
				this.range,
				this.price,
				this.name,
				this.limit
			)
			.subscribe(res => {
				this.deviceBrand = res.data;
				// tslint:disable-next-line:forin
				for (const i in this.deviceBrand) {
					if (this.deviceBrand[i]._id === this._id) {
						this.deviceBrand.splice(
							this.deviceBrand.indexOf(this.deviceBrand[i]),
							1
						);
					}
				}
			});
	}

	/**
	 * Prevent stranslate
	 * @param keyword
	 */
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
