import { Injectable } from '@angular/core';
import { GlobleService } from './globle.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RegisterServiceService {
	url = this.api.apiUrl;
	header: any = this.api.getHeader();

	constructor(private api: GlobleService, private http: HttpClient) { }

	/**
	 * Request code for submit
	 * @param body
	 */
	requestCode(body: any): Observable<any> {
		return this.http.post(`${this.url}/v2/seatel/request-code`, body, {
			headers: this.header
		});
	}

	/**
	 * Verify code for check code
	 * @param body
	 */
	verifyCode(body) {
		return this.http.post(`${this.url}/seatel/verify-code`, body, {
			headers: this.header
		});
	}

	/**
	 * Confirm code for submit
	 * @param body
	 */
	confirmCode(body) {
		return this.http.post(`${this.url}/v2/seatel/confirm-code`, body, {
			headers: this.header
		});
	}
	getCertification() {
		return this.http.get(this.url + '/certifications', {
			headers: this.header
		});
	}
	getLanguages() {
		return this.http.get(this.url + '/languages', {
			headers: this.header
		});
	}
	registerProfile(body) {
		return this.http.post(`${this.url}/seatel/register-profile`, body, {
			headers: this.header
		});
	}
	downloadRegister(body): Observable<any> {
		return this.http
			.post(`${this.url}/registration-profiles/pdf`, body, {
				headers: this.header
			});
	}
}
