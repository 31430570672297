import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoykobComponent } from './loy-kob.component';
import { addOnPackageComponent } from './add-on-package/add-on-package.component';
import { LoyKobPageComponent } from './loy-kob-page/loy-kob-page.component';
// import { tariffComponent } from './tariff/tariff.component';

const routes: Routes = [
	{
		path: '',
		component: LoykobComponent,
		children: [
			{ path: '', component: LoyKobPageComponent },
			{ path: 'add-on-packages', component: addOnPackageComponent },
			// { path: 'standard-tariff', component: tariffComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
	declarations: []
})
export class LoykobRoutingModule {}
