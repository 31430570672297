import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-unlimited',
  templateUrl: './instagram-unlimited.component.html',
  styleUrls: ['./instagram-unlimited.component.scss']
})
export class InstagramUnlimitedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
