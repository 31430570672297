import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { Observable } from "rxjs/Observable";
import { catchError } from "rxjs/operators";

import { GlobleService } from "./globle.service";

@Injectable()
export class HomePageService {
	rootUrl: string = this.globalService.apiUrl;
	header: any = this.globalService.getHeader();
	private i = 0;

	constructor(private http: HttpClient, private globalService: GlobleService) {}

	/**
	 * Get Mobile App Section in Home and Personal pages
	 * @param lang
	 */
	getMobileApp(lang): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/mobile-app?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getKeywords(): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/keywords`, { headers: this.header })
			.pipe(catchError(this.handleError.bind(this)));
	}
	getHomePage(lang): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/homepage?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	getSearches(inputKeyword: string, language, offset, limit): Observable<any> {
		return this.http
			.get(
				`${this.rootUrl}/searches?keyword=${inputKeyword}&lang=${language}&offset=${offset}&limit=${limit}`,
				{ headers: this.header }
			)
			.pipe(catchError(this.handleError.bind(this)));
	}
	public getJSON(): Observable<any> {
		return this.http.get("./assets/roaming.json");
	}
	public internationalJSON(): Observable<any> {
		return this.http.get("./assets/international-call.json");
	}
	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// return an ErrorObservable with a user-facing error message
		return throwError("Something bad happened; please try again later.");
	}

	getBanners(lang) {
		return this.http
			.get(`${this.rootUrl}/banners?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	getPlans(lang) {
		return this.http
			.get(`${this.rootUrl}/plans?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getPromotions(lang) {
		return this.http
			.get(`${this.rootUrl}/promotions?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	getMain() {
		return this.http
			.get(`${this.rootUrl}/main`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	postAppUsages(data) {
		// return this.http
		// 	.post(`${this.rootUrl}/app-usages/track`, data, {
		// 		headers: this.header
		// 	})
		// 	.pipe(catchError(this.handleError.bind(this)));
	}
	subscibeEmail(data): Observable<any> {
		return this.http
			.post(`${this.rootUrl}/subscribers`, data, {
				headers: this.header
			})
			.pipe(catchError(this.handleError));
	}
	getPrivacyPolicy(lang) {
		return this.http
			.get(
				`${this.rootUrl}/pages/privacy-policy-main/privacy-policy?lang=${lang}`,
				{
					headers: this.header
				}
			)
			.pipe(catchError(this.handleError.bind(this)));
	}
	getBusiness(lang): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/business-main/business?lang=${lang}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getActivePopUp(): Observable<any> {
		const popUp = this.http
			.get(`${this.rootUrl}/promotions/pop-up-active`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
		return popUp;
	}

	getMenu() {
		return this.http
			.get(`${this.rootUrl}/admin/appearance/get-web-menu`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getDetailMenu() {
		// const status = 'status=active'
		return this.http
			.get(`${this.rootUrl}/admin/appearance`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	// getWebDetailMenu() {
	// 	return this.http.get
	// }
}
