import { Component, OnInit } from '@angular/core';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Router } from '@angular/router';
import { FindShopService } from '../../../service/find-shop.service';
import { TranslateService } from '@ngx-translate/core';
import {
	FormGroup,
	Validators,
	FormBuilder,
	FormControl
} from '@angular/forms';

@Pipe({
	name: 'search'
})
@Injectable()
export class SearchPipe implements PipeTransform {
	transform(inputKeyword: any[], field: string): any[] {
		if (!field) {
			return [];
		}
		field = field.toLowerCase();
		return inputKeyword.filter(function (item) {
			return item.value.toLowerCase().includes(field);
		});
	}
}
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	addFeedback: FormGroup;
	language: string;
	inputKeyword = '';
	dataSearch = [];
	required: string;
	requiredFeedback: string;
	submitted = false;

	emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
	phonePattern = /^\d+$/;
	public keywords;

	provinces = [];
	keyword = {
		desktopUrl: {
			en: '/assets/support/SupportBannerpage.jpg',
			km: '/assets/support/SupportBannerpage-KH.jpg',
			zh: '/assets/support/SupportBannerpage.jpg'
		},
		mobileUrl: {
			en:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg',
			km:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-36.jpg',
			zh: '/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg'
		}
	};
	support: any = [];
	supportSection1: any = [];
	supportSection2: any = [];
	supportSection3: any = [];
	constructor(
		private router: Router,
		private shopService: FindShopService,
		private translate: TranslateService,
		private formBuilder: FormBuilder
	) {
		const getLanguage = localStorage.getItem('language') || 'en';
		translate.setDefaultLang(getLanguage);
		translate.use(getLanguage);
		localStorage.setItem('language', getLanguage);
		this.language = getLanguage;

		if (this.language === 'en') {
			this.provinces = [
				{ label: 'Phnom Penh', value: 'Phnom Penh' },
				{ label: 'Banteay Meanchey', value: 'Banteay Meanchey' },
				{ label: 'Battambang', value: 'Battambang' },
				{ label: 'Kampong Cham', value: 'Kampong Cham' },
				{ label: 'Kampong Chhnang', value: 'Kampong Chhnang' },
				{ label: 'Kampong Speu', value: 'Kampong Speu' },
				{ label: 'Kampong Thom', value: 'Kampong Thom' },
				{ label: 'Kampot', value: 'Kampot' },
				{ label: 'Kandal', value: 'Kandal' },
				{ label: 'Koh Kong', value: 'Koh Kong' },
				{ label: 'Kep', value: 'Kep' },
				{ label: 'Kratié', value: 'Kratié' },
				{ label: 'Mondulkiri', value: 'Mondulkiri' },
				{ label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
				{ label: 'Pailin', value: 'Pailin' },
				{ label: 'Preah Sihanouk', value: 'Preah Sihanouk' },
				{ label: 'Preah Vihear', value: 'Preah Vihear' },
				{ label: 'Pursat', value: 'Pursat' },
				{ label: 'Prey Veng', value: 'Prey Veng' },
				{ label: 'Ratanakiri', value: 'Ratanakiri' },
				{ label: 'Siem Reap', value: 'Siem Reap' },
				{ label: 'Stung Treng', value: 'Stung Treng' },
				{ label: 'Svay Rieng', value: 'Svay Rieng' },
				{ label: 'Takéo', value: 'Takéo' },
				{ label: 'Tboung Khmum', value: 'Tboung Khmum' }
			];
		} else if (this.language === 'km') {
			this.provinces = [
				{ label: 'ភ្នំពេញ', value: 'Phnom Penh' },
				{ label: 'បន្ទាយមានជ័យ', value: 'Banteay Meanchey' },
				{ label: 'បាត់ដំបង', value: 'Battambang' },
				{ label: 'កំពង់ចាម', value: 'Kampong Cham' },
				{ label: 'កំពង់ឆ្នាំង', value: 'Kampong Chhnang' },
				{ label: 'កំពង់ស្ពឺ', value: 'Kampong Speu' },
				{ label: 'កំពង់ធំ', value: 'Kampong Thom' },
				{ label: 'កំពត', value: 'Kampot' },
				{ label: 'កណ្តាល', value: 'Kandal' },
				{ label: 'កោះកុង', value: 'Koh Kong' },
				{ label: 'កែប', value: 'Kep' },
				{ label: 'ក្រចេះ', value: 'Kratié' },
				{ label: 'មណ្ឌលគីរី', value: 'Mondulkiri' },
				{ label: 'ឧត្តរមានជ័យ', value: 'Oddar Meanchey' },
				{ label: 'បៃលិន', value: 'Pailin' },
				{ label: 'ព្រះសីហនុ', value: 'Preah Sihanouk' },
				{ label: 'ព្រះវិហារ', value: 'Preah Vihear' },
				{ label: 'ពោធិ៍សាត់', value: 'Pursat' },
				{ label: 'ព្រៃវែង', value: 'Prey Veng' },
				{ label: 'រតនគីរី', value: 'Ratanakiri' },
				{ label: 'សៀមរាប', value: 'Siem Reap' },
				{ label: 'ស្ទឹងត្រែង', value: 'Stung Treng' },
				{ label: 'ស្វាយរៀង', value: 'Svay Rieng' },
				{ label: 'តាកែវ', value: 'Takéo' },
				{ label: 'ត្បូងឃ្មុំ', value: 'Tboung Khmum' }
			];
		} else if (this.language === 'zh') {
			this.provinces = [
				{ label: '金边', value: 'Phnom Penh' },
				{ label: '波比', value: 'Banteay Meanchey' },
				{ label: '马德望', value: 'Battambang' },
				{ label: '甘榜湛', value: 'Kampong Cham' },
				{ label: '磅清扬', value: 'Kampong Chhnang' },
				{ label: '磅士碑', value: 'Kampong Speu' },
				{ label: '磅通', value: 'Kampong Thom' },
				{ label: '贡布', value: 'Kampot' },
				{ label: '甘丹', value: 'Kandal' },
				{ label: '戈公', value: 'Koh Kong' },
				{ label: '白马', value: 'Kep' },
				{ label: '桔井', value: 'Kratié' },
				{ label: '蒙多基里省', value: 'Mondulkiri' },
				{ label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
				{ label: '拜林', value: 'Pailin' },
				{ label: '西哈努克', value: 'Preah Sihanouk' },
				{ label: '柏威夏', value: 'Preah Vihear' },
				{ label: '菩萨', value: 'Pursat' },
				{ label: '猎物冯', value: 'Prey Veng' },
				{ label: '腊塔纳基里', value: 'Ratanakiri' },
				{ label: '暹粒市', value: 'Siem Reap' },
				{ label: '特本克蒙', value: 'Stung Treng' },
				{ label: '甘拉', value: 'Svay Rieng' },
				{ label: '猛男', value: 'Takéo' },
				{ label: '特本克蒙', value: 'Tboung Khmum' }
			];
		}
	}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		// this.dataSearch = this.provinces;
		this.getSupport();

		this.addFeedback = new FormGroup({
			email: new FormControl('', [
				Validators.required,
				Validators.pattern(this.emailPattern)
			]),
			contactNumber: new FormControl('', [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			fullName: new FormControl('', Validators.required),
			message: new FormControl('', Validators.required)
		});
	}
	getSupport() {
		this.shopService.getSupport(this.language).subscribe(data => {
			this.support = data;
			this.supportSection1 = this.support.section1;
			this.supportSection2 = this.support.section2;
			this.supportSection3 = this.support.section3;
		});
	}
	/**
	 * Control validate form
	 */
	get f() {
		return this.addFeedback.controls;
	}

	/**
	 * Get search value and go to find shop page
	 * @param inputKeyword
	 */
	goPlaces(inputKeyword) {
		if (inputKeyword === '') {
			this.required = 'required';

			return;
		} else {
			this.router.navigate(['/support/find-shop'], {
				queryParams: { localArea: inputKeyword }
			});
		}
	}

	/**
	 * Validation on search location
	 * @param inputKeyword
	 */
	checkRequired(inputKeyword) {
		if (inputKeyword === '') {
			this.required = 'required';
		} else {
			this.required = '';
			this.dataSearch = this.provinces;
		}
	}

	/**
	 * Check validate when change location
	 * @param event
	 */
	selectedChange(event) {
		if (event === '') {
			this.required = 'required';
		} else {
			this.required = '';
		}
	}

	/**
	 * Submit form feed back with backend
	 */
	onSubmit() {
		this.submitted = true;

		// stop here if form is invalid
		if (this.addFeedback.invalid) {
			return;
		}

		if (this.addFeedback.valid) {
			this.shopService.feedback(this.addFeedback.value).subscribe(res => {
				this.submitted = false;
				$('#feedback-load').trigger('click');
				this.addFeedback.reset();
			});
		}
	}

	/**
	 * Prevent translate from backend
	 * @param keyword
	 */
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}

	/**
	 * Prevent translate in local
	 * @param keyword
	 */
	preventTranslateLocal(keyword) {
		return this.keyword[keyword]
			? this.keyword[keyword][this.language]
			: keyword;
	}
}
