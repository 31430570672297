import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-special-number',
    templateUrl: './special-number.component.html',
    styleUrls: ['./special-number.component.scss']
})
export class SpecialNumberComponent implements OnInit {
		desktopImage: string;
		mobileImage: string;
		descriptionImageUrl: string;
		list: any = [];
		title: string;

		mainSpecialNumber: any = [];
		mainSpecialNumberSection1: any = [];
		mainSpecialNumberSection2: any = [];

    language: string;
    public keywords;

		constructor(
				private translate: TranslateService,
				private productService: ProductsServicesService,
		) {
			const getLanguage = localStorage.getItem('language') || 'en';
			translate.setDefaultLang(getLanguage);
			translate.use(getLanguage);
			localStorage.setItem('language', getLanguage);
			this.language = getLanguage;
		}
	
    ngOnInit() {
			this.getSpecialSim();
			this.language = localStorage.getItem('language');
			this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		}

		preventTranslate(keywords) {
			return this.keywords && this.keywords[keywords]
				? this.keywords[keywords][this.language]
				: keywords;
		}

		getSpecialSim() {
			this.productService.getSpecialNumberPrice(this.language).subscribe(data => {
				console.log(data);
				this.mainSpecialNumber = data;
				this.mainSpecialNumberSection1 = this.mainSpecialNumber.section1;
				this.mainSpecialNumberSection2 = this.mainSpecialNumber.section2;
				this.list = this.mainSpecialNumberSection2.list;
				this.descriptionImageUrl = this.mainSpecialNumberSection2.descriptionImageUrl;
				this.desktopImage = this.mainSpecialNumberSection1.desktopImageUrl;
				this.mobileImage = this.mainSpecialNumberSection1.mobileImageUrl;
				this.title = this.mainSpecialNumberSection1.title;
			});
		}
}
