import { Component, OnInit, HostListener } from '@angular/core';

@Component({
	selector: 'app-products-services',
	templateUrl: './products-services.component.html',
	styleUrls: ['./products-services.component.scss']
})
export class ProductsServicesComponent implements OnInit {
	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;

	constructor() {

	}
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}

	ngOnInit() {
		localStorage.removeItem('hasPhoneNumber');
		localStorage.removeItem('hasProfile');
		localStorage.removeItem('hasCode');
	}


}
