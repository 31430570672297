import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobleService } from './globle.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetLuckyDrawChristmasService {
  rootUrl: string = this.globalService.apiUrl;
  header: any = this.globalService.getHeader();

  constructor(	private http: HttpClient, private globalService: GlobleService) { }
  private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.log('error', error.error);
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}

		// return an ErrorObservable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}
	/**
	 * Submit feedback support page
	 * @param phonenumber
	 */
  getDataLuckyDraw(phonenumber): Observable<any> {
    return this.http.get(`http://103.242.56.228/api/lucky-numbers/draw-result?platform=yes-web&search=${phonenumber}`,
      {
        headers: new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer 3|kE0rceMh73EClS7vTX8AMh0WA8FxGPI7ly6fdzEo'})
      }
    ).pipe(catchError(this.handleError.bind(this)));
  }
}
