import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DevicesService } from '../../../service/devices.service';

@Component({
	selector: 'app-pre-order-success',
	templateUrl: './pre-order-success.component.html',
	styleUrls: ['./pre-order-success.component.scss']
})
export class PreOrderSuccessComponent implements OnInit {
	language: string;
	public keywords;
	preOrder: any = [];
	allTotal: number;
	totalItems: number;
	totalQtyItems: number;
	orderId: any;
	pickupDateTime: string;
	order: any;
	constructor(private devicesService: DevicesService, private router: Router) {}

	ngOnInit() {
		this.language = localStorage.getItem('language') || 'en';
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.preOrder = JSON.parse(localStorage.getItem('remarkPreOrder'));
		this.orderId = localStorage.getItem('orderId');
		if (!this.preOrder) {
			this.router.navigate(['/']);
		}
	}
	savePDF(e) {
		const type = this.preOrder.type;
		// TODO: update later
	}
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
