import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../../../service/devices.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-success',
	templateUrl: './success.component.html',
	styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
	language: string;
	public keywords;
	order: any = [];
	allTotal: number;
	getorderTouristPass: any;
	getdeviceForRent: any;
	totalItems: number;
	totalrentItems: number;
	totalQtyItems: number;
	totalQtyrentItems: number;
	orderId: any;
	pickupDateTime: string;
	packType: string;
	getFee: string;
	getData: string;
	getValuePhone: string;
	constructor(private devicesService: DevicesService, private router: Router) { }

	ngOnInit() {
		this.language = localStorage.getItem('language') || 'en';
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.order = JSON.parse(localStorage.getItem('order'));
		this.packType = localStorage.getItem('packType');
		this.getFee = localStorage.getItem('fee');
		this.getData = localStorage.getItem('data');
		this.getValuePhone = localStorage.getItem('phone');
		this.getorderTouristPass = JSON.parse(localStorage.getItem('orderTouristPass'));
		if (!this.getorderTouristPass && !this.order) {
			this.router.navigate(['/']);
		}
		this.getdeviceForRent = JSON.parse(localStorage.getItem('deviceForRent'));
		this.pickupDateTime = localStorage.getItem('pickupDateTime');
		this.totalItems = this.getorderTouristPass.reduce((sum, item) => sum + item.total, 0);
		this.totalQtyItems = this.getorderTouristPass.reduce((sum, item) => sum + item.quantity, 0);
		this.totalrentItems = this.getdeviceForRent.reduce((sum, item) => sum + item.total, 0);
		this.totalQtyrentItems = this.getdeviceForRent.reduce((sum, item) => sum + item.quantity, 0);
		this.allTotal = Number(this.totalrentItems + this.totalItems);
		this.orderId = localStorage.getItem('orderId');


	}
	savePDF(e) {
		const type = this.order.type;
		this.devicesService.touristsPassSaveOrderDetail(this.orderId, type).subscribe(data => {
			window.open(data.link);
		});
	}
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
