import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { Meta } from '@angular/platform-browser';
declare var window: any;

@Component({
	selector: 'app-lucky-angpao',
	templateUrl: './lucky-angpao.component.html',
	styleUrls: ['./lucky-angpao.component.scss']
})
export class LuckyAngpaoComponent implements OnInit {
	language: string;
	keywords: any = {};
	hideContent = true;
	youTubeStar: any;
	getbanner: any;
	desktopImageUrl: string;
	mobileImageUrl: string;
	section2: any;
	section2description: string;
	section2desktopImageUrl: string;
	section3: any;
	section3description: string;
	section4: any;
	section4desktopImageUrl: string;
	section4mobileImageUrl: string;
	section4description: string;
	section3desktopImageUrl: any = {};
	section3mobileImageUrl: string;
	keyword: any = {};
	host: string = window.location.origin;
	angpaoStepIntoText: string;
	bannerImageDesktop: string;
	bannerImageMobile: string;
	contentMetaTitle: string;
	contentMetaUrl: string = window.location.href;
	contentMetaImageUrl: string;

	angpaoText = {
		en:
			'Starting from 01 – 15 February, yes is celebrating Chinese New Year with prizes giveaway for 15 days via yes app and yes website. All you need to do is do your best to catch amazing prizes in our rainfall ang pao.',
		km:
			'ចាប់ពីថ្ងៃ 01-15 ខែកុម្ភះ yesចូលរួមប្រារព្វអបអរសាទរពិធីបុណ្យចូលឆ្នាំថ្មី ប្រពៃណីចិន ជាមួយ​ការ​ចែកអាំងប៉ាវយ៉ាងសន្ធឹកសន្ធាប់ពេញ 15ថ្ងៃ នៅលើ yes app និងវេបសាយ។ អ្នកត្រូវការត្រឹមតែសំណាង​ប៉ុណ្ណោះក្នុងការចូលរួមផ្សងចាប់រង្វាន់ពីអាំងប៉ាវលាភ។		',
		zh:
			'Starting from 01 – 15 February, yes is celebrating Chinese New Year with prizes giveaway for 15 days via yes app and yes website. All you need to do is do your best to catch amazing prizes in our rainfall ang pao.'
	};
	metaTitle = {
		en: 'Catch amazing prizes in our rainfall ang pao',
		km: 'ចាប់ឈ្នះ ចាប់ឈ្នះ រង្វាន់ក្នុងអាំងប៉ាវលាភនៅលើyes appនិងវេបសាយ',
		zh: 'Catch amazing prizes in our rainfall ang pao'
	};
	metaImage = {
		en: '/assets/lucky-angpao/mobile/EN-Homepage-Mobile[1080X1500].jpg',
		km: '/assets/lucky-angpao/mobile/KH-Homepage-Mobile[1080X1500].jpg',
		zh: '/assets/lucky-angpao/mobile/EN-Homepage-Mobile[1080X1500].jpg'
	};
	banner = {
		desktopUrl: {
			en: '/assets/lucky-angpao/EN-Subpage-1920x450px.jpg',
			km: '/assets/lucky-angpao/KH-Subpage-1920x450px.jpg',
			zh: '/assets/lucky-angpao/EN-Subpage-1920x450px.jpg'
		},
		mobileUrl: {
			en: '/assets/lucky-angpao/mobile/EN-Sub-Page1[1080x900].jpg',
			km: '/assets/lucky-angpao/mobile/KH-Sub-Page1[1080x900].jpg',
			zh: '/assets/lucky-angpao/mobile/EN-Sub-Page1[1080x900].jpg'
		}
	};
	angpaoStepImageDesktop = {
		en: '/assets/lucky-angpao/Table-[EN].png',
		km: '/assets/lucky-angpao/Table-[KH].png',
		zh: '/assets/lucky-angpao/Table-[EN].png'
	};

	angpaoStepImageMobile = {
		en: '/assets/lucky-angpao/mobile/EN-Sub-Page2.jpg',
		km: '/assets/lucky-angpao/mobile/KH-Sub-Page2.jpg',
		zh: '/assets/lucky-angpao/mobile/EN-Sub-Page2.jpg'
	};

	angpaoConditionImageDesktop = {
		en: '/assets/lucky-angpao/EN-Website-TC-Background.jpg',
		km: '/assets/lucky-angpao/KH-Website-TC-Background.jpg',
		zh: '/assets/lucky-angpao/EN-Website-TC-Background.jpg'
	};

	angpaoConditionImageMobile = {
		en: '/assets/lucky-angpao/mobile/EN-Sub-Page3.jpg',
		km: '/assets/lucky-angpao/mobile/EN-Sub-Page3.jpg',
		zh: '/assets/lucky-angpao/mobile/EN-Sub-Page3.jpg'
	};

	constructor(
		private productService: ProductsServicesService,
		private meta: Meta
	) {
		$('title').remove();
		$('<title>' + this.metaTitle.en + '"></title>').insertAfter('base');

		this.meta.updateTag({
			name: 'description',
			content: this.angpaoText.en
		});

		this.meta.updateTag({
			property: 'og:url',
			content: this.contentMetaUrl
		});
		this.meta.updateTag({
			property: 'og:type',
			content: 'website'
		});
		this.meta.updateTag({
			property: 'og:title',
			content: this.metaTitle.en
		});
		this.meta.updateTag({
			property: 'og:description',
			content: this.angpaoText.en
		});
		this.meta.updateTag({
			property: 'og:image',
			content: this.host + this.banner.desktopUrl.en
		});
		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|aba|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
			if (window.location.href.indexOf('play') <= -1) window.location.href = "https://appurl.io/jlhes9xx";
		}
	}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.getContentByLanguage();
	}

	getContentByLanguage() {
		switch (this.language) {
			case 'en': {
				this.bannerImageDesktop = this.banner.desktopUrl.en;
				this.bannerImageMobile = this.banner.mobileUrl.en;
				this.angpaoStepIntoText = this.angpaoText.en;
				this.section3desktopImageUrl = this.angpaoStepImageDesktop.en;
				this.section3mobileImageUrl = this.angpaoStepImageMobile.en;
				this.section4desktopImageUrl = this.angpaoConditionImageDesktop.en;
				this.section4mobileImageUrl = this.angpaoConditionImageMobile.en;
				break;
			}
			case 'km': {
				this.bannerImageDesktop = this.banner.desktopUrl.km;
				this.bannerImageMobile = this.banner.mobileUrl.km;
				this.angpaoStepIntoText = this.angpaoText.km;
				this.section3desktopImageUrl = this.angpaoStepImageDesktop.km;
				this.section3mobileImageUrl = this.angpaoStepImageMobile.km;
				this.section4desktopImageUrl = this.angpaoConditionImageDesktop.km;
				this.section4mobileImageUrl = this.angpaoConditionImageMobile.km;
				break;
			}
			case 'zh': {
				this.bannerImageDesktop = this.banner.desktopUrl.en;
				this.bannerImageMobile = this.banner.mobileUrl.en;
				this.angpaoStepIntoText = this.angpaoText.en;
				this.section3desktopImageUrl = this.angpaoStepImageDesktop.en;
				this.section3mobileImageUrl = this.angpaoStepImageMobile.en;
				this.section4desktopImageUrl = this.angpaoConditionImageDesktop.en;
				this.section4mobileImageUrl = this.angpaoConditionImageMobile.en;
				break;
			}
			default: {
				this.bannerImageDesktop = this.banner.desktopUrl.en;
				this.bannerImageMobile = this.banner.mobileUrl.en;
				this.angpaoStepIntoText = this.angpaoText.en;
				this.section3desktopImageUrl = this.angpaoStepImageDesktop.en;
				this.section3mobileImageUrl = this.angpaoStepImageMobile.en;
				this.section4desktopImageUrl = this.angpaoConditionImageDesktop.en;
				this.section4mobileImageUrl = this.angpaoConditionImageMobile.en;
			}
		}

		$('.free-data-bg').css(
			'background-image',
			`url(${this.section4desktopImageUrl})`
		);
	}
	preventTranslate(keywords) {
		if (this.keywords) {
			if (this.keywords[keywords]) {
				return this.keywords[keywords][this.language];
			} else {
				return keywords;
			}
		} else {
			return keywords;
		}
	}
	preventTranslateLocal(keyword) {
		return this.keyword[keyword]
			? this.keyword[keyword][this.language]
			: keyword;
	}
}
