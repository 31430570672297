import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { FaqService } from '../../../service/faq.service';
import { HomePageService } from '../../../service/home-page.service';

@Component({
	selector: 'app-faq-answer',
	templateUrl: './faq-answer.component.html',
	styleUrls: ['./faq-answer.component.scss']
})
export class FaqAnswerComponent implements OnInit {
	@ViewChild('showId', { static: true })
	showId: ElementRef;
	getId: any = {};
	keywords: any = {};
	language: string;

	faqCategories: any = [];
	questionAnswers: any = [];
	activeTabId: '';
	questionId = '';
	activeAnswer: any = [];
	activeAnswerIndex = 1;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private homePageService: HomePageService,
		private faqService: FaqService
	) {}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.keywords.desktopUrl = {
			en: '/assets/support/SupportBannerpage.jpg',
			km: '/assets/support/SupportBannerpage-KH.jpg',
			zh: '/assets/support/SupportBannerpage.jpg'
		};
		this.keywords.mobileUrl = {
			en:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg',
			km:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-36.jpg',
			zh: '/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg'
		};
		this.getParam();
		this.getFaqCategory();
	}

	getParam() {
		this.route.queryParams.subscribe(params => {
			this.getId = params['id'];
			this.questionId = params['questionId'];
			this.getQuestionAnswerByCategoryId(this.getId, this.questionId);
		});
	}

	getFaqCategory() {
		this.faqService.getFaqCategory(this.language).subscribe(res => {
			this.faqCategories = res.data;
			this.activeTabId = this.getId;
		});
	}

	getQuestionAnswerByCategoryId(categoryId, questionId) {
		this.getId = categoryId;
		this.questionId = questionId;
		this.router.navigate(['/faq/category'], {
			queryParams: { id: this.getId, questionId: this.questionId }
		});
		this.faqService
			.getFaqByCategoryId(this.getId, this.language)
			.subscribe(res => {
				this.questionAnswers = res.data;
				this.activeAnswer = this.questionAnswers.filter(
					x => x._id === this.questionId
				)[0];
				const tempIndex = this.questionAnswers.findIndex(
					x => x._id === this.questionId
				);
				this.activeAnswerIndex = tempIndex + 1;

				if (!this.activeAnswer) {
					this.activeAnswerIndex = 1;
					this.activeAnswer = this.questionAnswers[0];
					this.questionId = this.activeAnswer._id;
				}
			});
	}

	getAnswerByQuestionId(questionId, index) {
		this.activeAnswerIndex = index;
		this.questionId = questionId;
		this.faqService
			.getAnswerByQuestionId(this.questionId, this.language)
			.subscribe(res => {
				this.activeAnswer = res.data[0];
			});
		this.router.navigate(['/faq/category'], {
			queryParams: { id: this.getId, questionId: this.questionId }
		});
	}
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
interface Question {
	id: string;
	text: string;
	linkId: string;
}
interface Faq {
	type: string;
	question: Question[];
}
