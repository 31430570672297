import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule) },
  { path: 'user', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'scan-qr', loadChildren: () => import('./includes/outline/outline.module').then(m => m.OutlineModule) },
  {
    path: 'not-found',
    loadChildren: () => import('./includes/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
