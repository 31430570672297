import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { CorporateComponent } from './corporate/corporate.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { ManagementComponent } from './management/management.component';
import { SocialComponent } from './social/social.component';
import { ValuesComponent } from './values/values.component';
import { CareersComponent } from './careers/careers.component';
import { MediaEventsComponent } from './media-events/media-events.component';
import { ProfileComponent } from './profile/profile.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { HttpClientModule } from '../../../../node_modules/@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
	imports: [
		CommonModule,
		AboutRoutingModule,
		HttpClientModule,
		NgxPaginationModule
	],
	declarations: [
		AboutComponent,
		CorporateComponent,
		AboutPageComponent,
		ManagementComponent,
		SocialComponent,
		ValuesComponent,
		CareersComponent,
		MediaEventsComponent,
		ProfileComponent,
		EventDetailComponent
	]
})
export class AboutModule {}
