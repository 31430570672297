import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FindShopService } from '../../../service/find-shop.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CartsServiceService } from '../../../service/carts-service.service';
import { FormGroup, FormControl, Validators, PatternValidator } from '@angular/forms';
import { GlobleService } from '../../../service/globle.service';
import { DataSharingService } from '../../../service/data-sharing.service';
import { PaymentMethodsService } from '../../../service/payment-methods.service';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
import { ToPhoneNumber } from '../../../shared/utils';
import { OrderService } from '../../../service/order.service';
import { UploadServiceService } from '../../../service/upload-service.service';
import { stringify } from 'querystring';
import * as request from 'request';

@Component({
    selector: 'app-order-summary-step',
    templateUrl: './order-summary-step.component.html',
    styleUrls: ['./order-summary-step.component.scss']
})
export class OrderSummaryStepComponent implements OnInit {
    provinces = [];
    language: string;
    phoneNumber: string;
    custName: string;
    messageError: string;
    keywords: any;
    shops = [];
    showShopName: string;
    showShopAddress: string;
    msgOptionPickupSim: string;
    markers = {
        lat: 11.571523,
        lng: 104.898126
    };
    deliverLat: number;
    deliverLng: number;

    lat = 11.571523;
    lng = 104.898126;
    zoom = 7;
    lat2 = 11.571523;
    lng2 = 104.898126;
    zoom2 = 7;
    style = '';
    scrollwheel: false;

    showSelectedHub = 'hub';

    icons = {
        head_office: '/assets/pink-map-small.png',
        cooperative: '/assets/blue-map-small.png',
        current_location: '/assets/current-location.png'
    };

    previous = null;
    previous2 = null;
    deviceTotal: number;
    allTotal: number;
    originalPrice: number;
    deviceTaxTotal: number;
    orderSimCards: any = [];
    orderDevices: any = [];
    orderScratchCards: any = [];

    frmDeliver: FormGroup;
    frmPickUp: FormGroup;
    phonePattern = /^[855][0-9]{11}|[0][0-9]{9}$/;
    submitted = false;
    order: any;
    items: any = [];
    orderServiceType: string;
    shopId: string;
    deliveryLocation: any;
    orderServiceTitle: string;
    paymentType: string;
    remark: string;
    result: string;
    paymentTypeUrl: string;
    amount: string;
    rootUrl: string;
    checkDelivery: any = {};
    checkPickUp: any = {};
    errSelectMap: string;
    errSelectHub: string;
    editAddress: string;
    city = 'city';
    firstName = '';
    editProvince = '';
    editMessage = '';
    editMessageWarning = '';
    currentLat: number;
    currentLng: number;
    paymentMethods: any[];
    shipmentInsurance = false;
    loginType: string;
    hasPromotion: any;
    // tslint:disable-next-line:max-line-length
    conditionsDelivery =
        'Delivery: The total order amount must be more than $0. If not, cannot	proceed. And when checked on NEXT, a pop up message appear: To select delivery, you need to top up at least $1.';
    itemsimCardPrice: string;
    editFirstName: string;
    editLasttName: any;
    editphoneNumber: any;
    editEmail: any;
    dataDelivery: any = {};
    getaddress: string;
    getProvince: string;
    getphoneNumber: string;
    getEmail: string;
    getFirstName: string;
    getlastName: string;
    getshopName: string;
    submittedPickUp = false;
    itemId: string;
    inputField: string;
    editMessageWarningHub = '';
    buyScratchCards: any = [];
    buySimCards: any = [];
    buyCartList: any;

    // identify user
    orderId: any = [];
    orderIds: any = [];
    msgErr: string;
    msgErr1: string;
    msgSuccess: string;
    msgSuccess1: string;
    submitted1 = false;
    submitted2 = false;

    idType: any = [];
    checkIdTypeValue: string;
    defualPhoto: string;
    frmInformation: FormGroup;
    frmInformation1: FormGroup;
    myDate = new Date().toISOString();
    bsValue = new Date();
    bsRangeValue: Date[];
    maxDate = new Date();
    dayDate = new Date();
    bsConfig: any;
    minMode = new Date();
    datepickerMode = new Date();
    hasUncompletedOrders: string;

    fronId: string;
    backId: string;
    photo: string;
    photo1: string;
    fronId1: string;
    backId1: string;

    frontIdImage: any = [];
    frontIdImage1: any = [];
    backIdImage: any = [];
    backIdImage1: any = [];
    photoLocal: string;
    photoLocal1: string;
    buttonDisabled: string;

    constructor(
        private findShopService: FindShopService,
        private router: Router,
        private cartService: CartsServiceService,
        private api: GlobleService,
        private data: DataSharingService,
        private paymentMethod: PaymentMethodsService,
        private fileService: UploadServiceService,
        private _location: Location,
        private orderService: OrderService,
        private datePipe: DatePipe
    ) {
        this.maxDate.setDate(this.maxDate.getDate() + 7);
        this.bsRangeValue = [this.dayDate, this.maxDate];
        this.myDate = this.datePipe.transform(this.dayDate, 'yyyy-MM-dd');
        this.bsConfig = Object.assign(
            {},
            {
                viewMode: 'months',
                datepickerMode: 'year'
            }
        );
    }

    ngOnInit() {
        this.getCertification();
        this.frmInformation = new FormGroup({
            idType: new FormControl('ID Type', Validators.required),
            id: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            sex: new FormControl('male', Validators.required),
            date: new FormControl('', Validators.required),
            frontIDPhotoUrl: new FormControl('', Validators.required),
            backIDPhotoUrl: new FormControl('', Validators.required),
            photoUrl: new FormControl('', Validators.required)
        });
        if (this.frmInformation.get('idType').value === 'ID Type') {
            console.log('abc');
            $('#id, #sex, #date, #month, #year').prop('disabled', true);
            this.checkIdTypeValue = 'ID Type';
        }
        this.orderId = localStorage.getItem('orderId');
        this.orderIds = localStorage.getItem('orderIds');
        this.hasUncompletedOrders = localStorage.getItem('hasUncompletedOrders');
        if (this.hasUncompletedOrders === 'true') {
            console.log('hasUncompletedOrders', this.hasUncompletedOrders);
            this.orderId = this.orderIds;
        }

        this.loginType = localStorage.getItem('type');
        // this.hasPromotion = localStorage.getItem('hasPromotion');
        this.phoneNumber = localStorage.getItem('phone');
        this.custName = localStorage.getItem('custName');
        this.language = localStorage.getItem('language');
        this.itemsimCardPrice = localStorage.getItem('itemsimCardPrice');
        this.itemId = localStorage.getItem('itemId');
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

        // identify user
        this.idType = '';
        this.frontIdImage = localStorage.getItem('frontIdImage');
        this.backIdImage = localStorage.getItem('backIdImage');
        this.photoLocal = localStorage.getItem('photoLocal');

        this.orderDevices = JSON.parse(localStorage.getItem('orderDevices'));
        // this.orderScratchCards = JSON.parse(
        // 	localStorage.getItem('orderScratchCards')
        // );

        this.buyScratchCards = JSON.parse(localStorage.getItem('buyScratchCards'));
        if (this.buyScratchCards) {
            this.cartService.getCarts(this.phoneNumber).subscribe((res) => {
                this.buyCartList = res;
                this.buyScratchCards.forEach((x) => {
                    x.imageUrl = this.buyCartList.scratchCardIcon;
                });
                this.orderScratchCards = this.buyScratchCards;
            });
        } else {
            this.orderScratchCards = JSON.parse(localStorage.getItem('orderScratchCards'));
        }
        this.buySimCards = JSON.parse(localStorage.getItem('buySimCards'));
        if (this.buySimCards) {
            this.cartService.getCarts(this.phoneNumber).subscribe((res) => {
                this.buyCartList = res;
                this.buySimCards.forEach((x) => {
                    x.simIcon = this.buyCartList.simIcon;
                    x.topUpIcon = this.buyCartList.topUpIcon;
                    x.simPriceIcon = this.buyCartList.simPriceIcon;
                });
                this.orderSimCards = this.buySimCards;

                if (this.orderSimCards.length > 0) {
                    for (const i of this.orderSimCards) {
                        this.items.push(i);
                    }
                }

                console.log('orderSimCards from getCarts ');
                console.log(this.orderSimCards);
            });
        } else {
            this.orderSimCards = JSON.parse(localStorage.getItem('orderSimCards'));
            console.log('orderSimCards from localstorage ');
            console.log(this.orderSimCards);
        }
        this.allTotal = Number(localStorage.getItem('allTotal'));
        this.originalPrice = this.allTotal;
        this.getDelivery();
        localStorage.removeItem('clickStep1');
        localStorage.removeItem('clickStep2');
        localStorage.removeItem('clickStep3');
        localStorage.removeItem('cartLists');

        /**
         * Check if having order device
         */
        if (this.orderDevices.length > 0) {
            this.deviceTotal = 0;
            this.deviceTaxTotal = 0;
            for (const i of this.orderDevices) {
                this.deviceTotal += i.totalPrice;
            }
            this.deviceTaxTotal = this.deviceTotal * 0.005;
            // this.allTotal += this.deviceTaxTotal;
            // this.shipmentInsurance = true;
        }

        if (this.language === 'en') {
            this.provinces = [
                { label: 'Phnom Penh', value: 'Phnom Penh' },
                { label: 'Banteay Meanchey', value: 'Banteay Meanchey' },
                { label: 'Battambang', value: 'Battambang' },
                { label: 'Kampong Cham', value: 'Kampong Cham' },
                { label: 'Kampong Chhnang', value: 'Kampong Chhnang' },
                { label: 'Kampong Speu', value: 'Kampong Speu' },
                { label: 'Kampong Thom', value: 'Kampong Thom' },
                { label: 'Kampot', value: 'Kampot' },
                { label: 'Kandal', value: 'Kandal' },
                { label: 'Koh Kong', value: 'Koh Kong' },
                { label: 'Kep', value: 'Kep' },
                { label: 'Kratié', value: 'Kratié' },
                { label: 'Mondulkiri', value: 'Mondulkiri' },
                { label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
                { label: 'Pailin', value: 'Pailin' },
                { label: 'Preah Sihanouk', value: 'Preah Sihanouk' },
                { label: 'Preah Vihear', value: 'Preah Vihear' },
                { label: 'Pursat', value: 'Pursat' },
                { label: 'Prey Veng', value: 'Prey Veng' },
                { label: 'Ratanakiri', value: 'Ratanakiri' },
                { label: 'Siem Reap', value: 'Siem Reap' },
                { label: 'Stung Treng', value: 'Stung Treng' },
                { label: 'Svay Rieng', value: 'Svay Rieng' },
                { label: 'Takéo', value: 'Takéo' },
                { label: 'Tboung Khmum', value: 'Tboung Khmum' }
            ];
        } else if (this.language === 'km') {
            this.provinces = [
                { label: 'ភ្នំពេញ', value: 'Phnom Penh' },
                { label: 'បន្ទាយមានជ័យ', value: 'Banteay Meanchey' },
                { label: 'បាត់ដំបង', value: 'Battambang' },
                { label: 'កំពង់ចាម', value: 'Kampong Cham' },
                { label: 'កំពង់ឆ្នាំង', value: 'Kampong Chhnang' },
                { label: 'កំពង់ស្ពឺ', value: 'Kampong Speu' },
                { label: 'កំពង់ធំ', value: 'Kampong Thom' },
                { label: 'កំពត', value: 'Kampot' },
                { label: 'កណ្តាល', value: 'Kandal' },
                { label: 'កោះកុង', value: 'Koh Kong' },
                { label: 'កែប', value: 'Kep' },
                { label: 'ក្រចេះ', value: 'Kratié' },
                { label: 'មណ្ឌលគីរី', value: 'Mondulkiri' },
                { label: 'ឧត្តរមានជ័យ', value: 'Oddar Meanchey' },
                { label: 'បៃលិន', value: 'Pailin' },
                { label: 'ព្រះសីហនុ', value: 'Preah Sihanouk' },
                { label: 'ព្រះវិហារ', value: 'Preah Vihear' },
                { label: 'ពោធិ៍សាត់', value: 'Pursat' },
                { label: 'ព្រៃវែង', value: 'Prey Veng' },
                { label: 'រតនគីរី', value: 'Ratanakiri' },
                { label: 'សៀមរាប', value: 'Siem Reap' },
                { label: 'ស្ទឹងត្រែង', value: 'Stung Treng' },
                { label: 'ស្វាយរៀង', value: 'Svay Rieng' },
                { label: 'តាកែវ', value: 'Takéo' },
                { label: 'ត្បូងឃ្មុំ', value: 'Tboung Khmum' }
            ];
        } else if (this.language === 'zh') {
            this.provinces = [
                { label: '金边', value: 'Phnom Penh' },
                { label: '波比', value: 'Banteay Meanchey' },
                { label: '马德望', value: 'Battambang' },
                { label: '甘榜湛', value: 'Kampong Cham' },
                { label: '磅清扬', value: 'Kampong Chhnang' },
                { label: '磅士碑', value: 'Kampong Speu' },
                { label: '磅通', value: 'Kampong Thom' },
                { label: '贡布', value: 'Kampot' },
                { label: '甘丹', value: 'Kandal' },
                { label: '戈公', value: 'Koh Kong' },
                { label: '白马', value: 'Kep' },
                { label: '桔井', value: 'Kratié' },
                { label: '蒙多基里省', value: 'Mondulkiri' },
                { label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
                { label: '拜林', value: 'Pailin' },
                { label: '西哈努克', value: 'Preah Sihanouk' },
                { label: '柏威夏', value: 'Preah Vihear' },
                { label: '菩萨', value: 'Pursat' },
                { label: '猎物冯', value: 'Prey Veng' },
                { label: '腊塔纳基里', value: 'Ratanakiri' },
                { label: '暹粒市', value: 'Siem Reap' },
                { label: '特本克蒙', value: 'Stung Treng' },
                { label: '甘拉', value: 'Svay Rieng' },
                { label: '猛男', value: 'Takéo' },
                { label: '特本克蒙', value: 'Tboung Khmum' }
            ];
        }

        $(function () {
            $('input[name=exampleRadios]')
                .on('click init-post-format ', function () {
                    $('.hid-drop, .hid-address').toggle($('#exampleRadios1').prop('checked'));
                })
                .trigger('init-post-format');
        });

        if (this.orderSimCards.length > 0) {
            for (const i of this.orderSimCards) {
                this.items.push(i);
            }
        }
        if (this.orderDevices.length > 0) {
            for (const i of this.orderDevices) {
                this.items.push(i);
            }
        }
        if (this.orderScratchCards.length > 0) {
            for (const i of this.orderScratchCards) {
                this.items.push(i);
            }
        }
        // this.checkDelivery = JSON.parse(localStorage.getItem('deliveryLocation'));

        if (this.checkDelivery) {
            // if (this.allTotal > 0) {
            // $('#exampleRadios2').prop('checked', true);
            this.checkDeliver();
            this.getaddress = this.checkDelivery.address;
            this.getProvince = this.checkDelivery.province;
            this.getFirstName = this.checkDelivery.firstName;
            this.getlastName = this.checkDelivery.lastName;
            this.getphoneNumber = this.checkDelivery.phoneNumber;
            this.getEmail = this.checkDelivery.email;
            // }
            // if (this.allTotal === 0) {
            // const element = <HTMLInputElement>document.getElementById('exampleRadios2');
            // element.disabled = true;
            // }
        }

        if (this.checkDelivery) {
            this.frmDeliver = new FormGroup({
                address: new FormControl(this.checkDelivery.address, Validators.required),
                province: new FormControl(this.checkDelivery.province, Validators.required),
                firstName: new FormControl(this.checkDelivery.firstName, Validators.required),
                lastName: new FormControl(this.checkDelivery.lastName, Validators.required),
                phoneNumber: new FormControl(this.checkDelivery.phoneNumber, [
                    Validators.required,
                    Validators.pattern(this.phonePattern),
                    Validators.minLength(9),
                    Validators.maxLength(12)
                ]),
                email: new FormControl(this.checkDelivery.email)
            });
        } else {
            this.frmDeliver = new FormGroup({
                address: new FormControl(this.getaddress, Validators.required),
                province: new FormControl(this.getProvince, Validators.required),
                firstName: new FormControl(this.getFirstName, Validators.required),
                lastName: new FormControl(this.getlastName, Validators.required),
                phoneNumber: new FormControl(this.getphoneNumber, [
                    Validators.required,
                    Validators.pattern(this.phonePattern),
                    Validators.minLength(9),
                    Validators.maxLength(12)
                ]),
                email: new FormControl(this.getEmail)
            });
        }
        if (this.checkPickUp) {
            this.frmPickUp = new FormGroup({
                firstName: new FormControl(this.checkPickUp.firstName, Validators.required),
                lastName: new FormControl(this.checkPickUp.lastName, Validators.required),
                phoneNumber: new FormControl(this.checkPickUp.phoneNumber, [
                    Validators.required,
                    Validators.pattern(this.phonePattern),
                    Validators.minLength(9),
                    Validators.maxLength(12)
                ]),
                email: new FormControl(this.checkPickUp.email)
            });
        } else {
            this.frmPickUp = new FormGroup({
                firstName: new FormControl(this.getFirstName, Validators.required),
                lastName: new FormControl(this.getlastName, Validators.required),
                phoneNumber: new FormControl(this.getphoneNumber, [
                    Validators.required,
                    Validators.pattern(this.phonePattern),
                    Validators.minLength(9),
                    Validators.maxLength(12)
                ]),
                email: new FormControl(this.getEmail)
            });
        }

        this.paymentMethod.getPaymentMethods().subscribe((res) => {
            this.paymentMethods = res;
        });
    }
    getCertification() {
        this.cartService.getCertification().subscribe(
            (res) => {
                this.idType = res;
            },
            (err) => {
                console.log(err);
            }
        );
    }
    checkIdType() {
        this.checkIdTypeValue = this.frmInformation.get('idType').value;
        if (this.frmInformation.get('idType').value === 'ID Type') {
            $('#id, #sex, #date, #month, #year').prop('disabled', true);
        } else {
            $('#id, #sex, #date, #month, #year').prop('disabled', false);
        }
    }
    uploadFrontId(event: any, files: FileList) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (events: any) => {
                this.frontIdImage = events.target.result;
                $('label[for="file-input"] .upload-icon').css('border', 'none');
                $('label[for="file-input"] .icon').hide();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        this.fileService.uploadFile(files).subscribe(
            (res) => {
                const data: any = res;
                this.fronId = data.data[0].filename;
                this.frmInformation.controls.frontIDPhotoUrl.setValue(data.data[0].filename);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Upload back Id image
     * @param event
     * @param files
     */
    uploadBackId(event: any, files: FileList) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (events: any) => {
                this.backIdImage = events.target.result;
                $('label[for="file-input1"] .upload-icon').css('border', 'none');
                $('label[for="file-input1"] .icon').hide();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        this.fileService.uploadFile(files).subscribe(
            (res) => {
                const data: any = res;
                this.backId = data.data[0].filename;
                this.frmInformation.controls.backIDPhotoUrl.setValue(data.data[0].filename);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * upload profie picture
     * @param event
     * @param files
     */
    uploadPhoto(event: any, files: FileList) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (events: any) => {
                this.photoLocal = events.target.result;
                $('label[for="file-input2"] .upload-icon').css('border', 'none');
                $('label[for="file-input2"] .icon').hide();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        this.fileService.uploadFile(files).subscribe(
            (res) => {
                const data: any = res;
                this.photo = data.data[0].filename;
                this.frmInformation.controls.photoUrl.setValue(data.data[0].filename);
            },
            (err) => {
                console.log(err);
            }
        );
    }
    InternetStartDate(val): void {
        this.myDate = this.datePipe.transform(val, 'yyyy-MM-dd');
        // tslint:disable-next-line:quotemark
    }
    updateOrders() {
        this.msgErr = '';
        this.msgSuccess = '';
        this.submitted = true;
        if (this.frmInformation.invalid || this.checkIdTypeValue === 'ID Type') {
            return;
        }
        const date = this.frmInformation.get('date').value;
        // if (this.hasPromotion === 'true') {
        //     this.hasPromotion = 'true';
        // }
        const userIdentify = {
            dateOfBirth: date.toISOString(),
            gender: this.frmInformation.get('sex').value,
            certName: this.frmInformation.get('idType').value,
            idCardNumber: this.frmInformation.get('id').value,
            name: this.frmInformation.get('name').value,
            frontIDPhotoUrl: this.fronId,
            backIDPhotoUrl: this.backId,
            photoUrl: this.photo
            // hasPromotion: this.hasPromotion
        };
        localStorage.setItem('userIdentify', JSON.stringify(userIdentify));
    }

    getDelivery() {
        this.cartService.getDelivery(this.phoneNumber).subscribe((data) => {
            this.dataDelivery = data;
            this.getaddress = this.dataDelivery.address;
            this.getProvince = this.dataDelivery.province;
            this.getphoneNumber = this.dataDelivery.phoneNumber;
            this.getEmail = this.dataDelivery.email;
            this.getFirstName = this.dataDelivery.firstName;
            this.getlastName = this.dataDelivery.lastName;
            // this.getshopName = this.dataDelivery.shopName;
            // this.showShopName = this.dataDelivery.shopName;
            // this.shopId = this.dataDelivery.shopId;
        });
    }
    /**
     * Validation form deliver
     */
    get g() {
        return this.frmInformation.controls;
    }
    get f() {
        return this.frmDeliver.controls;
    }
    get h() {
        return this.frmPickUp.controls;
    }
    /**
     * Get current location user
     */
    allowLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.currentLat = position.coords.latitude;
                this.currentLng = position.coords.longitude;
                this.zoom = 13;
            }, this.geoError);
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    }

    geoError() {
        alert('Google Map does not have permission to use your location.');
    }

    /**
     * Selecting Cash for payment
     */
    // cashSelected() {
    //     this.removeDisable();
    //     this.paymentType = 'cash';
    //     $('#cash').removeClass('d-none');
    //     $('#card-cash').addClass('card-selected');
    //     $('#wing, #mpay, #alipay, #aba, #asiaweiluy, #cash-disabled').addClass('d-none');
    //     $('#wing-disabled, #mpay-disabled, #alipay-disabled, #asiaweiluy-disabled, #aba-disabled').removeClass(
    //         'd-none'
    //     );
    //     this.getRemark();
    // }

    /**
     * Selecting Wing for payment
     */
    wingSelected() {
        this.removeDisable();
        this.paymentType = 'wing';
        $('#wing').removeClass('d-none');
        $('#card-wing').addClass('card-selected');
        $('#mpay, #alipay, #aba, #asiaweiluy, #wing-disabled').addClass('d-none');
        $('#mpay-disabled, #alipay-disabled, #asiaweiluy-disabled, #aba-disabled').removeClass('d-none');
        this.getRemark();
    }

    /**
     * Selecting ABA for payment
     */
    abaSelected() {
        this.removeDisable();
        this.paymentType = 'aba';
        $('#aba').removeClass('d-none');
        $('#card-aba').addClass('card-selected');
        $('#mpay, #wing, #alipay, #asiaweiluy, #aba-disabled').addClass('d-none');
        $('#mpay-disabled, #alipay-disabled, #asiaweiluy-disabled, #wing-disabled').removeClass('d-none');
        this.getRemark();
    }

    /**
     * Selecting Mpay for payment
     */
    mpaySelected() {
        this.removeDisable();
        this.paymentType = 'mpay';
        $('#mpay').removeClass('d-none');
        $('#card-mpay').addClass('card-selected');
        $('#alipay, #wing, #aba, #asiaweiluy, #mpay-disabled').addClass('d-none');
        $('#alipay-disabled, #wing-disabled, #asiaweiluy-disabled, #aba-disabled').removeClass('d-none');
        if (this.allTotal > 1000) {
            $('#show-warning').trigger('click');
            $('#btn-select-payment').attr('disabled', 'disabled').addClass('btn-disabled');
        } else {
            this.getRemark();
        }
    }

    /**
     * Selecting Alipay for payment
     */
    alipaySelected() {
        this.removeDisable();
        this.paymentType = 'alipay';
        $('#alipay').removeClass('d-none');
        $('#card-alipay').addClass('card-selected');
        $('#mpay, #wing, #aba, #asiaweiluy, #alipay-disabled').addClass('d-none');
        $('#mpay-disabled, #wing-disabled, #asiaweiluy-disabled, #aba-disabled').removeClass('d-none');
        if (this.allTotal > 1000) {
            $('#show-warning').trigger('click');
            $('#btn-select-payment').attr('disabled', 'disabled').addClass('btn-disabled');
        } else {
            this.getRemark();
        }
    }

    /**
     * Selecting AsiaWeiLuy for payment
     */
    asiaWeiLuySelected() {
        this.removeDisable();
        this.paymentType = 'asiaweiluy';
        $('#asiaweiluy').removeClass('d-none');
        $('#card-asiaweiluy').addClass('card-selected');
        $('#mpay, #wing, #aba, #alipay, #asiaweiluy-disabled').addClass('d-none');
        $('#mpay-disabled, #wing-disabled, #aba-disabled, #alipay-disabled').removeClass('d-none');
        if (this.allTotal > 1000) {
            $('#show-warning').trigger('click');
            $('#btn-select-payment').attr('disabled', 'disabled').addClass('btn-disabled');
        } else {
            this.getRemark();
        }
    }

    /**
     * Remove disable button next on payment step
     */
    removeDisable() {
        $('#btn-select-payment').removeClass('btn-disabled').removeAttr('disabled');
    }

    /**
     * Get remark for payment
     */
    getRemark() {
        if (!this.paymentType) {
            return;
        }
        const order = {
            phoneNumber: this.phoneNumber,
            dynamicCardPrice: true,
            promotionItem: this.itemId,
            items: this.items,
            shipmentInsurance: this.shipmentInsurance,
            paymentMethod: this.paymentType,
            orderServiceType: this.orderServiceType,
            shopId: this.shopId,
            deliveryLocation: this.deliveryLocation,
            platform: 'web'
        };

        this.cartService.order(order).subscribe(
            (res) => {
                let data: any;
                data = res;
                const orderId = data.orderId;
                this.remark = data.remark;
                localStorage.setItem('orderId', data.orderId);
                this.saveProfile(data.orderId);
            },
            (err) => {
                this.messageError = err.error.message;
            }
        );
        console.log('this.items');
        console.log(this.items);
    }
    async saveProfile(orderId) {
        const profileUser = JSON.parse(localStorage.getItem('userIdentify'));
        const newObj = {
            orderIds: orderId,
            profile: profileUser
        };
        console.log('newObj', newObj);
        this.cartService.updateOrder(newObj).subscribe(
            (res) => {
                console.log('success profile');
            },
            (err) => {
                console.log(err);
                console.log('success profile', err);
                this.msgErr = err.error.message;
            }
        );
    }

    /**
     * Condition for agree shipment insurance
     */
    taxInsurance() {
        if ($('#shipment-insurance').prop('checked') === true) {
            $('#show-shipment, #show-shipment-summary').removeClass('d-none');
            this.allTotal += Math.round(this.deviceTaxTotal * 100) / 100;
            this.shipmentInsurance = true;
        } else {
            $('#show-shipment, #show-shipment-summary').addClass('d-none');
            this.allTotal -= Math.round(this.deviceTaxTotal * 100) / 100;
            this.shipmentInsurance = false;
        }
    }

    /**
     * Order step one
     */
    step1() {
        $('#order, #pickup, #payment-card-show, #order-summary-show').toggleClass('d-none');
        $('#progress-top-up').addClass('success');
        $('#progress-payment').addClass('active');
        window.scroll({ top: 0, behavior: 'smooth' });
        localStorage.setItem('clickStep1', 'true');
        $('#progress-payment').addClass('text-active');
        $('#progress-top-up, #progress-confirm, #progress-success').removeClass('text-active');
    }

    /**
     * Order back to step two
     */
    backToStepTwo() {
        $('#pickup, #confirm').toggleClass('d-none');
        $('#progress-payment').addClass('success');
        $('#progress-confirm').addClass('active');
        window.scroll({ top: 0, behavior: 'smooth' });
    }

    /**
     * Order step two
     */
    step2() {
        console.log('s0', this.frmInformation.invalid);
        this.updateOrders();
        console.log('s1', this.frmInformation.invalid);
        if ($('#exampleRadios1').prop('checked') === false && $('#exampleRadios2').prop('checked') === false){
            this.msgOptionPickupSim = 'Check option to get simcard';
        }
        const firstName = this.frmPickUp.get('firstName').value;
        const lastName = this.frmPickUp.get('lastName').value;
        if (!!/[^a-zA-Z]/.test(firstName)) {
            $('#show-invalid-firstname-modal').trigger('click');
            return;
        } else if (!!/[^a-zA-Z]/.test(lastName)) {
            $('#show-invalid-lastname-modal').trigger('click');
            return;
        }
        if (this.orderServiceType === 'pick-up') {
            this.submittedPickUp = true;
            if (!this.shopId) {
                this.errSelectHub = 'Please select yes hub.';
                return;
            }
            if (this.frmPickUp.invalid) {
                this.errSelectHub = 'Please edit the field.';
                // console.log('this.frmPickUp', this.frmPickUp);
                return;
            }
            if (this.editMessage) {
                this.editMessageWarningHub = this.editMessage;
                return;
            }
            this.deliveryLocation = {
                // province: this.frmPickUp.get('province').value,
                // shopName: this.frmPickUp.get('shopName').value,
                firstName: this.frmPickUp.get('firstName').value,
                lastName: this.frmPickUp.get('lastName').value,
                phoneNumber: this.frmPickUp.get('phoneNumber').value,
                email: this.frmPickUp.get('email').value
            };
            this.cartService.putDelivery(this.phoneNumber, this.deliveryLocation).subscribe(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log(err);
                }
            );
        } else if (this.orderServiceType === 'delivery') {
            this.submitted = true;
            if (this.frmDeliver.invalid) {
                return;
            }

            if (this.editMessage) {
                this.editMessageWarning = this.editMessage;
                return;
            }
            this.deliveryLocation = {
                address: this.frmDeliver.get('address').value,
                city: this.frmDeliver.get('province').value,
                firstName: this.frmDeliver.get('firstName').value,
                lastName: this.frmDeliver.get('lastName').value,
                phoneNumber: this.frmDeliver.get('phoneNumber').value,
                email: this.frmDeliver.get('email').value
            };
            this.cartService.putDelivery(this.phoneNumber, this.deliveryLocation).subscribe(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log(err);
                }
            );
            // localStorage.setItem(
            // 	'deliveryLocation',
            // 	JSON.stringify(this.deliveryLocation)
            // );
        } else {
            return;
        }
        console.log('s2', this.frmInformation.invalid);
        if (this.frmInformation.invalid === false) {
            window.scroll({ top: 0, behavior: 'smooth' });
            $('#pickup, #confirm').toggleClass('d-none');
            $('#progress-payment').addClass('success');
            $('#progress-confirm').addClass('active');
            localStorage.setItem('clickStep2', 'true');
            $('#progress-confirm').addClass('text-active');
            $('#progress-top-up, #progress-payment, #progress-success').removeClass('text-active');
        }
    }

    /**
     * Order step three
     */
    step3() {
        this.messageError = '';

        if (this.allTotal === 0) {
            const order = {
                phoneNumber: this.phoneNumber,
                dynamicCardPrice: true,
                promotionItem: this.itemId,
                items: this.items,
                shipmentInsurance: this.shipmentInsurance,
                orderServiceType: this.orderServiceType,
                shopId: this.shopId,
                deliveryLocation: this.deliveryLocation,
                platform: 'web'
            };

            this.cartService.order(order).subscribe(
                (res) => {
                    let data: any;
                    data = res;
                    localStorage.setItem('orderId', data.orderId);
                    this.router.navigate(['/cart/success']);
                    localStorage.removeItem('itemsimCardPrice');
                },
                (err) => {
                    this.messageError = err.error.message;
                }
            );
            return;
        }
        window.scroll({ top: 0, behavior: 'smooth' });
        $('#confirm, #payment').toggleClass('d-none');
        $('#progress-confirm').addClass('success');
        $('#progress-success').addClass('active');
        localStorage.setItem('clickStep3', 'true');
        $('#progress-success').addClass('text-active');
        $('#progress-top-up, #progress-payment, #progress-confirm').removeClass('text-active');
    }

    /**
     * Order step four
     */
    payment() {
        if (!this.remark) {
            return;
        }
        // if(this.paymentType === 'cash') {
        // 	this.router.navigate(['/cart/success']);
        // } else {}
        this.amount = String(this.allTotal);
        const popupWidth = 700,
            popupHeight = 800,
            popupLeft = (window.screen.width - popupWidth) / 2,
            popupTop = (window.screen.height - popupHeight) / 2;

        if (this.paymentType === 'mpay') {
            this.paymentTypeUrl = 'mpay/form';
            this.rootUrl = this.api.apiUrl;
        } else if (this.paymentType === 'alipay') {
            this.paymentTypeUrl = 'alipay/form';
            this.rootUrl = this.api.apiUrl;
        } else if (this.paymentType === 'asiaweiluy') {
            this.paymentTypeUrl = 'asia-wei-luy/form';
            this.rootUrl = this.api.apiUrl;
        } else if (this.paymentType === 'cash') {
            this.rootUrl = environment.redirectUrl;
        } else {
            this.paymentTypeUrl = this.paymentType;
            console.log('====', this.paymentTypeUrl);
            this.rootUrl = environment.redirectUrl;
            console.log('==env==', environment.redirectUrl);
        }
        if (this.paymentType !== 'aba') {
            const popup = window.open(
                this.rootUrl + '/' + this.paymentTypeUrl + '?amount=' + this.amount + '&remark=' + this.remark,
                '',
                'width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupLeft + ',top=' + popupTop + ''
            );
            console.log('!aba', popup);
            if (popup.open) {
                $('#btn-select-payment').addClass('btn-disabled').attr('disabled', 'disabled');
            }
            const interval = setInterval(() => {
                try {
                    const url_string = popup.location.href;
                    const url = new URL(url_string);

                    if (this.paymentType === 'asiaweiluy') {
                        const orderId = url.searchParams.get('orderId');
                        this.orderService.getOrderStatus(orderId).subscribe(
                            (res) => {
                                let data: any;
                                data = res;
                                if (data.status === 'succeeded') {
                                    popup.close();
                                    $('#payment').toggleClass('d-none');
                                    $('#progress-success').toggleClass('success');
                                    this.data.updateBadgeCount();
                                    this.router.navigate(['/cart/success']);
                                    localStorage.removeItem('itemsimCardPrice');
                                } else {
                                    popup.close();
                                }
                            },
                            (err) => {}
                        );
                    } else {
                        const c = url.searchParams.get('result');
                        if (c) {
                            clearInterval(interval);
                            this.result = popup.location.search.slice(8);
                            if (this.result === 'success') {
                                popup.close();
                                $('#payment').toggleClass('d-none');
                                $('#progress-success').toggleClass('success');
                                this.data.updateBadgeCount();
                                this.router.navigate(['/cart/success']);
                                localStorage.removeItem('itemsimCardPrice');
                            } else {
                                popup.close();
                            }
                        }
                    }
                } catch (evt) {}
                if (popup.closed) {
                    $('#btn-select-payment').removeClass('btn-disabled').removeAttr('disabled');
                    clearInterval(interval);
                }
            }, 100);

            if (this.paymentType === 'aba') {
                const popup = window.open(
                    this.rootUrl +
                        '/' +
                        this.paymentTypeUrl +
                        '?amount=' +
                        this.amount +
                        '&remark=' +
                        this.remark +
                        '&firstName=' +
                        this.getFirstName +
                        '&lastName=' +
                        this.getlastName +
                        '&email=' +
                        this.getEmail +
                        '&phoneNumber=' +
                        ToPhoneNumber(this.getphoneNumber),
                    '',
                    'width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupLeft + ',top=' + popupTop + ''
                );
                if (popup.open) {
                    $('#btn-select-payment').addClass('btn-disabled').attr('disabled', 'disabled');
                }
                const interval = setInterval(() => {
                    try {
                        const url_string = popup.location.href;
                        const url = new URL(url_string);
                        const c = url.searchParams.get('result');
                        if (c) {
                            clearInterval(interval);
                            this.result = popup.location.search.slice(8);
                            if (this.result === 'success') {
                                popup.close();
                                $('#payment').toggleClass('d-none');
                                $('#progress-success').toggleClass('success');
                                this.data.updateBadgeCount();
                                this.router.navigate(['/cart/success']);
                                localStorage.removeItem('itemsimCardPrice');
                                localStorage.removeItem('clickStep1');
                                localStorage.removeItem('clickStep2');
                                localStorage.removeItem('clickStep3');
                            } else {
                                popup.close();
                            }
                        }
                    } catch (evt) {}
                    if (popup.closed) {
                        $('#btn-select-payment').removeClass('btn-disabled').removeAttr('disabled');
                        clearInterval(interval);
                    }
                }, 100);
            }
        } else {
            const popup = window.open(
                this.rootUrl +
                    '/' +
                    this.paymentTypeUrl +
                    '?amount=' +
                    this.amount +
                    '&remark=' +
                    this.remark +
                    '&firstName=' +
                    this.getFirstName +
                    '&lastName=' +
                    this.getlastName +
                    '&email=' +
                    this.getEmail +
                    '&phoneNumber=' +
                    ToPhoneNumber(this.getphoneNumber),
                '',
                'width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupLeft + ',top=' + popupTop + ''
            );
            if (popup.open) {
                $('#btn-select-payment').addClass('btn-disabled').attr('disabled', 'disabled');
            }
            const interval = setInterval(() => {
                try {
                    const url_string = popup.location.href;
                    const url = new URL(url_string);
                    const c = url.searchParams.get('result');
                    if (c) {
                        clearInterval(interval);
                        this.result = popup.location.search.slice(8);
                        if (this.result === 'success') {
                            popup.close();
                            $('#payment').toggleClass('d-none');
                            $('#progress-success').toggleClass('success');
                            this.data.updateBadgeCount();
                            this.router.navigate(['/cart/success']);
                            localStorage.removeItem('itemsimCardPrice');
                            localStorage.removeItem('clickStep1');
                            localStorage.removeItem('clickStep2');
                            localStorage.removeItem('clickStep3');
                        } else {
                            popup.close();
                        }
                    }
                } catch (evt) {}
                if (popup.closed) {
                    $('#btn-select-payment').removeClass('btn-disabled').removeAttr('disabled');
                    clearInterval(interval);
                }
            }, 100);
        }
    }

    /**
     * Checking select pickup or delivery
     */
    checkDeliver() {
        if ($('#exampleRadios2').prop('checked') === true) {
            // if (this.allTotal > 0) {
            $('.hid-form').removeClass('d-none');
            $('.select-pinkup').removeClass('border-pink');
            $('.select-deliver').addClass('border-pink');
            this.orderServiceType = 'delivery';
            this.orderServiceTitle = 'Delivery';
            // }
        } else if ($('#exampleRadios1').prop('checked') === true) {
            $('.hid-form').addClass('d-none');
            $('.select-deliver').removeClass('border-pink');
            $('.select-pinkup').addClass('border-pink');
            this.orderServiceType = 'pick-up';
            this.orderServiceTitle = 'Pickup at yes hub';
            this.submitted = false;
            this.deliveryLocation = {};
        }
    }
    checkPickUpHub() {
        if ($('#exampleRadios1').prop('checked') === true) {
            $('.hid-form').addClass('d-none');
            $('.select-deliver').removeClass('border-pink');
            $('.select-pinkup').addClass('border-pink');
            this.orderServiceType = 'pick-up';
            this.orderServiceTitle = 'Pickup at yes hub';
            this.submittedPickUp = false;
            this.deliveryLocation = {};
        }
    }
    /**
     * Edit delivery province
     */
    editDelivery() {
        $('.frmDeliver .disabled').removeAttr('disabled').removeClass('disabled');
        $('.label-deliver').addClass('d-none');
        $('.btn-save, .btn-edit-address').toggleClass('d-none');
        this.editMessage = 'You need to save or cancel your work before go to the next.';
    }
    editPickUp() {
        $('.group-pickup .disabled').removeAttr('disabled').removeClass('disabled');
        $('.label-deliverhub').addClass('d-none');
        $('.btn-savehub, .btn-edit-addresshub').toggleClass('d-none');
        this.editMessage = 'You need to save or cancel your work before go to the next.';
    }
    editInputBox(id) {
        $('#' + id + '.disabled')
            .removeAttr('disabled')
            .removeClass('disabled');
        $('#' + id).addClass('d-none');
        $('.btn-savehub').removeClass('d-none');
        $('#pickupEdit').addClass('d-none');
        this.editMessage = 'You need to save or cancel your work before go to the next.';
    }
    editInputBoxDelivery(id) {
        $('#' + id + '.disabled')
            .removeAttr('disabled')
            .removeClass('disabled');
        $('#' + id).addClass('d-none');
        $('.btn-save').removeClass('d-none');
        $('#deliveryEdit').addClass('d-none');
        this.editMessage = 'You need to save or cancel your work before go to the next.';
    }
    /**
     * Cancel edit delivery
     */
    cancelDelivery() {
        this.cartService.getDelivery(this.phoneNumber).subscribe((data) => {
            this.dataDelivery = data;
            this.getaddress = this.dataDelivery.address;
            this.getProvince = this.dataDelivery.province;
            this.getphoneNumber = this.dataDelivery.phoneNumber;
            this.getEmail = this.dataDelivery.email;
            this.getFirstName = this.dataDelivery.firstName;
            this.getlastName = this.dataDelivery.lastName;
        });

        $('#province-input, #address-input, #firstName-input, #lastName-input, #email-input, #phoneNumber-input ')
            .addClass('disabled')
            .attr('disabled');
        // tslint:disable-next-line:max-line-length
        $(
            '.label-deliver#province-input, .label-deliver#address-input, .label-deliver#firstName-input, .label-deliver#lastName-input, .label-deliver#email-input, .label-deliver#phoneNumber-input'
        )
            .removeClass('disabled')
            .attr('disabled');
        $('.label-deliver').removeClass('d-none');
        $('.btn-save, .btn-cancel, .btn-edit-address').toggleClass('d-none');
        this.editMessageWarning = '';
        this.editMessage = '';
    }
    cancelPickUp() {
        this.cartService.getDelivery(this.phoneNumber).subscribe((data) => {
            this.dataDelivery = data;
            this.getaddress = this.dataDelivery.address;
            this.getProvince = this.dataDelivery.province;
            this.getphoneNumber = this.dataDelivery.phoneNumber;
            this.getEmail = this.dataDelivery.email;
            this.getFirstName = this.dataDelivery.firstName;
            this.getlastName = this.dataDelivery.lastName;
        });
        $('#phoneNumber, #email, #LastName, #firstName').addClass('disabled').attr('disabled');
        $('.label-deliverhub').removeClass('d-none');
        $('.btn-savehub, .btn-cancel, .btn-edit-addresshub').toggleClass('d-none');
        this.editMessageWarningHub = '';
        this.editMessage = '';
    }
    /**
     * Update edit delivery
     */
    saveDelivery() {
        this.submitted = true;

        if (this.frmDeliver.invalid) {
            console.log('invalid field');
            return;
        }
        this.deliveryLocation = {
            address: this.frmDeliver.get('address').value,
            province: this.frmDeliver.get('province').value,
            latitude: this.deliverLat,
            longitude: this.deliverLng,
            firstName: this.frmDeliver.get('firstName').value,
            lastName: this.frmDeliver.get('lastName').value,
            phoneNumber: this.frmDeliver.get('phoneNumber').value,
            email: this.frmDeliver.get('email').value
        };
        this.cartService.putDelivery(this.frmPickUp.get('phoneNumber').value, this.deliveryLocation).subscribe(
            (data) => {
                localStorage.setItem('phone', this.frmPickUp.get('phoneNumber').value);
                this.phoneNumber = this.frmPickUp.get('phoneNumber').value;
                return data;
            },
            (err) => {
                console.log(err);
            }
        );
        // localStorage.setItem(
        // 	'deliveryLocation',
        // 	JSON.stringify(this.deliveryLocation)
        // );
        $('#province-input, #address-input, #firstName-input, #lastName-input, #email-input, #phoneNumber-input ')
            .addClass('disabled')
            .attr('disabled');
        // tslint:disable-next-line:max-line-length
        $(
            '.label-deliver#province-input, .label-deliver#address-input, .label-deliver#firstName-input, .label-deliver#lastName-input, .label-deliver#email-input, .label-deliver#phoneNumber-input'
        )
            .removeClass('disabled')
            .attr('disabled');
        $('.label-deliver').removeClass('d-none');
        $('.btn-save, .btn-edit-address').toggleClass('d-none');
        this.editMessageWarning = '';
        this.editMessage = '';
    }
    savePickUp() {
        this.submittedPickUp = true;

        if (this.frmPickUp.invalid) {
            console.log('invalid field !');
            return;
        }
        this.deliveryLocation = {
            firstName: this.frmPickUp.get('firstName').value,
            lastName: this.frmPickUp.get('lastName').value,
            phoneNumber: this.frmPickUp.get('phoneNumber').value,
            email: this.frmPickUp.get('email').value
        };
        this.cartService.putDelivery(this.frmPickUp.get('phoneNumber').value, this.deliveryLocation).subscribe(
            (data) => {
                localStorage.setItem('phone', this.frmPickUp.get('phoneNumber').value);
                this.phoneNumber = this.frmPickUp.get('phoneNumber').value;
                return data;
            },
            (err) => {
                console.log(err);
            }
        );

        $('#phoneNumber, #email, #LastName, #firstName').addClass('disabled').attr('disabled');
        $('.label-deliverhub').removeClass('d-none');
        $('.btn-savehub, .btn-edit-addresshub').toggleClass('d-none');
        this.editMessageWarningHub = '';
        this.editMessage = '';
    }
    /**
     * Create marker on the map two
     * @param $event
     */
    mapClicked($event) {
        this.markers = {
            lat: $event.coords.lat,
            lng: $event.coords.lng
        };
    }

    /**
     * Selecting pin map
     * @param lat
     * @param lng
     * @param infowindow
     */
    positionChanged(lat, lng, infowindow) {
        this.lat = lat;
        this.lng = lng;
        this.zoom = 12;

        if (this.previous === infowindow) {
            return;
        }
        if (this.previous !== null) {
            this.previous.close();
        }
        this.previous = infowindow;
    }

    /**
     * Selecting pin map two
     * @param lat
     * @param lng
     * @param infowindow
     */
    positionChanged2(lat, lng, infowindow) {
        this.lat2 = lat;
        this.lng2 = lng;
        this.zoom2 = 10;

        if (infowindow) {
            if (this.previous2 === infowindow) {
                return;
            }
            if (this.previous2 !== null) {
                this.previous2.close();
            }
            this.previous2 = infowindow;
        }
    }

    /**
     * Selecting yes hub for pick up
     * @param i
     * @param name
     * @param address
     * @param id
     * @param province
     */
    getSelectedShop(i, name, address, id, province) {
        this.showShopName = name;
        this.showShopAddress = address;
        this.showSelectedHub = i;
        this.shopId = id;
        this.city = province;
        this.errSelectHub = '';
    }

    /**
     * Selecting your location on map two
     * @param lat
     * @param lng
     */
    getLocation(lat, lng) {
        this.deliverLat = lat;
        this.deliverLng = lng;
        this.errSelectMap = '';
    }

    /**
     * Selecting yes hub province on pick up
     * @param event
     */
    selectedProvince(event) {
        // this.allowLocation();
        const selectedProvince = event.target.value;
        this.showSelectedHub = 'hub';
        this.zoom = 11;
        switch (selectedProvince) {
            case 'Banteay Meanchey':
                this.lat = 13.654806;
                this.lng = 102.560528;
                break;
            case 'Battambang':
                this.lat = 13.102329;
                this.lng = 103.197459;
                break;
            case 'Kampong Cham':
                this.lat = 11.987948;
                this.lng = 105.461063;
                break;
            case 'Kampong Chhnang':
                this.lat = 12.253362;
                this.lng = 104.668137;
                break;
            case 'Kampong Speu':
                this.lat = 11.462155;
                this.lng = 104.521014;
                break;
            case 'Kampong Thom':
                this.lat = 12.709042;
                this.lng = 104.889488;
                break;
            case 'Kampot':
                this.lat = 10.616678;
                this.lng = 104.181403;
                break;
            case 'Kandal':
                this.lat = 11.482204;
                this.lng = 104.944432;
                break;
            case 'Kep':
                this.lat = 10.485879;
                this.lng = 104.320572;
                break;
            case 'Koh Kong':
                this.lat = 11.608967;
                this.lng = 102.982299;
                break;
            case 'Kratié':
                this.lat = 12.484836;
                this.lng = 106.017885;
                break;
            case 'Mondulkiri':
                this.lat = 12.45868;
                this.lng = 107.1875776;
                break;
            case 'Pailin':
                this.lat = 12.856927;
                this.lng = 102.60578;
                break;
            case 'Phnom Penh':
                this.lat = 11.541889;
                this.lng = 104.921333;
                break;
            case 'Preah Sihanouk':
                this.lat = 10.624028;
                this.lng = 103.523597;
                break;
            case 'Prey Veng':
                this.lat = 11.486498;
                this.lng = 105.325989;
                break;
            case 'Pursat':
                this.lat = 12.537239;
                this.lng = 103.918539;
                break;
            case 'Ratanakiri':
                this.lat = 13.737821;
                this.lng = 106.987511;
                break;
            case 'Siem Reap':
                this.lat = 13.35775;
                this.lng = 103.856444;
                break;
            case 'Svay Rieng':
                this.lat = 11.0843251;
                this.lng = 105.797938;
                break;
            case 'Takéo':
                this.lat = 10.979786;
                this.lng = 104.778004;
                break;
            case 'Tboung Khmum':
                this.lat = 11.9089629;
                this.lng = 105.6545335;
                break;

            default:
                break;
        }

        if (this.previous !== null) {
            this.previous.close();
            this.previous = null;
        }
        if (selectedProvince === 'city') {
            return;
        }

        this.findShopService.getSelfRunShops(this.language, selectedProvince).subscribe(
            (res) => {
                this.shops = res.data;
                this.shopId = '';
                this.showShopName = '';
                this.showSelectedHub = 'hub';
                // $('#pop-up-map').trigger('click');
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Selecting yes hup on select
     * @param event
     */
    selectedShop(event) {
        const selectedShop = event.target.value;
        if (selectedShop === 'hub') {
            return;
        }
        this.shopId = this.shops[selectedShop]._id;
        this.city = this.shops[selectedShop].localArea;
        this.showShopName = this.shops[selectedShop].name;
        this.showShopAddress = this.shops[selectedShop].address;
        this.errSelectHub = '';
    }

    /**
     * Prevent Translation
     * @param keyword
     */
    preventTranslate(keyword) {
        return this.keywords[keyword] ? this.keywords[keyword][this.language] : keyword;
    }

    stepOrder() {
        $('#pickup, #order-summary-show, #confirm, #payment').addClass('d-none');
        $('#order, #payment-card-show').removeClass('d-none');
        $('#progress-top-up').addClass('active');
        $('#progress-top-up').addClass('text-active');
        $('#progress-payment, #progress-confirm, #progress-success').removeClass('text-active');
    }
    stepPickupDelivery() {
        const hasClickStep1 = localStorage.getItem('clickStep1');
        if (hasClickStep1) {
            $('#order, #confirm, #payment, #payment-card-show').addClass('d-none');
            $('#pickup, #order-summary-show').removeClass('d-none');
            $('#progress-top-up, #progress-payment').addClass('active');
            $('#progress-payment').addClass('text-active');
            $('#progress-top-up, #progress-confirm, #progress-success').removeClass('text-active');
        }
    }
    stepReviewConfirm() {
        const hasClickStep1 = localStorage.getItem('clickStep1');
        const hasClickStep2 = localStorage.getItem('clickStep2');
        if (hasClickStep1 && hasClickStep2) {
            $('#order, #pickup, #payment, #payment-card-show').addClass('d-none');
            $('#confirm,  #order-summary-show').removeClass('d-none');
            $('#progress-top-up, #progress-payment, #progress-confirm ').addClass('active');
            $('#progress-confirm').addClass('text-active');
            $('#progress-top-up, #progress-payment, #progress-success').removeClass('text-active');
        }
    }
    stepPayment() {
        const hasClickStep1 = localStorage.getItem('clickStep1');
        const hasClickStep2 = localStorage.getItem('clickStep2');
        const hasClickStep3 = localStorage.getItem('clickStep3');
        if (hasClickStep1 && hasClickStep2 && hasClickStep3) {
            $('#order, #pickup, #confirm, #payment-card-show').addClass('d-none');
            $('#payment,  #order-summary-show').removeClass('d-none');
            $('#progress-top-up, #progress-payment, #progress-confirm, #progress-success').addClass('active');
            $('#progress-success').addClass('text-active');
            $('#progress-top-up, #progress-payment, #progress-confirm').removeClass('text-active');
        }
    }
    back() {
        this._location.back();
    }
}
