import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class GlobleService {
	apiUrl = environment.apiUrl;

	getHeader() {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'x-api-key':
				'eyJhbGciOiJIUzI1NiJ9.c2VhdGVsLWFwaS1kZXY.NsFhP0C7pqeONbcGAf42lQLLe6AIgy1QdOUOrg0uqcI',
			'x-platform': 'web'
		});
	}

	constructor(private router: Router) { }

	getUserToken() {
		return localStorage.getItem('accessToken')
			? localStorage.getItem('accessToken')
			: null;
	}
}
