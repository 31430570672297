import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetLuckyDrawChristmasService } from '../../service/get-lucky-draw-christmas.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  id: string;
  keywords: any = {};
  language: string;
  phoneNumber: string;
  banner: string;
  popupWelcome: string;
  deviceUrl: any;
  getdataLuckyDraw = new Winner;

  title: string ="Only $1 top up, you can a stand a chance to win grand prize, a Honda Scoopy 2021.";

  keyword = {
		bannerUrl: {
			en: '/assets/new-event/Top+up+win+English+Web-min.jpg',
			km: '/assets/new-event/Top+Up+win+Khmer+WEb-min.jpg',
			zh: '/assets/new-event/Top+up+Win+Chinese+Web-min.jpg'
		},
		popupWelcomeUrl: {
			en: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-EN.jpg',
			km: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-KH.jpg',
			zh: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-EN.jpg'
		}
	};

  constructor(private LuckyDrawChristmas: GetLuckyDrawChristmasService, private route: ActivatedRoute,) { }

  ngOnInit() {

    this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
	this.language = localStorage.getItem('language') || 'en';
	this.getEventData();

  }
	getDataLuckyDrawChristmas() {
		this.LuckyDrawChristmas.getDataLuckyDraw(this.phoneNumber).subscribe(data => {
			const result = (data as any);
			if(result.length > 0) this.getdataLuckyDraw = result[0];
			console.log(this.getdataLuckyDraw);
		});
	}

	getSearch(value) {
		this.phoneNumber = value;
		this.getDataLuckyDrawChristmas();
	}

    getEventData(){
    switch (this.language) {
			case 'en': {
        this.banner = this.keyword.bannerUrl.en;
        this.popupWelcome = this.keyword.popupWelcomeUrl.en
				break;
			}
			case 'km': {
        this.banner = this.keyword.bannerUrl.km;
        this.popupWelcome = this.keyword.popupWelcomeUrl.km

				break;
			}
			case 'zh': {
        this.banner = this.keyword.bannerUrl.zh;
        this.popupWelcome = this.keyword.popupWelcomeUrl.zh
				break;
			}
			default: {
        this.banner = this.keyword.bannerUrl.en;
        this.popupWelcome = this.keyword.popupWelcomeUrl.en
			}
		}
	}
	preventTranslate(keywords) {
		if (this.keywords) {
			if (this.keywords[keywords]) {
				return this.keywords[keywords][this.language];
			} else {
				return keywords;
			}
		} else {
			return keywords;
		}
	}
	preventTranslateLocal(keyword) {
		return this.keyword[keyword]
			? this.keyword[keyword][this.language]
			: keyword;
	}

}

class Winner {
	prize= '';
	luckyCodes:LuckyCodes[]= [new LuckyCodes];
}
class LuckyCodes {
	phoneNumber = '';
	luckyCode = '';
	winAt = '';
	expiredAt = '';
}

