import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-top-up',
	templateUrl: './top-up.component.html',
	styleUrls: ['./top-up.component.scss']
})
export class TopUpComponent implements OnInit {
	keywords: any = {};
	language: string;
	subtitle =
		'yes top up cards are available nationwide at yes hub, gas stations, Wing & true money agents as well as major banks.';
	// keywords = {
	//     desktopUrl: {
	//         en: '/assets/top-up/en-banner.jpg',
	//         km: '/assets/top-up/km-banner.jpg',
	//         zh: '/assets/top-up/en-banner.jpg'
	//     },
	//     mobileUrl: {
	//         en:
	//             '/assets/top-up/Product and Service mobile App [1080 x 900px]-09.png',
	//         km:
	//             '/assets/top-up/Product and Service mobile App [1080 x 900px]-10.png',
	//         zh:
	//             '/assets/top-up/Product and Service mobile App [1080 x 900px]-09.png'
	//     }
	// };
	constructor() {
		const getLanguage = localStorage.getItem('language') || 'en';
	}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.keywords.desktopUrl = {
			en: '/assets/top-up/en-banner.jpg',
			km: '/assets/top-up/km-banner.jpg',
			zh: '/assets/top-up/en-banner.jpg'
		};
		this.keywords.mobileUrl = {
			en: '/assets/top-up/Product and Service mobile App [1080 x 900px]-09.png',
			km: '/assets/top-up/Product and Service mobile App [1080 x 900px]-10.png',
			zh: '/assets/top-up/Product and Service mobile App [1080 x 900px]-09.png'
		};
		this.language = localStorage.getItem('language');
	}
	preventTranslate(keyword) {
		return this.keywords && this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
