import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';

@Component({
	selector: 'app-where-to-topup',
	templateUrl: './where-to-topup.component.html',
	styleUrls: ['./where-to-topup.component.scss']
})
export class WhereToTopupComponent implements OnInit {
	keywords: any = {};
	language: string;
	formTitle: string;
	imgLocation: string;
	sectionOne: any = [];
	sectionTwo: any = [];
	lists: any;
	feature: any;
	desktopImage: string;
	titleOne: string;
	titleTwo: string;
	eTopupImage: string;
	partnerImage: string;
	constructor(private productService: ProductsServicesService) { }

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.formTitle = '/assets/wheretotopup/yes-form-title.png';
		this.imgLocation = '/assets/wheretotopup/yes-location.png';
		this.getWhereToTopup();
	}

	getWhereToTopup() {
		this.productService.getWhereToTopup(this.language).subscribe(res => {
			console.log(res);
			
			this.sectionOne = res.section1;
			this.sectionTwo = res.section2;
			//Section One
			this.titleOne = this.sectionOne.title;
			this.feature = this.sectionOne.featureList;
			this.lists = this.sectionOne.list;
			this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
			//Section Two
			this.titleTwo = this.sectionTwo.title;
			this.eTopupImage = `${this.sectionTwo.featureImageUrl}`;
			this.partnerImage = `${this.sectionTwo.imageUrl}`;
		});
	}

	/**
	 * Prevent translate
	 * @param keyword
	 */
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
