import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { GlobleService } from './globle.service';

@Injectable()
export class ProductsServicesService {
    rootUrl: string = this.globalService.apiUrl;
    header: any = this.globalService.getHeader();

    constructor(private http: HttpClient, private globalService: GlobleService) {}

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    getProducts(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getOnePlans(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/one-plan?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getRikReay(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/v2/products/plan-page/rik-reay?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getWhereToTopup(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/where-to-topup?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getPromotionYouTube(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/unlimited-youtube?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getYouTubeStar(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/youtube-star?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getInternetForAll(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/internet-for-all?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getFreeData(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/free-internet?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    buyData(body): Observable<any> {
        // const buyMoreFilter = buyMore ? { buyMore } : {};
        // const body = { phoneNumber, amount, type, ...buyMoreFilter };

        return this.http.post(`${this.rootUrl}/seatel/buy-data`, body, {
            headers: this.header
        });
    }
    getPlanPackage(lang, subPlan) {
			return this.http
            .get(`${this.rootUrl}/v2/products/${subPlan}?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
		}
		getVipFortunePack(lang) {
			return this.http
            .get(`${this.rootUrl}/v2/products/vip-fortune-pack?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
		}
    getSpecialNumberPrice(lang) {
        return this.http
            .get(`${this.rootUrl}/pages/add-ons/special-number-price?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    get10GBPromotion(lang) {
        return this.http
            .get(`${this.rootUrl}/pages/add-ons/10gb-promotion?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getDataPack(lang) {
        return this.http
            .get(`${this.rootUrl}/v2/products/internet-pack?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getValuePack(lang) {
        return this.http
            .get(`${this.rootUrl}/products/value-pack?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
    getTouristSim(lang) {
        return this.http
            .get(`${this.rootUrl}/products/tourist-sim?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getDataPackInternetPlan(phone) {
        return this.http
            .get(`${this.rootUrl}/seatel/internet-plans?phoneNumber=${phone}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getInternationCall(lang): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/products/international-call?lang=${lang}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getInternationalCountry(): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/international-call`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getInternationalCountryFilter(filter): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/international-call?country=${filter}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getNewTouristSimPlan(lang) {
        const pathToData = './assets/product-services/new-tourist-sim/data/tourist-new-plan-data.json';
        return this.http.get(pathToData).map((res: any) => {
            let data: any;
            if (lang === 'km') {
                data = res.data.km;
            } else if (lang === 'zh') {
                data = res.data.zh;
            } else {
                data = res.data.en;
            }

            return data;
        });
    }


    //CDTV

    getUserId(phone) {
        return this.http
            .get(`${this.rootUrl}/seatel/main?phoneNumber=${phone}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    buyTvPack(body): Observable<any> {
        return this.http.post(`${this.rootUrl}/seatel/subscribe`, body, {
            headers: this.header
        });
    }

    getUserSubscription(userId){
        return this.http
            .get(`${this.rootUrl}/seatel/user-subscription?userId=${userId}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    //LoyKob

    buyLoykob(body): Observable<any> {
        return this.http.post(`${this.rootUrl}/seatel/subscribeloykob`, body, {
            headers: this.header
        });
    }

    buyAddonLoykob(body): Observable<any> {
        return this.http.post(`${this.rootUrl}/seatel/addon-loykob`, body, {
            headers: this.header
        });
    }

    getUserSubscriptionLoykob(userId){
        return this.http
            .get(`${this.rootUrl}/seatel/user-subscription-loykob?userId=${userId}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    getUserAddonLoykob(userId){
        return this.http
            .get(`${this.rootUrl}/seatel/user-subscription-addon-loykob?userId=${userId}`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }
}
