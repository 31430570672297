import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { HomePageService } from '../../../service/home-page.service';
import { concat } from '../../../../../node_modules/rxjs/operator/concat';
import { IOption } from '../../../../../node_modules/ng-select';

@Component({
	selector: 'app-international-call',
	templateUrl: './international-call.component.html',
	styleUrls: ['./international-call.component.scss']
})
export class InternationalCallComponent implements OnInit {
	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	language: string;
	desktopImage: string;
	mobileImage: string;
	international: any;
	public keywords;
	threeCents: any = {};
	sevenCents: any = {};
	tenCents: any = {};
	fifteenCents: any = {};
	twentyFiveCents: any = {};
	thirtyCents: any = {};
	AllCountry: any = {};
	countries: Country[] = [];
	results: Internation[] = [];
	allcountries: Country[] = [];
	BaseData: any = {};
	selectCountry: any = {};
	whatType: any = {};
	brands = [];
	sectiontwo: any;
	steps: any;
	stepImage: any;
	internationalTitle: any;
	banner: any;
	allCountry: any;
	listCountry: any;
	filter: any;
	filterCountry: any;

	constructor(
		private productService: ProductsServicesService,
		private homePageService: HomePageService
	) { }
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.getInternation();
		this.getInternationalCountry();
	}
	getInternation() {
		this.productService.getInternationCall(this.language).subscribe(res => {
			this.international = res;
			this.banner = this.international.section1;
			this.desktopImage = `${this.banner.desktopImageUrl}`;
			this.mobileImage = `${this.banner.mobileImageUrl}`;
			this.sectiontwo = this.international.section2;
			this.steps = this.sectiontwo.steps;
			this.internationalTitle = this.sectiontwo.title;
			this.stepImage = this.sectiontwo.desktopImageUrl;
			$('.bg-phone-dial').css(
				'background-image',
				`url(${this.stepImage
				})`
			);
		});
	}
	getInternationalCountry() {
		this.productService.getInternationalCountry().subscribe(data => {
			this.allCountry = data;
			this.countries = [...data.map(x => x.countries)] as any;
			const countrys: SelectorCountry[] = [];

			this.AllCountry = [...this.countries];

			// tslint:disable-next-line:forin
			for (const i in this.AllCountry) {
				const CountryNames = this.AllCountry[i].map(x => x.country);
				// tslint:disable-next-line:forin
				for (const j in CountryNames) {
					const CountryName = CountryNames[j];
					countrys.push(new SelectorCountry(CountryName));
				}
			}
			this.brands = countrys;
		});
	}

	onChangeCountry(event) {
		this.filter = event.label;
		this.productService.getInternationalCountryFilter(this.filter).subscribe(data => {
			this.allCountry = data;
		});
	}

	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
export class SelectorCountry {
	constructor(public label: string) { }
}
export class Countrys {
	country: string;
	code: string;
	typeCode: string;
	image: string;
}
interface Country {
	country: string;
	code: string;
	typeCode: string;
	image: string;
}
interface Internation {
	type: string;
	typeCode: string;
	countries: Country[];
}
