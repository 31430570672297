import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareButtonsModule } from 'ngx-sharebuttons';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { SidebarComponent } from '../includes/sidebar/sidebar.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [
		CommonModule,
		AdminRoutingModule,
		NgxGaugeModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ShareButtonsModule.forRoot()
	],
	declarations: [AdminComponent, DashboardComponent, SidebarComponent]
})
export class AdminModule { }
