import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import 'rxjs/add/observable/timer';

import { RegisterServiceService } from '../../service/register-service.service';
import { UploadServiceService } from '../../service/upload-service.service';

@Component({
	selector: 'app-self-registration-profile',
	templateUrl: './self-registration-profile.component.html',
	styleUrls: ['./self-registration-profile.component.scss']
})
export class SelfRegistrationProfileComponent implements OnInit {
	language: string;
	keywords: any;
	typeRegister = 'register-profile';
	frmRegister: FormGroup;
	frmRegisterInfo: FormGroup;
	sumitted = false;
	sumittedCode = false;
	phonePattern = /^\d+$/;
	errorMsg: any;
	cfmCodeError: string;
	code: string;
	code1: string;
	code2: string;
	code3: string;
	code4: string;
	code5: string;
	phoneNumber: string;
	hasPhoneNumber: string;
	hasCode: string;
	certification: any = [];
	langs: any = [];
	frontIdImage: any = [];
	fronId: any = [];
	frmInformation: any = [];
	backIdImage: any = [];
	backId: any = [];
	submitted = false;
	errCheckAgree: string;
	buttonDisabled: string;
	profile: any;
	hasProfile: any;
	checkIdTypeValue: string;
	countDown;
	counter = 120;
	tick = 1000;
	selectLange: string;
	profileError: any;
	certId: any;
	today = Date.now();
	fixedTimezone = this.today;
	constructor(
		private router: Router,
		private registerServiceService: RegisterServiceService,
		private fileService: UploadServiceService
	) { }

	ngOnInit() {
		this.language = localStorage.getItem('language');
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		const confirmFinish = localStorage.getItem('confirmFinish');
		if (confirmFinish) {
			localStorage.removeItem('hasPhoneNumber');
			localStorage.removeItem('hasCode');
			localStorage.removeItem('hasProfile');
			localStorage.removeItem('confirmFinish');
			this.hasPhoneNumber = '';
			this.hasCode = '';
			this.phoneNumber = '';
		}
		$('#confirmCode, #inputInfor, #confirmation').addClass('d-none');
		if (window.screen.width < 500) {
			$('.step3, .step4').addClass('d-none');
		}

		this.getLanguages();
		this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');
		if (this.hasPhoneNumber) {
			$('#confirmCode').removeClass('d-none');
			$('#inputPhoneNumber, #inputInfor, #confirmation').addClass('d-none');
			$('.step1').removeClass('active');
			$('.step2').addClass('active');
		}
		this.hasCode = localStorage.getItem('hasCode');
		if (this.hasPhoneNumber && this.hasCode) {
			$('#inputInfor').removeClass('d-none');
			$('#inputPhoneNumber, #confirmCode, #confirmation').addClass('d-none');
			$('.step1, .step2').removeClass('active');
			$('.step3').addClass('active');
			if (window.screen.width < 500) {
				$('.step3').addClass('active');
				$('.step1, .step2').addClass('d-none');
				$('.step3, .step4').removeClass('d-none');
			}
		}
		this.frmRegister = new FormGroup({
			phoneNumber: new FormControl('', [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			])
		});
		this.frmRegisterInfo = new FormGroup({
			name: new FormControl('', Validators.required),
			certId: new FormControl('', Validators.required),
			documentNumber: new FormControl('', Validators.required),
			languageId: new FormControl('', Validators.required),
			frontIDPhotoUrl: new FormControl('', Validators.required),
			backIDPhotoUrl: new FormControl('', Validators.required)
		});
		$(function () {
			$('#documentNumber, #name').keypress(function (event) {
				// console.log('555');
				const ew = event.which;
				if (ew === 32) {
					return true;
				}
				if (48 <= ew && ew <= 57) {
					return true;
				}
				if (65 <= ew && ew <= 90) {
					return true;
				}
				if (97 <= ew && ew <= 122) {
					return true;
				}
				if (86 <= ew && ew <= 17) {
					return true;
				}
				return false;

			});

		});
		// $('#name').keyup(function (event) {
		// 	const letters = /^[A-Za-z]+$/;
		// 	const value = (<HTMLInputElement>event.target).value;
		// 	console.log('value =', value);
		// 	if ((<HTMLInputElement>event.target).value = /^[A-Za-z0-9]*$/) {
		// 		console.log('1');
		// 	} else {
		// 		console.log('tst');
		// 	}
		// 	// return false;


		// });
		$('.inputs').keyup(function () {
			const value = (<HTMLInputElement>event.target).value;
			const maxLength = (<HTMLInputElement>event.target).maxLength;
			if (value.length === maxLength) {
				const $next = $(this).next('.inputs');
				if ($next.length) {
					$(this)
						.next('.inputs')
						.focus();
				} else {
					$(this).blur();
				}
			} else {
				const $prev = $(this).prev('.inputs');
				if ($prev.length) {
					$(this)
						.prev('.inputs')
						.focus();
				} else {
					$(this).blur();
				}
			}
		});
		this.getCertification();
		this.hasProfile = JSON.parse(localStorage.getItem('hasProfile'));
		if (this.hasProfile) {
			this.certId = this.certification.filter(
				x => x.certId === this.hasProfile.certId
			);
			for (const i of this.certId) {
				this.hasProfile.certId = i.certName;
			}
			$('#confirmation').removeClass('d-none');
			$('#inputPhoneNumber, #confirmCode, #inputInfor').addClass('d-none');
			$('.step1, .step2, .step3').removeClass('active');
			$('.step4').addClass('active');
			if (window.screen.width < 500) {
				$('.step1, .step2').addClass('d-none');
				$('.step3, .step4').removeClass('d-none');
				$('.step4').addClass('active');
			}
		}
	}
	onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const validCharacters = /^[a-zA-Z\s]*$/;
		if (validCharacters.test(pastedText) === true) {
			return true;
		} else {
			return false;
		}


	}
	/**
	 *  Validation form register
	 */
	get frmValidate() {
		return this.frmRegister.controls;
	}
	/**
	 *  Validation form register
	 */
	get f() {
		return this.frmRegisterInfo.controls;
	}
	/**
	 *  Translate
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
	requestCode(e) {
		this.sumitted = true;
		if (this.phoneNumber === '' || this.phoneNumber === undefined) {
			return;
		}
		if (this.hasPhoneNumber) {
			this.phoneNumber = this.hasPhoneNumber;
		}
		const body = {
			phoneNumber: this.frmRegister.get('phoneNumber').value,
			type: this.typeRegister
		};
		this.registerServiceService.requestCode(body).subscribe(
			data => {
				this.countDownMin();
				$('#confirmCode').removeClass('d-none');
				$('#inputPhoneNumber, #inputInfor').addClass('d-none');
				$('.step1, .step4, .step3').removeClass('active');
				$('.step2').addClass('active');
				this.code = '';
				this.code1 = '';
				this.code2 = '';
				this.code3 = '';
				this.code4 = '';
				this.code5 = '';
				localStorage.setItem('hasPhoneNumber', this.phoneNumber);
				this.cfmCodeError = '';
				this.sumittedCode = false;
			},
			err => {
				this.errorMsg = err.error.message;
			}
		);
	}

	countDownMin() {
		// tslint:disable-next-line:no-unused-expression
		this.countDown;
		this.counter = 120;
		this.tick = 1000;
		this.countDown = Observable.timer(0, this.tick)
			.take(this.counter)
			.map(() => --this.counter);
	}
	/**
	 *  Submit confirm code
	 */
	confirmCode(
		inputCode,
		inputCode1,
		inputCode2,
		inputCode3,
		inputCode4,
		inputCode5
	) {
		this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');
		this.sumittedCode = true;
		if (
			inputCode.value === '' &&
			inputCode1.value === '' &&
			inputCode2.value === '' &&
			inputCode3.value === '' &&
			inputCode4.value === '' &&
			inputCode5.value === ''
		) {
			return;
		}
		const body = {
			code:
				inputCode.value +
				inputCode1.value +
				inputCode2.value +
				inputCode3.value +
				inputCode4.value +
				inputCode5.value,
			phoneNumber: this.hasPhoneNumber,
			action: this.typeRegister
		};
		this.registerServiceService.confirmCode(body).subscribe(
			res => {
				$('#inputInfor').removeClass('d-none');
				$('#inputPhoneNumber, #confirmCode').addClass('d-none');
				$('.step1, .step2, .step4').removeClass('active');
				$('.step3').addClass('active');
				if (window.screen.width < 500) {
					$('.step1, .step2').addClass('d-none');
					$('.step3, .step4').removeClass('d-none');
				}
				localStorage.setItem('hasCode', this.code);
			},
			err => {
				this.cfmCodeError = err.error.message;
			}
		);
	}
	getCertification() {
		this.registerServiceService.getCertification().subscribe(data => {
			this.certification = data;
			if (this.hasProfile) {
				this.certId = this.certification.filter(
					x => x.certId === this.hasProfile.certId
				);
				for (const i of this.certId) {
					this.hasProfile.certId = i.certName;
				}
			}
		});
	}
	getLanguages() {
		this.registerServiceService.getLanguages().subscribe(data => {
			this.langs = data;
		});
	}
	/**
	 * Upload front Id image
	 * @param event
	 * @param files
	 */
	uploadFrontId(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.frontIdImage = events.target.result;
				$('label[for="file-input"] .upload-icon').css('border', 'none');
				$('label[for="file-input"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.fronId = data.data[0].filename;
				this.frmRegisterInfo.controls.frontIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	/**
	 * Upload back Id image
	 * @param event
	 * @param files
	 */
	uploadBackId(event: any, files: FileList) {
		// console.log('files', files);
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.backIdImage = events.target.result;
				$('label[for="file-input1"] .upload-icon').css('border', 'none');
				$('label[for="file-input1"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.backId = data.data[0].filename;
				this.frmRegisterInfo.controls.backIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	checkAgree() {
		if ($('#checkAgree').prop('checked') === true) {
			this.errCheckAgree = '';
		}
	}
	registerProfile() {
		this.submitted = true;
		this.phoneNumber = localStorage.getItem('hasPhoneNumber');
		if (this.frmRegisterInfo.invalid) {
			return;
		}
		if (
			this.frmRegisterInfo.invalid &&
			this.frmRegisterInfo.get('certId').value === 'ID Type' &&
			this.frmRegisterInfo.get('languageId').value === 'language'
		) {
			return;
		}
		if (!$('#checkAgree').prop('checked') === true) {
			this.errCheckAgree = 'Please check term and conditions.';
			return;
		}

		this.profile = {
			phoneNumber: this.hasPhoneNumber,
			name: this.frmRegisterInfo.get('name').value,
			certId: Number(this.frmRegisterInfo.get('certId').value),
			documentNumber: this.frmRegisterInfo.get('documentNumber').value,
			languageId: Number(this.frmRegisterInfo.get('languageId').value),
			frontIDPhotoUrl: this.fronId,
			backIDPhotoUrl: this.backId
		};
		localStorage.setItem('hasProfile', JSON.stringify(this.profile));
		this.hasProfile = JSON.parse(localStorage.getItem('hasProfile'));
		if (this.hasProfile) {
			this.certId = this.certification.filter(
				x => x.certId === this.hasProfile.certId
			);
			for (const i of this.certId) {
				this.hasProfile.certId = i.certName;
			}
		}
		$('#confirmation').removeClass('d-none');
		$('#inputInfor, #inputPhoneNumber, #confirmCode').addClass('d-none');
		$('.step1, .step2, .step3').removeClass('active');
		$('.step4').addClass('active');
		if (window.screen.width < 500) {
			$('.step1, .step2').addClass('d-none');
			$('.step3, .step4').removeClass('d-none');
		}
	}
	confirmFinish(e) {
		this.hasProfile = JSON.parse(localStorage.getItem('hasProfile'));
		if (this.hasProfile) {
			this.certId = this.certification.filter(
				x => x.certId === this.hasProfile.certId
			);
			for (const i of this.certId) {
				this.hasProfile.certId = i.certId;
			}
		}
		this.registerServiceService.registerProfile(this.hasProfile).subscribe(data => {
			swal(
				'',
				// tslint:disable-next-line:max-line-length
				this.preventTranslate('Your profile registration has been submitted successfully. Our team will check this application as soon as possible.'),
				'success',
			);
			localStorage.setItem('confirmFinish', 'true');
			localStorage.removeItem('hasPhoneNumber');
			localStorage.removeItem('hasCode');
			localStorage.removeItem('hasProfile');
			this.hasPhoneNumber = '';
			this.hasCode = '';
			this.phoneNumber = '';
			$('#confirmCode, #inputInfor, #confirmation').addClass('d-none');
			$('#inputPhoneNumber').removeClass('d-none');
			$('.step4').removeClass('active');
			$('.step1').addClass('active');
		},
			err => {
				this.profileError = err.error.message;
			}
		);
	}
	download(e) {
		const profile = JSON.parse(localStorage.getItem('hasProfile'));
		if (profile) {
			this.certId = this.certification.filter(
				x => x.certId === this.hasProfile.certId
			);
			for (const i of this.certId) {
				this.hasProfile.certId = i.certId;
			}
		}
		this.registerServiceService.downloadRegister(profile).subscribe(data => {
			window.open(data.link);
		});
	}
	/**
	 * 855189494385 => 0189494385
	 * @param mSISDN phone number
	 */
	toLocalPhoneNumber(mSISDN: string) {
		if (typeof mSISDN === 'string') {
			mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
			mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
		}
		return mSISDN;
	}
}
