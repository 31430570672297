import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customSearch'
})
@Injectable()
export class CustomSearchPipe implements PipeTransform {

    transform(inputKeyword: any[], field: string, value: string): any[] {
        if (!inputKeyword) { return []; }
        return inputKeyword.filter(it => it[field] === value);
    }

}
