import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';

@Component({
	selector: 'app-internet-for-all',
	templateUrl: './internet-for-all.component.html',
	styleUrls: ['./internet-for-all.component.scss']
})
export class InternetForAllComponent implements OnInit {

	language: string;
	keywords: any = {};
	hideContent = true;
	internetForAll: any;
	getbanner: any;
	desktopImageUrl: string;
	mobileImageUrl: string;
	section2: any;
	section2description: string;
	section2desktopImageUrl: string;
	section3: any;
	section3description: string;
	section4: any;
	section4desktopImageUrl: string;
	section4description: string;
	section3desktopImageUrl: string;
	section3mobileImageUrl: string;
	keyword = {
		desktopUrl: {
			en: '/assets/YouTube-Star/Youtube-Star-Update-Web-EN.jpg',
			km: '/assets/YouTube-Star/Youtube-Star-Update-Web-KH.jpg',
			zh: '/assets/YouTube-Star/Youtube-Star-Update-Web-EN.jpg'
		},
		mobileUrl: {
			en: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-EN.jpg',
			km: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-KH.jpg',
			zh: '/assets/YouTube-Star/Youtube-Star-Update-Mobile-EN.jpg'
		}
	};
	constructor(private productService: ProductsServicesService) { }

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.getInternetForAll();
	}
	getInternetForAll() {
		this.productService.getInternetForAll(this.language).subscribe(data => {
			this.internetForAll = data;
			// console.log('test', this.internetForAll);
			this.getbanner = this.internetForAll.section1;
			this.desktopImageUrl = this.getbanner.desktopImageUrl;
			this.mobileImageUrl = this.getbanner.mobileImageUrl;
			this.section2 = this.internetForAll.section2;
			this.section2description = this.section2.description;
			this.section2desktopImageUrl = this.section2.desktopImageUrl;
			this.section3 = this.internetForAll.section3;
			this.section3desktopImageUrl = this.section3.desktopImageUrl;
			// this.section3mobileImageUrl = this.section3.mobileImageUrl;
			this.section3description = this.section3.description;
			// this.section4 = this.internetForAll.section4;
			// this.section4description = this.section4.description;
			// this.section4desktopImageUrl = this.section4.desktopImageUrl;

			$('.trawel-unlimite-2').css(
				'background-image',
				`url(${this.section2desktopImageUrl})`
			);
			$('.free-data-bg').css(
				'background-image',
				`url(${this.section3desktopImageUrl})`
			);
			this.hideContent = false;
		});
	}
	preventTranslate(keywords) {
		if (this.keywords) {
			if (this.keywords[keywords]) {
				return this.keywords[keywords][this.language];
			} else {
				return keywords;
			}
		} else {
			return keywords;
		}
	}
	preventTranslateLocal(keyword) {
		return this.keyword[keyword]
			? this.keyword[keyword][this.language]
			: keyword;
	}

}
