import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AboutPageService } from '../../../service/about-page.service';

@Component({
	selector: 'app-management',
	templateUrl: './management.component.html',
	styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
	language: string;
	name: string;
	position: string;
	description: string;
	management: any;
	managementDetail: any = [];
	public keywords;
	ManagementBanner: any = [];

	constructor(private aboutPageService: AboutPageService) {
	}

	ngOnInit() {
		this.language = localStorage.getItem('language') || 'en';
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.getManagementTeam();
		this.getManagementBanner();
	}
	getManagementTeam() {
		this.aboutPageService.getManagementTeam(this.language).subscribe(
			data => {
				this.management = data;
			});
	}
	getManagementDetail(id) {
		this.aboutPageService.getManagementTeamDetail(this.language, id).subscribe(
			data => {
				this.managementDetail = data.data;
			});
	}
	getManagementBanner() {
		this.aboutPageService.getManagementBanner(this.language).subscribe(data => {
			this.ManagementBanner = data.section1;
		});
	}
	getTeamDetail(event) {
		const id = event.currentTarget.id;
		this.getManagementDetail(id);
		const el = document.getElementById('team-detail-scroll');
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		$('#team-detail').addClass('show');
		$('.abso-img img').attr('src',
			$('#' + id + ' img').attr('src')
		);
		$('.col-cm-3').removeClass('suicide');
		$('#' + id).addClass('suicide');

		$('.abso-img img').removeClass('flyIn');

		$('.abso-img img').addClass('flyIn');

		$('#management-team').addClass('hide');
	}

	toggleClose() {
		$('#team-detail').removeClass('show');
		$('.col-cm-3').removeClass('suicide');

		$('#management-team').removeClass('hide');
		setTimeout(function () {
			$('.abso-img img').removeClass('flyIn');
		}, 800);
	}

	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}

}
