import { Injectable } from '@angular/core';
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders
} from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobleService } from './globle.service';

@Injectable()
export class LoginServiceService {
	url: string = this.globleService.apiUrl;
	header: any = this.globleService.getHeader();

	constructor(
		private globleService: GlobleService,
		private http: HttpClient,
		private router: Router
	) {}

	/**
	 * Check login by phone number
	 * @param phoneNumber
	 * @param password
	 */
	login(phoneNumber, password, token) {
		return this.http
			.post(
				this.url + '/v2/seatel/login',
				{
					phoneNumber: phoneNumber,
					password: password,
					token: token? token: '',
				},
				{ headers: this.header }
			)
			.toPromise()
			.catch((e: HttpErrorResponse) => {
				this.router.navigate(['login']);
				return e.error;
			});
	}

	/**
	 * Check login by social
	 * @param socialId
	 * @param socialType
	 */
	socialLogin(socialId, socialType) {
		return this.http.post(
			this.url + '/seatel/verify-socialId',
			{
				socialId,
				socialType
			},
			{ headers: this.header }
		);
	}

	/**
	 * Get information user from instagram
	 * @param accessToken
	 */
	instagramLogin(accessToken) {
		return this.http.get(
			'https://api.instagram.com/v1/users/self/?access_token=' + accessToken
		);
	}

	identifyUser() {
		return 'ok link';
	}

	// linkedinLogin(codeParam) {
	// 	return this.http.post(
	// 		'https://www.linkedin.com/oauth/v2/accessToken',
	// 		{
	// 			grant_type: 'authorization_code',
	// 			code: codeParam,
	// 			redirect_uri: 'http://localhost:4200/',
	// 			client_id: '8624h86438oe4q',
	// 			client_secret: 'eAo02tUl9o7gYicc'
	// 		},
	// 		{
	// 			headers: new HttpHeaders({
	// 				'Content-Type': 'application/json',
	// 				'Access-Control-Allow-Origin': '*',
	// 				'Access-Control-Allow-Methods':
	// 					'GET, POST, OPTIONS, PUT, PATCH, DELETE',
	// 				'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
	// 				'Access-Control-Allow-Credentials': 'true'
	// 			})
	// 		}
	// 	);
	// }
}
