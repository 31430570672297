import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../../node_modules/@angular/router';
import { HomePageService } from '../../../service/home-page.service';
import { FaqService } from '../../../service/faq.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
	language: string;
	keywords: any = {};

	faqCategories: any = [];
	faqCategoryRows: any = [];
	categoryId = '';
	constructor(
		private router: Router,
		private homePageService: HomePageService,
		private faqService: FaqService
	) {}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.keywords.desktopUrl = {
			en: '/assets/support/SupportBannerpage.jpg',
			km: '/assets/support/SupportBannerpage-KH.jpg',
			zh: '/assets/support/SupportBannerpage.jpg'
		};
		this.keywords.mobileUrl = {
			en:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg',
			km:
				'/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-36.jpg',
			zh: '/assets/support/Product-and-Service-mobile-App-[1080-x-900px]-35.jpg'
		};
		this.getFaqCategory();
	}
	questionLink(categoryId) {
		this.router.navigate(['/faq/category'], {
			queryParams: { id: categoryId }
		});
	}

	getFaqCategory() {
		this.faqService.getFaqCategory(this.language).subscribe(res => {
			this.faqCategories = res.data;
		});
	}

	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
interface Question {
	id: string;
	text: string;
}
interface Faq {
	type: string;
	question: Question[];
}
