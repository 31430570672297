import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-tengb-promotion',
    templateUrl: './tengb-promotion.component.html',
    styleUrls: ['./tengb-promotion.component.scss']
})
export class TengbPromotionComponent implements OnInit {
    bannerImageDesktop: string;
    bannerImageMobile: string;
    main10GBpromo: any = [];
    title: string;
    description: string;
    termAndCondition: string;
    termCondition: any = [];

    language: string;
    public keywords;

    constructor(private translate: TranslateService, private productService: ProductsServicesService) {
        const getLanguage = localStorage.getItem('language') || 'en';
        translate.setDefaultLang(getLanguage);
        translate.use(getLanguage);
        localStorage.setItem('language', getLanguage);
        this.language = getLanguage;
    }

    ngOnInit() {
        this.language = localStorage.getItem('language');
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.get10GBpromotions();
    }

    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }

    get10GBpromotions() {
        this.productService.get10GBPromotion(this.language).subscribe((data) => {
            // console.log(data);
            this.main10GBpromo = data;
            this.bannerImageDesktop = this.main10GBpromo.section1.desktopImageUrl;
            this.bannerImageMobile = this.main10GBpromo.section1.mobileImageUrl;
            this.title = this.main10GBpromo.section1.title;
            this.description = this.main10GBpromo.section1.description;
            this.termAndCondition = this.main10GBpromo.section2.termAndCondition;
        });
    }
}
