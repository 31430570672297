import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FaqLayoutComponent } from './faq-layout.component';
import { FaqAnswerComponent } from './faq-answer/faq-answer.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
	{
		path: '',
		component: FaqLayoutComponent,
		children: [
			{ path: '', component: FaqComponent },
			{ path: 'category', component: FaqAnswerComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
	declarations: []
})
export class FaqRoutingModule {}
