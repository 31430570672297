import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../service/admin.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HomePageService } from '../service/home-page.service';
declare const FB: any;
@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
    phone: string;
    mainPhone: string;
    main: any = [];
    profile: any = {};
    promotions: any;
    localUrl: any[];
    promotion: any = [];
    promotionNoneSeatel: any = [];
    code: any;
    getDraw: any = [];
    hasWon: any;
    frmDraw: FormGroup;
    getCertType: string;
    getCertNumber: string;
    submitteDraw: boolean;
    drawId: any;
    loginType: string;
    profiles: any = [];
    phoneUnlink: string;
    keywords: any;
    language: any;
    itemType: any;
    getItem: any;
    getItemName: string;
    quote: string;
    isLoggedIn: any;
    status: string;
    promotionkny: any;

    constructor(private router: Router, private adminService: AdminService, private homePageService: HomePageService) {
        this.phone = localStorage.getItem('phone');
        this.mainPhone = localStorage.getItem('main-phone');
    }
    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
        this.status = 'disconnected';
        localStorage.setItem('status', this.status);
        this.postAppUsages();
    }
    ngOnInit() {
        this.isLoggedIn = localStorage.getItem('isLoggedin');

        if (this.isLoggedIn === 'true') {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
        this.loginType = localStorage.getItem('type');
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.language = localStorage.getItem('language');
        this.status = 'connected';
        this.postAppUsages();

        // if (this.router.url === '/user') {
        // 	return this.router.navigate(['/user/dashboard']);

        // }
        // $(document).ready(function () {
        // 	$('#initials').hide();
        // 	setTimeout(function () {
        // 		$('#initials').show();
        // 		$('#addlinkAnimation').hide();
        // 	}, 7900);
        // });

        if (this.loginType === 'off-net') {
            this.getProfile();
        }
        if (this.loginType !== 'off-net') {
            this.getProfiles();
            this.getMain();
        }

        this.frmDraw = new FormGroup({
            name: new FormControl('', Validators.required),
            phoneNumber: new FormControl(this.phone || '', Validators.required)
        });
    }
    postAppUsages() {
        // const data = {
        // 	phoneNumber: this.mainPhone,
        // 	status: this.status
        // };
        // this.homePageService.postAppUsages(data).subscribe(res => {
        // 	// console.log('hi', res);
        // },
        // 	Error => {
        // 		console.log(Error.message);
        // 	}
        // );
    }
    /**
     * Prevent Translate
     * @param keywords
     */
    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }
    get g() {
        return this.frmDraw.controls;
    }
    getMain() {
        this.adminService.getMain(this.phone).subscribe((res) => {
            this.main = res;
            this.promotions = this.main.promotions;
            this.promotion = this.promotions.find((p) => p.code === 'yes-cyn');
            this.promotionkny = this.promotions.find((p) => p.code === 'yes-kny');

            const promotionPinlessTopup = this.promotions.find((p) => p.code === 'yes-5dp');
            localStorage.setItem('topUpPromotion', JSON.stringify(promotionPinlessTopup || {}));
        });
    }
    /**
     * Get phone number and all link phone number
     */
    getProfiles() {
        this.adminService.getProfiles(this.mainPhone).subscribe(
            (res) => {
                this.profiles = res;
                this.getProfile();
            },
            (err) => {
                console.log(err);
            }
        );
    }
    /**
     * Get profile phone number
     */
    getProfile() {
        this.adminService.getProfile(this.phone).subscribe((res) => {
            this.profile = res;
            if (this.loginType === 'off-net') {
                this.promotionNoneSeatel = this.profile.promotions.find((c) => c.code === 'yes-cyn');
            }
            localStorage.setItem('custName', this.profile.custName);
        });
    }
    zhTips(e) {
        if (this.loginType !== 'off-net') {
            this.code = this.promotion.code;
        }
        if (this.loginType === 'off-net') {
            this.code = this.promotionNoneSeatel.code;
        }
        this.phone = localStorage.getItem('main-phone');
        const body = {
            phoneNumber: this.phone,
            loggedIn: this.isLoggedIn
        };
        this.adminService.getPromotionDraw(this.code, body).subscribe((res: any) => {
            this.getDraw = res;
            this.getItem = this.getDraw.item;
            (<any>$('#tipTrue')).modal('show');
            this.hasWon = this.getDraw.hasWon;
            if (this.hasWon === true) {
                $('.showture').removeClass('d-none');
                $('.showfalse').addClass('d-none');
            } else {
                $('.showfalse').removeClass('d-none');
                $('.showture').addClass('d-none');
            }
        });
    }
    getLuckyDraw(e) {
        const getId = this.getDraw.item;
        this.drawId = getId._id;
        this.submitteDraw = true;
        if (this.frmDraw.invalid) {
            return;
        }
        const body = {
            profile: {
                name: this.frmDraw.get('name').value,
                phoneNumber: this.frmDraw.get('phoneNumber').value
            }
        };
        this.adminService.getPromotionItem(this.drawId, body).subscribe((res: any) => {
            this.getDraw = res;
            this.quote = res.message;
            FB.ui(
                {
                    method: 'share',
                    quote: this.quote,
                    hashtag: '#yesseatelCNYpromo',
                    href: 'https://www.yes.com.kh/luckyangpao'
                },
                function (response) {
                    console.log(response);
                }
            );
            localStorage.setItem('itemType', this.getItem.type);
            localStorage.setItem('itemsimCardPrice', this.getItem.simCardPrice);
            localStorage.setItem('itemId', this.getItem._id);
            if (this.getItem.type === 'sim-card') {
                localStorage.setItem('hasfreeSim', 'true');
            }
            localStorage.setItem('itemName', this.getItem.name);
            this.getItemName = localStorage.getItem('itemName');
            this.itemType = localStorage.getItem('itemType');
            if (this.itemType === 'sim-card') {
                this.router.navigate(['/e-hub/buy-sim']);
            }
            (<any>$('#submitInfor')).modal('hide');
        });
    }
    hideModal() {
        (<any>$('#submitInfor')).modal('hide');
    }
}
