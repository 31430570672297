import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqLayoutComponent } from './faq-layout.component';
import { FaqAnswerComponent } from './faq-answer/faq-answer.component';
import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './faq/faq.component';
import { SharedPipesModule } from '../../shared/pipes/shared-pipes.module';

@NgModule({
	imports: [CommonModule, FaqRoutingModule, SharedPipesModule],
	declarations: [FaqLayoutComponent, FaqAnswerComponent, FaqComponent]
})
export class FaqModule {}
