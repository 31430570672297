import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tourist-sim',
    templateUrl: './tourist-sim.component.html',
    styleUrls: ['./tourist-sim.component.scss']
})
export class TouristSimComponent implements OnInit {
    keywords: any = {};
    language: string;
    constructor() {}

    ngOnInit() {
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.keywords.desktopUrl = {
            en: '/assets/product-services/[EN]-Tourist-Sim.jpg',
            km:
                '/assets/product-services/Products _ Services art work-04 (2).png',
            zh: '/assets/product-services/[EN]-Tourist-Sim.jpg'
        };
        this.keywords.mobileUrl = {
            en:
                '/assets/product-services/Product and Service mobile App [1080 x 900px]-03.png',
            km:
                '/assets/product-services/Product and Service mobile App [1080 x 900px]-04.png',
            zh:
                '/assets/product-services/Product and Service mobile App [1080 x 900px]-03.png'
        };
        this.language = localStorage.getItem('language');
    }
    preventTranslate(keyword) {
        return this.keywords[keyword]
            ? this.keywords[keyword][this.language]
            : keyword;
    }
}
