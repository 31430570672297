import { Component, HostListener, Input, OnInit, NgModule } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'angularx-social-login';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { Subscription, from } from '../../../../node_modules/rxjs';
import { of } from '../../../../node_modules/rxjs/observable/of';
import { CartsServiceService } from '../../service/carts-service.service';
import { DataSharingService } from '../../service/data-sharing.service';
import { DevicesService } from '../../service/devices.service';
import { HomePageService } from '../../service/home-page.service';
import { FooterService } from '../../service/footer.service';
import { ToImageUrlPipe } from '../../to-image-url.pipe';

// tslint:disable-next-line:import-blacklist

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [ToImageUrlPipe]
})
export class HeaderComponent implements OnInit {
    // isLoggedIn$: Observable<boolean>;
    isLoggedIn: string;
    brandDevices: any = [];
    brandA: any = [];
    brandB: any = [];
    keywords: any = {};
    language: string;
    limit = 10;
    offset = 0;
    languages = [
        { key: 'English', value: 'en' },
        { key: 'Khmer', value: 'km' },
        { key: 'Chinese', value: 'zh' }
    ];
    inputKeyword = '';
    public dataSearch$ = '';
    private sub: Subscription;
    @Input()
    firstLoad = true;
    headerLogo: string;
    key: string;
    badgeCount = 0;
    phoneNumber: string;
    cartList: any = [];
    type: string;
    menuData: any;
    currentRoute: string;
    urlList: any;
    detailMenu: any;
    constructor(
        private translate: TranslateService,
        private homePageService: HomePageService,
        public router: Router,
        private deviceService: DevicesService,
        private cartService: CartsServiceService,
        private data: DataSharingService,
        private authService: AuthService,
        private footerService: FooterService,
        private toImageUrl: ToImageUrlPipe
    ) {
        this.homePageService.getDetailMenu().subscribe((res) => {
            localStorage.setItem('detailMenu', JSON.stringify(Object(res.data)));
        });

        this.detailMenu = JSON.parse(localStorage.getItem('detailMenu'));

		this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                if (this.detailMenu.length > 0) {

                    const mainUrls = this.detailMenu.map((value) => ({ url: value.url, status: value.status }));
                    const subUrls = this.detailMenu
                        .filter((value) => value.status === 'active')
                        .flatMap((i) => i.submenu.map((value) => ({ url: value.url, status: value.status })));

						// console.log(subUrls);
						
                    const data = [...mainUrls, ...subUrls];
					// console.log('data', data);
					
                    const urlMap: Record<string, UrlData> = {};

                    data.forEach(({ url, status }) => {
                        if (!urlMap[url] || status === 'active') {
                            urlMap[url] = { url, status };
                        }
                    });

                    const result = Object.values(urlMap);
					// console.log('final result ', result);
					

                    const checkUrl = result.find((x) => x.url === event.url);

                    if (checkUrl) {
                        if (checkUrl.status !== 'active') {
                            this.router.navigate(['forbidden']);
                        }
                    }
                }
            }
        });

        const getLanguage = localStorage.getItem('language') || 'en';
        translate.setDefaultLang(getLanguage);
        translate.use(getLanguage);
        localStorage.setItem('language', getLanguage);
        this.language = getLanguage;
        this.isLoggedIn = localStorage.getItem('isLoggedin');
        // this.isLoggedIn = this.authService.signIn();
    }

    private async loadMenuDetailData() {
        try {
            const res = await this.homePageService.getDetailMenu().toPromise();

            if (res.data) {
                localStorage.setItem('detailMenu', JSON.stringify(Object(res.data)));
            }
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }

    private async loadMenuData() {
        try {
            const res = await this.homePageService.getMenu().toPromise();
            if (res.data) {
                localStorage.setItem('menu', JSON.stringify(Object(res.data)));
            }
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }

    changeLangs(language) {
        this.translate.setDefaultLang(language || 'en');
        this.translate.use(language || 'en');
        localStorage.setItem('language', language || 'en');
        location.reload();
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        // console.log(window.scrollY);
        if (window.scrollY >= 75) {
            $('.main-header').addClass('animation');
        } else if (window.scrollY <= 150) {
            $('.main-header').removeClass('animation');
        }
    }

    async ngOnInit() {
        await this.loadMenuData();
        await this.loadMenuDetailData();

        this.menuData = JSON.parse(localStorage.getItem('menu'));
        this.detailMenu = JSON.parse(localStorage.getItem('detailMenu'));

        this.type = localStorage.getItem('type');
        this.data.badgeCount.subscribe((res) => {
            this.badgeCount = res;
        });
        this.isLoggedIn = localStorage.getItem('isLoggedin');
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

        document.addEventListener('updateKeyword', () => {
            this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        });

        this.footerService.getFooterInfo(this.language).subscribe(
            (res) => {
                this.headerLogo = this.toImageUrl.transform(res[0][0].logoName);
            },
            (err) => {
                console.log('error', err);
            }
        );

        $(function () {
            if (window.screen.width < 500) {
                $('.navbar-brand').on('click', function () {
                    $('.collapse').removeClass('show');
                });
                $('.navbar-nav>li>a').on('click', function () {
                    $('.collapse').removeClass('show');
                });
                $('.dropdown-item').on('click', function () {
                    $('.collapse').removeClass('show');
                });

                $('.click-show').click(function () {
                    $(this).toggleClass('drpd-mobile-list');
                    // $('.drpd-mobile-list').toggle();
                });
            }
            if (window.screen.width > 1025) {
                $('.btnClick').on('click', function () {
                    $(this).toggleClass('show-btnClick');
                });
                $('.nav-middle .dropdown').hover(
                    function () {
                        $(this).find('.dropdown-menu').slideDown(300).addClass('show');
                        $('.show-btnClick').removeClass('show');
                    },
                    function () {
                        $(this).find('.dropdown-menu').finish().slideUp(300).removeClass('show');
                    }
                );
                // profile hover
                $('.profile-log').hover(
                    function () {
                        $(this).find('.drpd-sub-profile').slideDown(300).addClass('show');
                        $('.show-btnClick').removeClass('show');
                    },
                    function () {
                        $(this).find('.drpd-sub-profile').finish().slideUp(300).removeClass('show');
                    }
                );

                $('.nav-middle .nav-link').mouseover(function () {
                    $(this).parent().addClass('targeted');
                    let delay = 0;
                    $('.targeted #a li').each(function () {
                        const li = $(this);
                        setTimeout(function () {
                            li.addClass('animated fadeInRight');
                        }, (delay += 100));
                    });
                    delay = 0;
                    $('.targeted #b li').each(function () {
                        const li = $(this);
                        setTimeout(function () {
                            li.addClass('animated fadeInRight');
                        }, (delay += 100));
                    });
                    $('.targeted li').removeClass('animated fadeInRight');
                    $(this).parent().removeClass('targeted');
                });
            }
            let prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                if (window.screen.width < 1025) {
                    const currentScrollPos = window.pageYOffset;

                    if (prevScrollpos < currentScrollPos) {
                        $('#navbarSupportedContent ').removeClass('show');
                        $('.dropdown-menu ').removeClass('show');
                    }
                    prevScrollpos = currentScrollPos;
                }
            };

            setActiveLink();
            $('a.nav-link, a.dropdown-item').click(function (e) {
                let link = $(e.target).attr('routerlink');
                setActiveLink(link);
            });

            function setActiveLink(link = '') {
                if (link == '') {
                    link = location.pathname;
                }

                if (link == 'e-hub/buy-sim-tourists' || link == '/e-hub/buy-sim-tourists') {
                    $('#e-hub-link').addClass('none-active');
                    $('#say-yes-link').addClass('set-active');
                } else if (link == 'e-hub/support-devices' || link == '/e-hub/support-devices') {
                    $('#e-hub-link').addClass('none-active');
                    $('#support-link').addClass('set-active');
                } else {
                    $('#e-hub-link').removeClass('none-active');
                    $('#say-yes-link').removeClass('set-active');
                    $('#support-link').removeClass('set-active');
                }
            }
        });

        this.getSearch(this.inputKeyword);
        // this.getBrands();
    }

    signOut() {
        this.authService.signOut();
        location.reload();
        localStorage.removeItem('phone');
        localStorage.removeItem('main-phone');
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('amount');
        localStorage.removeItem('socialRegister');
        localStorage.removeItem('socialId');
        localStorage.removeItem('socialType');
        localStorage.removeItem('allTotal');
        localStorage.removeItem('cart');
        localStorage.removeItem('orderDevices');
        localStorage.removeItem('orderScratchCards');
        localStorage.removeItem('orderSimCards');
        localStorage.removeItem('custName');
        localStorage.removeItem('popup');
        localStorage.removeItem('type');
        localStorage.removeItem('photoLocal1');
        // localStorage.removeItem('hasPromotion');
        localStorage.removeItem('hasUncompletedOrders');
        localStorage.removeItem('orderIds');
        localStorage.removeItem('top-up-price');
        localStorage.removeItem('add-on-amount');
        localStorage.removeItem('add-on-internet');
        localStorage.removeItem('pageUrl');
        localStorage.removeItem('itemsimCardPrice');
        localStorage.removeItem('useGaugeText');
        localStorage.removeItem('promotionPopUp');
        localStorage.removeItem('showUnlimitedVoiceAndSMS');
        localStorage.removeItem('showBalance');
        localStorage.removeItem('useGaugeText');
        localStorage.removeItem('orderTouristPass');
        localStorage.removeItem('deviceForRent');
        localStorage.removeItem('shopId');
        localStorage.removeItem('orderServiceType');
        localStorage.removeItem('getDetailInfo');
        localStorage.removeItem('hasPhoneNumber');
        localStorage.removeItem('hasCode');
        localStorage.removeItem('hasProfile');
        localStorage.removeItem('simType');
        localStorage.removeItem('cartLists');
        localStorage.removeItem('simCardList');
        localStorage.removeItem('cartDeviceitems');
        localStorage.removeItem('buyScratchCards');
        localStorage.removeItem('buySimCards');
        this.router.navigate(['/']);
    }
    gotoServices() {
        const el = document.getElementById('services');
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    gotoPlan() {
        const el = document.getElementById('plan');
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    gotoCoverage(e) {
        const el = document.getElementById('coverage');
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    getSearch(inputKeyword) {
        inputKeyword = '';
        if (!this.inputKeyword.trim()) {
            // if not search term, return empty array.
            return of([]);
        }
        this.homePageService
            .getSearches(this.inputKeyword, this.language, this.offset, this.limit)
            .subscribe((data) => {
                this.dataSearch$ = data.data;
                // console.log(this.dataSearch$);
            });
    }
    getSearchPage(inputKeyword) {
        this.homePageService
            .getSearches(this.inputKeyword, this.language, this.offset, this.limit)
            .subscribe((data) => {
                this.dataSearch$ = data.data;
                // console.log(this.dataSearch$);
                this.router.navigate(['/search'], {
                    queryParams: { key: this.inputKeyword }
                });
                this.inputKeyword = '';
            });
    }

    // getBrands() {
    // 	this.deviceService.getBrands().subscribe(res => {
    // 		this.brandDevices = res;
    // 		for (let i = 0; i < this.brandDevices.length - 6; i++) {
    // 			this.brandA.push({
    // 				name: this.brandDevices[i].name,
    // 				logoUrl: this.brandDevices[i].logoUrl,
    // 				_id: this.brandDevices[i]._id
    // 			});
    // 		}
    // 		for (let i = 6; i < this.brandDevices.length; i++) {
    // 			this.brandB.push({
    // 				name: this.brandDevices[i].name,
    // 				logoUrl: this.brandDevices[i].logoUrl,
    // 				_id: this.brandDevices[i]._id
    // 			});
    // 		}
    // 	});
    // }

    navigateLink(type, url) {
        if (type === 'devices') {
            this.router.navigate(['/e-hub/devices/device-detail/' + `${url}`]);
            // console.log(url);
        } else if (type === 'shops') {
            this.router.navigate(['/support/find-shop'], {
                queryParams: { localArea: url }
            });
        } else if (type === 'products') {
            this.router.navigate(['/personal']);
        }
    }
    preventTranslate(keyword) {
        if (this.keywords) {
            if (this.keywords[keyword]) {
                return this.keywords[keyword][this.language];
            }
        }
        return keyword;
    }
}

interface UrlData {
    url: string;
    status: string;
}
