import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { ProductsServicesService } from "../../../../service/products-services.service";
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { DashboardComponent } from "../../../../admin/dashboard/dashboard.component";
@Component({
	selector: "app-one-plan",
	templateUrl: "./add-on-package.component.html",
	styleUrls: ["./add-on-package.component.scss"]
})
export class addOnPackageComponent implements OnInit {

	desktopImage: string;
	mobileImage: string;

	termConditionBottom: String = "/assets/product-services/loy-kob/term-condition-icon.png";
	backgroundloykob: string = "/assets/product-services/loy-kob/background-loykob.png";
	loykobcircle: string = "/assets/product-services/loy-kob/addoncircle.png";
	active: string = 'Active';
	reactive: string = 'Reactive';

	@ViewChild(DashboardComponent, { static: true }) child: DashboardComponent;
	language: string;
	keywords = {};

	dataPack: any = [];
	sectionTwo: any = {};
	daily: any = [];
	weekly: any = [];
	monthly: any = [];

	sectionOne: any;
	sectionThree: any;

	dailyPriceFeeOne: number;
	dailyPriceFeeTwo: number;
	weeklyPriceFeeOne: number;
	weeklyPriceFeeTwo: number;
	monthlyPriceFeeOne: number;
	monthlyPriceFeeTwo: number;

	dailyFeeOne: any;
	dailyFeeTwo: any;
	weeklyFeeOne: any;
	weeklyFeeTwo: any;
	monthlyFeeOne: any;
	monthlyFeeTwo: any;

	dailyDataOne: any;
	dailyDataTwo: any;
	weeklyDataOne: any;
	weeklyDataTwo: any;
	monthlyDataOne: any;
	monthlyDataTwo: any;

	dailyActiveOne: any;
	dailyActiveTwo: any;
	weeklyActiveOne: any;
	weeklyActiveTwo: any;
	monthlyActiveOne: any;
	monthlyActiveTwo: any;

	dailyValidityOne: any;
	dailyValidityTwo: any;
	weeklyValidityOne: any;
	weeklyValidityTwo: any;
	monthlyValidityOne: any;
	monthlyValidityTwo: any;
	phone: string;
	plan: string;
	profile: any = {};
	type: string;
	getAmount: string;
	errorMsg: string;
	isLoggedin: string;
	getId: any = {};
	topUpPrice: number;
	newIcon = 'assets/new-en.png';
	fee: string;
	code: string;
	internet: string;
	amount: string;
	loading: string;
	checkSimType: string;
	packageCode: string;
	buyMore: string;

	htmlDescrioption: any;

	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	UserId: string;
	getType: any;
	constructor(
		private productServiceloyKob: ProductsServicesService,
	) { }
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.checkSimType = localStorage.getItem('type');
		this.getUserId();
		this.getType = localStorage.getItem('simType');
		if (this.checkSimType !== 'off-net') {
			this.UserId = localStorage.getItem('UserId');
		}
		switch (this.language) {
			case 'km':
				this.newIcon = 'assets/new-kh.png';
				break;
			case 'zh':
				this.newIcon = 'assets/new-en.png';
				break;
			default:
				this.newIcon = 'assets/new-en.png';
		}

		this.plan = localStorage.getItem('plan');
		this.phone = localStorage.getItem('phone');
		this.isLoggedin = localStorage.getItem('isLoggedin');
		this.topUpPrice = Number(localStorage.getItem('top-up-price'));

		this.getDataPack();
		this.getUserSubscript();
	}

	/**
	 * Check internet price
	 * @param price
	 */
	checkInternetPrice(price) {
		let getPrice: number;
		if (price.includes('$')) {
			getPrice = Number(price.replace('$', ''));
		} else {
			const str = Number(price.replace(/[^0-9.]/g, ''));
			getPrice = str / 100;
		}
		return getPrice;
	}

	getUserId() {
		this.phone = localStorage.getItem('phone');
		this.productServiceloyKob.getUserId(this.phone).subscribe((data) => {
			this.UserId = data._id;
			localStorage.setItem('UserId', this.UserId);
		});
	}

	getUserSubscript() {
		const userId = localStorage.getItem('UserId');
		this.productServiceloyKob.getUserAddonLoykob(userId).subscribe((data) => {
			this.packageCode = data.packageCode;
			//console.log(this.packageCode);

		});
	}

	/**
	 * Show popup buy internet
	 * @param fee
	 * @param internet
	 * @param amount
	 */
	buyInternetPack(fee, internet, code) {
		if (!this.phone || !this.isLoggedin) {
			(<any>$('#preventLogin')).modal('show');
		} else {
			(<any>$('#exampleModalCenter2')).modal('show');
		}
		this.fee = fee;
		this.code = code;
		this.internet = internet;
		this.amount = String(this.checkInternetPrice(fee));
	}
	/**
	 * Submit buy internet
	 */
	onSubmitBuy() {
		this.loading = 'loading';

		this.productServiceloyKob
			.buyAddonLoykob({ userId: this.UserId, packageCode: this.code, amount: +this.amount })
			.subscribe(
				(data) => {
					this.dataPack = data;
					this.loading = '';
					localStorage.setItem('packType', this.type);
					localStorage.setItem('data', this.dataPack);
					swal({
						title: "Successful",
						text: this.preventTranslate('Your subscription was successful.'),
						type: "success",
						confirmButtonColor: "#ec008c",
					});
				},
				(err) => {
					this.loading = '';
					this.errorMsg = err.error.message;
					swal({

						title: "Error",
						text: this.errorMsg,
						type: "error",
						confirmButtonColor: "#ec008c",
					});
				}
			);
	}

	/**
	 * Get data table on internet pack with api
	 */
	getDataPack() {
		this.productServiceloyKob.getPlanPackage(this.language, 'loy-kobs').subscribe((res) => {
			this.dataPack = res;
			this.sectionOne = this.dataPack.section1;
			this.sectionTwo = this.dataPack.section2;
			this.sectionThree = this.dataPack.section3;
			this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
			this.mobileImage = `${this.sectionOne.mobileImageUrl}`;

			console.log('Desktopimage', this.desktopImage);
			console.log('MobileImage', this.mobileImage);
			
			console.log('aaa', this.sectionThree);
			const addOn = _.chain(this.sectionTwo.addOn)
				.groupBy('datapack')
				.map((value, key) => value)
				.value();

			this.weekly = addOn[0];
			this.monthly = addOn[1];
			this.htmlDescrioption = function (str) {
				const parser = new DOMParser();
				const doc = parser.parseFromString(this.sectionThree.description, 'text/html');
				return doc.body;
			};
			$('#htmlDesc').append(this.sectionThree.description);
			$('.seatel-top-up').css('background-image', `url(${this.sectionOne.backgroundLoykobUrl})`);
		});
	}

	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
	}
}
