import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AddOnsComponent } from './add-ons.component';
import { ValuePackComponent } from './value-pack/value-pack.component';
import { InternationalCallComponent } from './international-call/international-call.component';
import { InternetPackageComponent } from './internet-pack/internet-pack.component';
import { SpecialNumberComponent } from './special-number/special-number.component';
import { TengbPromotionComponent } from './tengb-promotion/tengb-promotion.component';

const routes: Routes = [
    {
        path: '',
        component: AddOnsComponent,
        children: [
            { path: '', component: AddOnsComponent },
            // { path: 'valuepack', component: ValuePackComponent },
            { path: 'internationalcall', component: InternationalCallComponent },
            { path: 'data-pack', component: InternetPackageComponent },
            { path: 'special-numbers', component: SpecialNumberComponent },
            { path: '10gb-promotion', component: TengbPromotionComponent}
        ]
    }
];
@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes), CommonModule],
    exports: [RouterModule]
})
export class AddOnsRoutingModule {}
