import {
	Component,
	OnInit,
	OnDestroy,
	Pipe,
	PipeTransform
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NgwWowService } from "ngx-wow";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/filter";
import { HomePageService } from "../../service/home-page.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(url) {
		if (!url) {
			return "";
		}
		if (url) {
			url = url.replace("watch?v=", "embed/");
		}
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
@Component({
	selector: "app-home-page",
	templateUrl: "./home-page.component.html",
	styleUrls: ["./home-page.component.scss"]
})
export class HomePageComponent implements OnInit, OnDestroy {
	private i = 0;
	private wowSubscription: Subscription;
	public keywords;
	language: string;

	banners: any = [];
	bannersImage: any = [];
	bannersVideo: any = [];
	bannerVideo: any = [];
	bannerUrl: string;
	plans: any = [];
	promotions: any;

	appDescription =
		// tslint:disable-next-line:max-line-length
		"The latest, improved yes app is here –lets you manage your yes mobile accounts and discover yes deals in store. You can access your account anytime and anywhere. Everything is only a tap away, whether you’re using the app over yes network. The best part? There are NO internet charges! Give it a try!";

	constructor(
		private router: Router,
		private wowService: NgwWowService,
		private homePageService: HomePageService,
		public sanitizer: DomSanitizer
	) {
		this.router.events
			.filter(event => event instanceof NavigationEnd)
			.subscribe(event => {
				this.wowService.init();
			});
	}

	ngOnInit() {
		this.wowSubscription = this.wowService.itemRevealed$.subscribe(
			(item: HTMLElement) => {
				// do whatever you want with revealed element
			}
		);

		document.addEventListener("updateKeyword", () => {
			this.keywords = JSON.parse(localStorage.getItem("keyword_object"));
		});

		this.keywords = JSON.parse(localStorage.getItem("keyword_object"));
		this.language = localStorage.getItem("language");

		this.getBanners();
		this.getPlans();
		this.getPromotions();
	}
	ngOnDestroy(): void {
		this.wowSubscription.unsubscribe();
	}
	onActivate(event) {
		window.scrollTo();
	}

	getBanners() {
		this.homePageService.getBanners(this.language).subscribe(res => {
			this.banners = res;

			// tslint:disable-next-line:prefer-const
			for (let i in this.banners) {
				if (this.banners[i].type === "images") {
					this.bannersImage.push(this.banners[i]);
				}
			}
			// tslint:disable-next-line:prefer-const
			for (let i in this.banners) {
				if (this.banners[i].type === "video") {
					this.bannersVideo.push(this.banners[i]);
				}
			}
			this.bannerVideo = this.bannersVideo[0];
			if (this.bannersVideo.length > 0) {
				this.bannerUrl =
					this.bannerVideo.url +
					"?rel=0&loop=1&autoplay=1&mute=1&showinfo=0&controls=0";
			}
			console.log(this.banners);
		});
	}

	getPlans() {
		this.homePageService.getPlans(this.language).subscribe(res => {
			// console.log('Plans:', res);
			this.plans = res;
		});
	}

	getPromotions() {
		this.homePageService.getPromotions(this.language).subscribe(res => {
			// console.log('Promotions:', res);
			this.promotions = res;
		});
	}

	openStore(type) {
		const url =
			type === "ios"
				? "https://itunes.apple.com/th/app/seashop/id1124770704?mt=8"
				: "https://play.google.com/store/apps/details?id=com.seatel.mobilehall";
		const win = window.open(url, "_blank");
		win.focus();
	}

	preventTranslate(keywords) {
		return this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
