import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscapeHtmlPipe } from './keep-html.pipe';
import {FormatTimePipe} from './format-time.pipe';
// import { CodeNumbersPipe } from './code-numbers.pipe';
// import { PhoneNumbersPipe } from './phone-numbers.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [EscapeHtmlPipe, FormatTimePipe],
	exports: [EscapeHtmlPipe, FormatTimePipe]
})
export class SharedPipesModule {}
