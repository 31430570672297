import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataPackComponent } from './data-pack/data-pack.component';
import { OnePlanComponent } from './one-plan/one-plan.component';
import { FreeDataComponent } from './free-data/free-data.component';
import { RoamingComponent } from './roaming/roaming.component';
import { ProductsServicesComponent } from './products-services.component';
import { ProductsServicesRoutingModule } from './products-services-routing.module';
import { ProductServiceComponent } from './product-service/product-service.component';
import { InstagramUnlimitedComponent } from './instagram-unlimited/instagram-unlimited.component';
import { HomeBroadbandComponent } from './home-broadband/home-broadband.component';
import { TouristSimComponent } from './tourist-sim/tourist-sim.component';
import { PersonalHomeComponent } from './personal-home/personal-home.component';
import { ContentComponent } from './content/content.component';
import { SelectModule } from 'ng-select';
import { YouTubStarComponent } from './you-tub-star/you-tub-star.component';
import { SuccessComponent } from './success/success.component';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LuckyAngpaoComponent } from './lucky-angpao/lucky-angpao.component';
import { InternetForAllComponent } from './internet-for-all/internet-for-all.component';
import { PreOrderSuccessComponent } from './pre-order-success/pre-order-success.component';

import { ToPricePipe } from '../../shared/pipes/to-price.pipe';
import { CDTVDataSubscriptionComponent } from './cdtv-data-subscription/cdtv-data-subscription.component';
import { LoykobModule } from './loy-kob/loy-kob.module';
import { LoykobsModule } from './loy-kobs/loy-kob.module';
import { RikReayComponent } from './rik-reay/rik-reay.component';
import { tariffComponent } from './one-plan/tariff/tariff.component';

@NgModule({
    imports: [
        CommonModule,
        ProductsServicesRoutingModule,
        SelectModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoykobModule,
        LoykobsModule,
        ShareButtonsModule.forRoot()
    ],
    declarations: [
        DataPackComponent,
        CDTVDataSubscriptionComponent,
        OnePlanComponent,
        RikReayComponent,
        ProductsServicesComponent,
        FreeDataComponent,
        RoamingComponent,
        ProductServiceComponent,
        InstagramUnlimitedComponent,
        HomeBroadbandComponent,
        TouristSimComponent,
        PersonalHomeComponent,
        ContentComponent,
        YouTubStarComponent,
        SuccessComponent,
        LuckyAngpaoComponent,
        InternetForAllComponent,
        PreOrderSuccessComponent,
        ToPricePipe,
        tariffComponent
    ]
})
export class ProductsServicesModule {}
