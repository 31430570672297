// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CartsServiceService } from './carts-service.service';
import { AdminService } from './admin.service';

@Injectable()
export class DataSharingService {
	private phoneNumber;
	constructor(private readonly service: CartsServiceService) {
		this.phoneNumber = localStorage.getItem('phone');
		if (this.phoneNumber) {
			this.service.getCarts(this.phoneNumber).subscribe(res => {
				const metadata = res.metadata;
				const total = metadata.total;
				this.badgeCountSource.next(total);
			});
		}
	}
	private badgeCountSource = new BehaviorSubject<number>(0);
	badgeCount = this.badgeCountSource.asObservable();

	updateBadgeCount() {
		this.service.getCarts(localStorage.getItem('phone')).subscribe(res => {
			const metadata = res.metadata;
			const total = metadata.total;
			this.badgeCountSource.next(total);
		});
	}
}
export class GetNotification {
	private phoneNumber;
	constructor(private readonly adminService: AdminService) {
		this.phoneNumber = localStorage.getItem('phone');
		if (this.phoneNumber) {
			this.adminService
				.getBageNotification(this.phoneNumber)
				.subscribe(data => {
					const total = data.notificationCount;
					this.notifications.next(total);
				});
		}
	}
	private notifications = new BehaviorSubject<number>(0);
	notiCount = this.notifications.asObservable();

	updateBadgeCount() {
		this.adminService.getBageNotification(this.phoneNumber).subscribe(data => {
			this.notifications = data.notificationCount;
			const total = data.notificationCount;
			this.notifications.next(total);
		});
	}
}
