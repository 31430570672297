import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { FindShopComponent } from './find-shop/find-shop.component';
import { SupportComponent } from './support.component';
import { WhereToTopupComponent } from './where-to-topup/where-to-topup.component';
import {ActivateSimComponent} from './activate-sim/activate-sim.component';
import {SelfRegistrationProfileComponent} from './self-registration-profile/self-registration-profile.component';

const routes: Routes = [
    {
        path: '',
        component: SupportComponent,
        children: [
            { path: '', component: LoginComponent },
            { path: 'support', component: LoginComponent },
            { path: 'find-shop', component: FindShopComponent },
            { path: 'where-to-topup', component: WhereToTopupComponent },
						{path: 'activate-number', component: ActivateSimComponent},
						{path: 'registration-profile', component: SelfRegistrationProfileComponent}
        ]
    }
];
@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
    declarations: []
})
export class SupportRoutingModule {}
export const RoutingConponets = [LoginComponent, FindShopComponent];
