import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import 'rxjs/add/observable/timer';

import { ActivateNumberService } from '../../../service/activate-number.service';

@Component({
	selector: 'app-activate-sim',
	templateUrl: './activate-sim.component.html',
	styleUrls: ['./activate-sim.component.scss']
})
export class ActivateSimComponent implements OnInit {
	language: string;
	keywords: any;
	typeRegister = 'activate-number';
	frmRegister: FormGroup;
	sumitted = false;
	sumittedCode = false;
	phonePattern = /^\d+$/;
	errorMsg: any;
	cfmCodeError: string;
	code: string;
	code1: string;
	code2: string;
	code3: string;
	code4: string;
	code5: string;
	phoneNumber: string;
	hasPhoneNumber: string;
	hasCode = false;
	submitted = false;
	errCheckAgree: string;
	buttonDisabled: string;
	checkIdTypeValue: string;
	countDown;
	counter = 0;
	tick = 1000;
	selectLange: string;
	today = Date.now();
	fixedTimezone = this.today;
	constructor(
		private router: Router,
		private registerServiceService: ActivateNumberService
	) { }

	ngOnInit() {
		this.language = localStorage.getItem('language');
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

		const confirmFinish = localStorage.getItem('confirmFinish');
		if (confirmFinish) {
			localStorage.removeItem('hasPhoneNumber');
			localStorage.removeItem('hasCode');
			localStorage.removeItem('confirmFinish');
			this.hasPhoneNumber = '';
			this.hasCode = false;
			this.phoneNumber = '';
		}
		$('#confirmCode, #confirmation').addClass('d-none');

		this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');
		if (this.hasPhoneNumber) {
			this.phoneNumber = this.hasPhoneNumber;

			$('#confirmCode').removeClass('d-none');
			$('#inputPhoneNumber, #confirmation').addClass('d-none');
			$('.step1').removeClass('active');
			$('.step2').addClass('active');
		}

		this.hasCode = localStorage.getItem('hasCode') === 'true';
		if (this.hasPhoneNumber && this.hasCode) {
			$('#confirmation').removeClass('d-none');
			$('#inputPhoneNumber, #confirmCode').addClass('d-none');
			$('.step1, .step2').removeClass('active');
			$('.step3').addClass('active').addClass('d-none');
		}
		this.frmRegister = new FormGroup({
			phoneNumber: new FormControl('', [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(8),
				Validators.maxLength(10)
			]),
			checkAgreement: new FormControl('', [Validators.required])
		});

		$('.inputs').keyup(function () {
			const value = (<HTMLInputElement>event.target).value;
			const maxLength = (<HTMLInputElement>event.target).maxLength;
			if (value.length === maxLength) {
				const $next = $(this).next('.inputs');
				if ($next.length) {
					$(this)
						.next('.inputs')
						.focus();
				} else {
					$(this).blur();
				}
			} else {
				const $prev = $(this).prev('.inputs');
				if ($prev.length) {
					$(this)
						.prev('.inputs')
						.focus();
				} else {
					$(this).blur();
				}
			}
		});
	}
	onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const validCharacters = /^[a-zA-Z\s]*$/;
		if (validCharacters.test(pastedText) === true) {
			return true;
		} else {
			return false;
		}


	}
	/**
	 *  Validation form register
	 */
	get frmValidate() {
		return this.frmRegister.controls;
	}
	/**
	 *  Translate
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
	requestCode(e) {
		// this.sumitted = true;
		if (this.hasPhoneNumber) {
			this.phoneNumber = this.hasPhoneNumber;
		} else {
			this.phoneNumber = this.frmRegister.get('phoneNumber').value;
		}

		if (this.phoneNumber === '' || this.phoneNumber === undefined) {
			return;
		}
		const body = {
			phoneNumber: this.phoneNumber,
			type: this.typeRegister
		};
		this.registerServiceService.requestCode(body).subscribe(
			data => {
				this.countDownMin();
				$('#confirmCode').removeClass('d-none');
				$('#inputPhoneNumber').addClass('d-none');
				$('.step1, .step3').removeClass('active');
				$('.step2').addClass('active');
				this.code = '';
				this.code1 = '';
				this.code2 = '';
				this.code3 = '';
				this.code4 = '';
				this.code5 = '';
				localStorage.setItem('hasPhoneNumber', this.phoneNumber);
				this.cfmCodeError = '';
				this.sumittedCode = false;
			},
			err => {
				this.errorMsg = err.error.message;
			}
		);
	}

	countDownMin() {
		this.countDown;
		this.counter = 120;
		this.tick = 1000;
		this.countDown = Observable.timer(0, this.tick)
			.take(this.counter)
			.map(() => --this.counter);
	}
	/**
	 *  Submit confirm code
	 */
	confirmCode(
		inputCode,
		inputCode1,
		inputCode2,
		inputCode3,
		inputCode4,
		inputCode5
	) {
		this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');

		if (
			inputCode.value === '' &&
			inputCode1.value === '' &&
			inputCode2.value === '' &&
			inputCode3.value === '' &&
			inputCode4.value === '' &&
			inputCode5.value === ''
		) {
			return;
		}

		const body = {
			code:
				inputCode.value +
				inputCode1.value +
				inputCode2.value +
				inputCode3.value +
				inputCode4.value +
				inputCode5.value,
			phoneNumber: this.hasPhoneNumber,
			action: this.typeRegister
		};
		this.registerServiceService.confirmCode(body).subscribe(
			res => {
				localStorage.setItem('hasCode', 'true');
				this.confirmFinish();
			},
			err => {
				this.cfmCodeError = err.error.message;
			}
		);
	}

	confirmFinish() {
		this.hasCode = localStorage.getItem('hasCode') === 'true';

		if (!this.hasCode) { return; }

		this.registerServiceService.activateNumber({phoneNumber: this.phoneNumber}).subscribe(res => {
			localStorage.setItem('confirmFinish', 'true');
			localStorage.removeItem('hasPhoneNumber');
			localStorage.removeItem('hasCode');
			this.hasPhoneNumber = '';
			this.hasCode = false;
			this.errorMsg = '';

			$('#confirmation').removeClass('d-none');
			$('#inputPhoneNumber, #confirmCode').addClass('d-none');
			$('.step1, .step2').removeClass('active');
			$('.step3').addClass('active');

			swal(
				'',
				// tslint:disable-next-line:max-line-length
				this.preventTranslate('Your SIM number has been activated successfully.'),
				'success',
			);
		}, err => {
			this.cfmCodeError = err.error.message;
		});
	}

	doneConfirmation(e) {
		this.phoneNumber = '';
		this.errorMsg = '';
		this.cfmCodeError = '';

		localStorage.removeItem('confirmFinish');
		this.frmRegister.reset();

		$('#inputPhoneNumber').removeClass('d-none');
		$('#confirmCode, #confirmation').addClass('d-none');
		$('.step2, .step3').removeClass('active');
		$('.step1').addClass('active');
	}

	changePhoneNumber() {
		this.frmRegister.reset();
		this.errorMsg = '';
		this.phoneNumber = '';
		this.hasPhoneNumber = '';
		localStorage.removeItem('hasPhoneNumber');

		$('#inputPhoneNumber').removeClass('d-none');
		$('#confirmCode').addClass('d-none');
		$('.step1').addClass('active');
		$('.step2').removeClass('active');
	}

	/**
	 * 855189494385 => 0189494385
	 * @param mSISDN phone number
	 */
	toLocalPhoneNumber(mSISDN: string) {
		if (typeof mSISDN === 'string') {
			mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
			mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
		}
		return mSISDN;
	}
}
