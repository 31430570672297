import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundRoutingModule } from './not-found-routing.module';
import { NotFoundComponent } from './not-found.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { BadGatewayPageComponent } from './bad-gateway-page/bad-gateway-page.component';
@NgModule({
    imports: [CommonModule, NotFoundRoutingModule],
    declarations: [NotFoundComponent, NotFoundPageComponent, BadGatewayPageComponent]
})
export class NotFoundModule {}
