import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { GlobleService } from '../../service/globle.service';
import { PaymentMethodsService } from '../../service/payment-methods.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-top-up-number',
	templateUrl: './top-up-number.component.html',
	styleUrls: ['./top-up-number.component.scss']
})
export class TopUpNumberComponent implements OnInit {
	frmTopUp: FormGroup;
	frmTopUpPin: FormGroup;
	submitted = false;
	submittedPin = false;
	phoneNumber: string;
	defaultPhoneNumber: string;
	amount: string;
	remark: string;
	errMsg: string;
	result: string;
	paymentType: string;
	paymentTypeUrl: string;

	phonePattern = /^\d+$/;
	amountPattern = false;
	oldAmount: string;

	rootUrl: string;
	phone: string;
	profile: any = {};
	language: string;
	paymentMethods: any[];
	public keywords;
	constructor(
		private adminService: AdminService,
		private router: Router,
		private api: GlobleService,
		private paymentMethod: PaymentMethodsService
	) { }

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.amount = localStorage.getItem('amount');
		this.phoneNumber = localStorage.getItem('phoneNumber');
		this.defaultPhoneNumber = localStorage.getItem('phone');
		this.phone = localStorage.getItem('main-phone');
		if (this.amount) {
			this.topUp();
			$('#dollar-' + this.amount).addClass('option-amount-active');
			this.oldAmount = this.amount;
		}

		this.frmTopUp = new FormGroup({
			phoneNumber: new FormControl(this.defaultPhoneNumber, [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			amount: new FormControl(this.amount, [Validators.required, Validators.max(100)])
		});

		this.frmTopUpPin = new FormGroup({
			phoneNumber: new FormControl(this.defaultPhoneNumber, [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			pin: new FormControl('', Validators.required)
		});

		this.paymentMethod.getPaymentMethods().subscribe(res => {
			this.paymentMethods = res;
		});
	}

	setAmount(amount) {
		this.frmTopUp.controls['amount'].setValue(amount);
		this.amountPattern = false;
		if (amount) {
			if (this.oldAmount) {
				$('#dollar-' + this.oldAmount).removeClass('option-amount-active');
			}
			$('#dollar-' + amount).addClass('option-amount-active');
		}
		this.oldAmount = String(amount);
	}

	removeActive() {
		$('#dollar-' + this.oldAmount).removeClass('option-amount-active');
	}

	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}

	/**
	 * Control validate form pin less
	 */
	get f() {
		return this.frmTopUp.controls;
	}

	/**
	 * Control validate form pin code
	 */
	get p() {
		return this.frmTopUpPin.controls;
	}

	/**
	 * Wing seleted option for payment
	 */
	wingSelected() {
		this.removeDisable();
		this.paymentType = 'wing';
		$('#wing').removeClass('d-none');
		$('#card-wing').addClass('card-selected');
		$('#wing-disabled, #aba, #mpay, #alipay').addClass('d-none');
		$('#aba-disabled, #mpay-disabled, #alipay-disabled').removeClass('d-none');
		this.getRemark();
	}

	/**
	 * Selecting ABA for payment
	 */
	abaSelected() {
		this.removeDisable();
		this.paymentType = 'aba';
		$('#aba').removeClass('d-none');
		$('#card-aba').addClass('card-selected');
		$('#aba-disabled, #wing, #mpay, #alipay').addClass('d-none');
		$('#wing-disabled, #mpay-disabled, #alipay-disabled').removeClass('d-none');
		this.getRemark();
	}

	/**
	 * Mpay seleted option for payment
	 */
	mpaySelected() {
		this.removeDisable();
		this.paymentType = 'mpay';
		$('#mpay').removeClass('d-none');
		$('#card-mpay').addClass('card-selected');
		$('#mpay-disabled, #wing, #aba, #alipay').addClass('d-none');
		$('#wing-disabled, #aba-disabled, #alipay-disabled').removeClass('d-none');
		this.getRemark();
	}

	/**
	 * Alipay seleted option for payment
	 */
	alipaySelected() {
		this.removeDisable();
		this.paymentType = 'alipay';
		$('#alipay').removeClass('d-none');
		$('#card-alipay').addClass('card-selected');
		$('#alipay-disabled, #wing, #aba, #mpay').addClass('d-none');
		$('#wing-disabled, #aba-disabled, #mpay-disabled').removeClass('d-none');
		this.getRemark();
	}

	/**
	 * Remove disable button next on payment step
	 */
	removeDisable() {
		$('#btn-mayment-next')
			.removeClass('disabled')
			.removeAttr('disabled');
	}

	/**
	 * Get remark for payment
	 */
	getRemark() {
		const obj = {
			phoneNumber: this.phoneNumber,
			toppedUpBy: localStorage.getItem('phone'),
			amount: this.amount,
			type: 'pinless',
			paymentType: this.paymentType,
			platform: 'web'
		};

		this.adminService.topUp(obj).subscribe(
			res => {
				this.errMsg = '';
				let data: any;
				data = res;
				this.remark = data.remark;
			},
			err => {
				let message: any;
				message = err;
				this.errMsg = message.error.message;
			}
		);
	}

	/**
	 * Open tap pinless
	 */
	pinLess() {
		$('#progress-confirm, #progress-payment, #top-up').removeClass('d-none');
		$('#top-up-pin').addClass('d-none');
		this.errMsg = '';
	}

	/**
	 * Open tap pin code
	 */
	pinCode() {
		$('#progress-confirm, #progress-payment, #top-up').addClass('d-none');
		$('#top-up-pin').removeClass('d-none');
		this.errMsg = '';
	}

	/**
	 * Submit top up by pin code
	 */
	mainTopUpPin() {
		this.submittedPin = true;

		if (this.frmTopUpPin.invalid) {
			return;
		}

		const objVerify = {
			pin: this.frmTopUpPin.get('pin').value
		};

		this.adminService.verifyPin(objVerify).subscribe(
			res => {
				this.errMsg = '';

				this.phoneNumber = this.frmTopUpPin.get('phoneNumber').value;
				const resp: any = res;
				this.amount = resp.amount;

				const obj = {
					phoneNumber: this.frmTopUpPin.get('phoneNumber').value,
					pin: this.frmTopUpPin.get('pin').value,
					type: 'pin'
				};

				this.adminService.topUp(obj).subscribe(
					response => {
						this.errMsg = '';
						$('#top-up-pin, #success').toggleClass('d-none');
						$('#progress-success').toggleClass('active success');
					},
					error => {
						let message: any;
						message = error;
						this.errMsg = message.error.message;
					}
				);
			},
			err => {
				let message: any;
				message = err;
				this.errMsg = message.error.message;
			}
		);
	}

	/**
	 * Validation amount
	 */
	checkAmount() {
		if (this.frmTopUp.get('amount').value < 1 && this.frmTopUp.get('amount').value > 100) {
			this.amountPattern = true;
		} else {
			this.amountPattern = false;
		}
	}

	/**
	 * Submit step one
	 */
	mainTopUp() {
		this.submitted = true;
		if (this.frmTopUp.get('amount').value < 1 || this.frmTopUp.get('amount').value > 100) {
			this.amountPattern = true;
			return;
		}

		if (this.frmTopUp.invalid) {
			return;
		}

		const obj = {
			phoneNumber: String(this.frmTopUp.get('phoneNumber').value)
		};

		this.adminService.verifyPhone(obj).subscribe(
			res => {
				this.errMsg = '';
				this.phoneNumber = String(this.frmTopUp.get('phoneNumber').value);
				this.amount = this.frmTopUp.get('amount').value;
				if (this.amount > '100') {
					return;
				}
				localStorage.setItem(
					'phoneNumber',
					String(this.frmTopUp.get('phoneNumber').value)
				);
				localStorage.setItem('amount', this.frmTopUp.get('amount').value);
				this.topUp();
			},
			err => {
				let message: any;
				message = err;
				this.errMsg = message.error.message;
			}
		);
	}

	/**
	 * Step one
	 */
	topUp() {
		$('#top-up, #confirm').toggleClass('d-none');
		$('#progress-confirm').toggleClass('active success');
	}

	/**
	 * Step two
	 */
	confirm() {
		// this.payment();
		$('#confirm, #payment').toggleClass('d-none');
		$('#progress-payment').toggleClass('active success');
	}

	/**
	 * Step three
	 */
	payment() {
		if (!this.remark) {
			return;
		}
		const popupWidth = 700,
			popupHeight = 800,
			popupLeft = (window.screen.width - popupWidth) / 2,
			popupTop = (window.screen.height - popupHeight) / 2;

		if (this.paymentType === 'mpay') {console.log('if ');
			this.paymentTypeUrl = 'mpay/form';
			this.rootUrl = this.api.apiUrl;
			
		} else if (this.paymentType === 'alipay') {
            console.log('else if ');
			this.paymentTypeUrl = 'alipay/form';
			this.rootUrl = this.api.apiUrl;
		} else {
            console.log('else ');
            
			this.paymentTypeUrl = this.paymentType;
			this.rootUrl = environment.redirectUrl;
            console.log(this.rootUrl, this.paymentTypeUrl);
            
		}
		// console.log(this.rootUrl +
		// 	'/' +
		// 	this.paymentTypeUrl +
		// 	'?amount=' +
		// 	this.amount +
		// 	'&remark=' +
		// 	this.remark,
		// 	'',);
		// return
		
		// will change pop up url to wing sdk + token 
		const popup = window.open(
			this.rootUrl +
			'/' +
			this.paymentTypeUrl +
			'?amount=' +
			this.amount +
			'&remark=' +
			this.remark,
			'',
			'width=' +
			popupWidth +
			',height=' +
			popupHeight +
			',left=' +
			popupLeft +
			',top=' +
			popupTop +
			''
		);
		if (popup.open) {
			$('#btn-mayment-next')
				.addClass('disabled')
				.attr('disabled', 'disabled');
		}
		const interval = setInterval(() => {
			try {
				const url_string = popup.location.href;
				const url = new URL(url_string);
				const c = url.searchParams.get('result');
				if (c) {
					clearInterval(interval);
					this.result = popup.location.search.slice(8);
					if (this.result === 'success') {
						$('#payment, #success').toggleClass('d-none');
						$('#progress-success').toggleClass('active success');
						popup.close();
						localStorage.removeItem('phoneNumber');
						localStorage.removeItem('amount');
					} else {
						popup.close();
					}
				}
			} catch (evt) { }
			if (popup.closed) {
				$('#btn-mayment-next')
					.removeClass('disabled')
					.removeAttr('disabled');
				clearInterval(interval);
			}
		}, 100);
	}
}
