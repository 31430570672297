import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumbers'
})
export class PhoneNumbersPipe implements PipeTransform {

  transform(mSISDN: string) {
     if (typeof mSISDN === 'string') {
      mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
      if(mSISDN.length > 5){
        mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
      }
      return mSISDN.toString().split("").reverse().map((digit, index) =>
      index != 0 && index % 3 === 0 ? ` ${digit}` : digit
    ).reverse().join("");
    }
    return null;
  }

}
