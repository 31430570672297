import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (
			localStorage.getItem('isLoggedin') &&
			localStorage.getItem('main-phone') &&
			localStorage.getItem('phone')
		) {
			return true;
		}
		localStorage.setItem('confirmProfile', state.url);

		this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
		return false;
	}
}
