import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    private fragment: string;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });
    }
    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit(): void {
        try {
            document
                .querySelector('#' + this.fragment)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });
        } catch (e) { }
    }
}
