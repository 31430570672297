import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
	language: string;
	keywords: any = {};
	constructor() { }

	ngOnInit() {
		this.language = localStorage.getItem('language') || 'en';
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.keywords.comingSoon = {
			// tslint:disable-next-line:max-line-length
			en: 'Coming Soon',
			km: 'ឆាប់ៗនេះ',
			zh: '快来了'
		};
	}
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
				? this.keywords[keywords][this.language]
				: keywords;
}

}
