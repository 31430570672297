import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable()
export class MessagingService {
  constructor(private angularFireMessaging: AngularFireMessaging) {
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        localStorage.setItem("firebaseToken", token);
        return token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
}