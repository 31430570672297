import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CartListComponent } from './cart-list/cart-list.component';
import { CartRoutingModule } from './cart-routing.module';
import { AgmCoreModule } from '@agm/core';
import { CartComponent } from './cart.component';
import { OrderSummaryStepComponent } from './order-summary-step/order-summary-step.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessBuyDeviceComponent } from './success-buy-device/success-buy-device.component';
import { BsDatepickerModule } from 'ngx-bootstrap';

@NgModule({
	imports: [
		CommonModule,
		CartRoutingModule,
		AgmCoreModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot()
	],
	declarations: [
		CartListComponent,
		CartComponent,
		OrderSummaryStepComponent,
		SuccessBuyDeviceComponent
	],
	providers: [DatePipe]
})
export class CartModule {}
