import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { HeaderComponent } from '../../../includes/header/header.component';
import { BusinessService } from '../../../service/business.service';

@Component({
	selector: 'app-business-plan',
	templateUrl: './business-plan.component.html',
	styleUrls: ['./business-plan.component.scss']
})
export class BusinessPlanComponent implements OnInit {
	@ViewChild(HeaderComponent, { static: true })
	child;
	language: string;
	public keywords;
	constructor(myElement: ElementRef, public businessService: BusinessService) {}

	business: FormGroup;
	emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.business = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [
				Validators.required,
				Validators.pattern(this.emailPattern)
			]),
			companyName: new FormControl('', Validators.required),
			contactNumber: new FormControl('', Validators.required),
			message: new FormControl('', Validators.required)
		});
	}

	get email() {
		return this.business.get('email');
	}
	get companyName() {
		return this.business.get('companyName');
	}
	get contactNumber() {
		return this.business.get('contactNumber');
	}
	get name() {
		return this.business.get('name');
	}
	get message() {
		return this.business.get('message');
	}
	businessSubmit(): void {
		if (this.business.invalid) {
			return;
		}
		this.businessService.businessSubmit(this.business.value).subscribe(res => {
			$('#bussiness-load').trigger('click');
			this.business.reset();
		});
	}
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
