import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { ProductsServicesComponent } from './products-services.component';
import { DataPackComponent } from './data-pack/data-pack.component';
import { OnePlanComponent } from './one-plan/one-plan.component';
import { ProductServiceComponent } from './product-service/product-service.component';
import { RoamingComponent } from './roaming/roaming.component';
import { HomeBroadbandComponent } from './home-broadband/home-broadband.component';
import { InstagramUnlimitedComponent } from './instagram-unlimited/instagram-unlimited.component';
import { FreeDataComponent } from './free-data/free-data.component';
import { TouristSimComponent } from './tourist-sim/tourist-sim.component';
import { PersonalHomeComponent } from './personal-home/personal-home.component';
import { ContentComponent } from './content/content.component';
import { SuccessComponent } from './success/success.component';
import { LuckyAngpaoComponent } from './lucky-angpao/lucky-angpao.component';
import { PreOrderSuccessComponent } from './pre-order-success/pre-order-success.component';
import { CDTVDataSubscriptionComponent } from './cdtv-data-subscription/cdtv-data-subscription.component';
import { RikReayComponent } from './rik-reay/rik-reay.component';

const routes: Routes = [
	{
		path: '',
		component: ProductsServicesComponent,
		children: [
			{ path: '', component: PersonalHomeComponent },
			{ path: 'add-ons', component: DataPackComponent },
			{ path: 'cdtv-pack', component: CDTVDataSubscriptionComponent },
			{ path: 'plan', component: OnePlanComponent },
			{ path: 'rik-reay', component: RikReayComponent },
			{
				path: 'loy-kob',
				loadChildren: () =>
					import('./loy-kob/loy-kob.module').then((m) => m.LoykobModule),
			},
			{
				path: 'loy-kobs',
				loadChildren: () =>
					import('./loy-kobs/loy-kob.module').then((m) => m.LoykobsModule),
			},
			{ path: 'plan/tourist', component: OnePlanComponent },
			{ path: 'roaming', component: RoamingComponent },
			{ path: 'promotion', component: FreeDataComponent },
			{
				path: 'instagram-unlimited',
				component: InstagramUnlimitedComponent
			},
			{ path: 'home-broadband', component: HomeBroadbandComponent },
			{ path: 'tourism', component: TouristSimComponent },
			{ path: 'content', component: ContentComponent },
			{ path: 'success', component: SuccessComponent },
			{ path: 'luckyangpao', component: LuckyAngpaoComponent },
			{ path: 'luckyangpao/:direct', component: LuckyAngpaoComponent },
			{ path: 'e-hub/pre-order/success', component: PreOrderSuccessComponent }
		]
	}
];
@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class ProductsServicesRoutingModule { }
export const RoutingConponets = [ProductsServicesComponent, DataPackComponent, CDTVDataSubscriptionComponent];
