import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterServiceService } from '../../service/register-service.service';
import * as $ from 'jquery';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	language: string;
	requestError: string;
	confirmErrorCode: string;
	confirmError: string;
	keywords: any = {};
	frmForgotPwd: FormGroup;
	frmConfirmPwd: FormGroup;
	frmConfirmCode: FormGroup;
	phonePattern = /^\d+$/;

	submitted = false;
	submittedCode = false;
	submittedPwd = false;
	misCfmPwd = '';
	forgotStep: string;

	constructor(
		private router: Router,
		private forgotService: RegisterServiceService
	) {}

	ngOnInit() {
		/**
		 * Check Step Forgot Password when Reload Page
		 */
		this.forgotStep = localStorage.getItem('forgot-step');
		if (this.forgotStep === 'sent') {
			this.showHide();
		} else if (this.forgotStep === 'confirm') {
			this.showHide();
			$(
				'#confirm-title, #reset-password-title, #confirm-code, #confirm-password'
			).toggleClass('d-none');
		}

		this.language = localStorage.getItem('language') || 'en';
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

		this.frmForgotPwd = new FormGroup({
			phoneNumber: new FormControl('', [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			])
		});

		this.frmConfirmCode = new FormGroup({
			code: new FormControl('', Validators.required)
		});

		this.frmConfirmPwd = new FormGroup({
			password: new FormControl('', Validators.required),
			confirmPassword: new FormControl('', [
				Validators.required,
				Validators.pattern(this.misCfmPwd)
			])
		});
	}

	/**
	 *  Forgot password validation
	 */
	get forgotPwdValidate() {
		return this.frmForgotPwd.controls;
	}

	/**
	 *  Confirm code validation
	 */
	get frmCodeValidate() {
		return this.frmConfirmCode.controls;
	}

	/**
	 *  Confirm password validation
	 */
	get confirmPwdValidate() {
		return this.frmConfirmPwd.controls;
	}

	/**
	 *  Show and hide form forgot password and confirm code
	 */
	showHide() {
		$(
			'#forgot-title, #confirm-title, #forgot-password, #confirm-code'
		).toggleClass('d-none');
	}

	/**
	 *  Submit forgot password form
	 */
	onSubmit() {
		this.requestError = '';
		this.submitted = true;
		if (this.frmForgotPwd.invalid) {
			return;
		}
		const body = {
			phoneNumber: this.frmForgotPwd.get('phoneNumber').value,
			type: 'reset-password'
		};
		this.forgotService.requestCode(body).subscribe(
			res => {
				this.showHide();
				localStorage.setItem('forgot-step', 'sent');
				localStorage.setItem(
					'forgot-phoneNumber',
					this.frmForgotPwd.get('phoneNumber').value
				);
			},
			err => {
				this.requestError = err.error.message;
			}
		);
	}

	/**
	 *  Send code again
	 */
	sendCode() {
		const body = {
			phoneNumber: localStorage.getItem('forgot-phoneNumber'),
			type: 'reset-password'
		};
		this.forgotService.requestCode(body).subscribe(
			res => {
				this.submittedCode = false;
				this.frmConfirmCode.reset();
			},
			err => {
				this.requestError = err.error.message;
			}
		);
	}

	/**
	 *  Submit confirm code form
	 */
	onConfirmCode() {
		this.confirmErrorCode = '';
		this.submittedCode = true;
		if (this.frmConfirmCode.invalid) {
			return;
		}

		const body = {
			phoneNumber: localStorage.getItem('forgot-phoneNumber'),
			code: this.frmConfirmCode.get('code').value
		};

		this.forgotService.verifyCode(body).subscribe(
			res => {
				$(
					'#confirm-title, #reset-password-title, #confirm-code, #confirm-password'
				).toggleClass('d-none');
				localStorage.setItem('forgot-step', 'confirm');
				localStorage.setItem(
					'forgot-code',
					this.frmConfirmCode.get('code').value
				);
			},
			err => {
				this.confirmErrorCode = err.error.message;
			}
		);
	}

	/**
	 *  Validation on confirm password
	 */
	misCfmPwdPattern() {
		if (
			this.frmConfirmPwd.get('password').value !==
			this.frmConfirmPwd.get('confirmPassword').value
		) {
			this.misCfmPwd = 'true';
		} else {
			this.misCfmPwd = '';
		}
	}

	/**
	 *  Submit confirm password form
	 */
	onConfirmPassword() {
		this.confirmError = '';
		this.submittedPwd = true;
		if (this.frmConfirmPwd.invalid) {
			return;
		}

		const body = {
			code: localStorage.getItem('forgot-code'),
			phoneNumber: localStorage.getItem('forgot-phoneNumber'),
			password: this.frmConfirmPwd.get('password').value,
			action: 'reset-password'
		};

		this.forgotService.confirmCode(body).subscribe(
			res => {
				this.router.navigate(['/login']);
				localStorage.removeItem('forgot-step');
				localStorage.getItem('forgot-phoneNumber');
				localStorage.getItem('forgot-code');
			},
			err => {
				this.confirmError = err.error.message;
			}
		);
	}

	/**
	 *  Translate
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
