import { Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
	AuthServiceConfig,
	FacebookLoginProvider,
	SocialLoginModule,
} from 'angularx-social-login';
import { NgwWowModule } from 'ngx-wow';
import { AdminModule } from './admin/admin.module';
import { AuthGuard } from './admin/guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomSearchPipe } from './custom-search.pipe';
import { FooterComponent } from './includes/footer/footer.component';
import { HeaderComponent } from './includes/header/header.component';
import { NotFoundModule } from './includes/not-found/not-found.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AboutPageService } from './service/about-page.service';
import { AdminService } from './service/admin.service';
import { BusinessService } from './service/business.service';
import { CartsServiceService } from './service/carts-service.service';
import { DataSharingService } from './service/data-sharing.service';
import { DevicesService } from './service/devices.service';
import { FaqService } from './service/faq.service';
import { FindShopService } from './service/find-shop.service';
import { GlobleService } from './service/globle.service';
import { HomePageService } from './service/home-page.service';
import { LoginServiceService } from './service/login-service.service';
import { PaymentMethodsService } from './service/payment-methods.service';
import { ProductsServicesService } from './service/products-services.service';
import { ProductsServicesV2Service } from './service/products-services-v2.service';
import { RegisterServiceService } from './service/register-service.service';
import { UploadServiceService } from './service/upload-service.service';
import { OrderService } from './service/order.service';
import { FooterService } from './service/footer.service';
import { ToImageUrlPipe } from './to-image-url.pipe';
import { RaffleService } from './service/raffle.service';
import { GetLuckyDrawChristmasService } from './service/get-lucky-draw-christmas.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './service/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import {ActivateNumberService} from './service/activate-number.service';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = new AuthServiceConfig([
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider('453409488609356'),
	},
	// {
	// 	id: LinkedInLoginProvider.PROVIDER_ID,
	// 	provider: new LinkedInLoginProvider('86x4x0kz48f2es', false, 'en_US')
	// }
]);

export function provideConfig() {
	return config;
}

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		CustomSearchPipe
	],
	imports: [
		BrowserModule,
		NgwWowModule,
		HttpClientModule,
		AppRoutingModule,
		NotFoundModule,
		LayoutsModule,
		AdminModule,
		FormsModule,
		ReactiveFormsModule,
		SocialLoginModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
	],
	providers: [
		GlobleService,
		HomePageService,
		AboutPageService,
		FindShopService,
		DevicesService,
		ProductsServicesService,
		ProductsServicesV2Service,
		BusinessService,
		AdminService,
		LoginServiceService,
		RegisterServiceService,
		CartsServiceService,
		AuthGuard,
		DataSharingService,
		UploadServiceService,
		PaymentMethodsService,
		FaqService,
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig,
		},
		Location,
		OrderService,
		FooterService,
		RaffleService,
		GetLuckyDrawChristmasService,
		MessagingService,
		AsyncPipe,
		ActivateNumberService
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
