import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { catchError } from "rxjs/operators";

import { GlobleService } from "./globle.service";
import { throwError } from "rxjs";

@Injectable()
export class RaffleService {
	rootUrl: string = this.globalService.apiUrl;
	header: any = this.globalService.getHeader();

	constructor(private http: HttpClient, private globalService: GlobleService) {}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.log("error", error.error);
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}

		// return an ErrorObservable with a user-facing error message
		return throwError("Something bad happened; please try again later.");
	}
	getTickets(date, phoneNumber, limit, offset): Observable<any> {
		const url =
			`/lucky-draw/tickets?limit=` +
			limit +
			`&offset=` +
			offset +
			`&date=` +
			date +
			"&phoneNumber=" +
			phoneNumber;
		return this.http
			.get(`${this.rootUrl}` + url, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getWinnerPrize(language, phoneNumber): Observable<any> {
		const url =
			`/lucky-draw/winner-prize/${language}?phoneNumber=` + phoneNumber;
		return this.http
			.get(`${this.rootUrl}` + url, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getDailyWinnerPrize(language, date): Observable<any> {
		const url = `/lucky-draw/daily-winner-prize/${language}?date=` + date;
		return this.http
			.get(`${this.rootUrl}` + url, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get device detail filter by color and storage in device detail page with api
	 * @param languege
	 * @param id
	 * @param colorId
	 * @param storage
	 */
	getDeviceDetailColor(languege, id, colorId, storage): Observable<any> {
		return this.http
			.get(
				`${this.rootUrl}/devices/${id}?lang=${languege}&colorId=${colorId}&storage=${storage}`,
				{
					headers: this.header,
				}
			)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getSlideBottom(languege): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/lucky-draw/slides/` + languege, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	// get data e-hub
	getEhubMain(languege): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/ehub/ehub-main?lang=${languege}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get slides e-hub
	getSlidesEhub(lang): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/ehub-slide/${lang}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get data scratch card
	getScratchCard(languege): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/ehub/scratch-card?lang=${languege}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	touristsSim(languege): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/sub-pages/tourist-sim?lang=${languege}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	touristsPass(): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/tourist-pass`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	touristsPassSaveOrderDetail(orderId, type): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/orders/${orderId}/receipts/${type}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Request orders verify
	 * @param body
	 */
	orderVerify(body) {
		return this.http.post(`${this.rootUrl}/orders/verify`, body, {
			headers: this.header,
		});
	}
	/**
	 * Get pre order device detail in device detail page with api
	 * @param languege
	 * @param id
	 */
	getPreOrderDeviceDetail(languege, id): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/devices/${id}?lang=${languege}&type=pre-order`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get all pre order device filter by brand in device page with api
	 * @param languege
	 */
	getPreOrderDevices(languege): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/devices?lang=${languege}&isPreOrder=true`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	getSlideLuckDraw(): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/lucky-draw/slides`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	getBanner(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/lucky-draw/banners/${language}`, {
				headers: this.header,
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
}
