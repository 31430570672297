import { Component, OnInit } from '@angular/core';
import { AboutPageService } from '../../../service/about-page.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
    article: any = [];
    latestArticles: any = [];
    id: any;
    language: string;
    limit = 8;
    offset = 20;

    constructor(
        private aboutPageService: AboutPageService,
        private translate: TranslateService,
        private route: ActivatedRoute
    ) {
        const getLanguage = localStorage.getItem('language') || 'en';
        translate.setDefaultLang(getLanguage);
        translate.use(getLanguage);
        localStorage.setItem('language', getLanguage);
        this.language = getLanguage;
    }

    ngOnInit() {
        this.getLatestArticles();
        this.route.params.subscribe(res => {
            this.id = res._id;
            this.getEventDetail();
        });
    }

    getEventDetail() {
        this.aboutPageService
            .getEventDetail(this.id, this.language)
            .subscribe(res => {
                console.log('data', res), (this.article = res);
            });
    }

    getLatestArticles() {
        this.aboutPageService
            .getEvents(this.language, this.limit, this.offset)
            .subscribe(res => {
                this.latestArticles = res.data;
                console.log('Latest Articles', this.latestArticles);
            });
    }
}
