import { Component, OnInit, HostListener } from '@angular/core';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    // tslint:disable-next-line:no-inferrable-types
    public fixed: boolean = false;
    constructor() { }

    ngOnInit() {
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if ( window.scrollY > 450 ) {
            this.fixed = true;
        } else if (this.fixed && window.scrollY < 500) {
            this.fixed = false;
        }
    }

}
