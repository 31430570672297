import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about.component';
import { CorporateComponent } from './corporate/corporate.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { ManagementComponent } from './management/management.component';
import { SocialComponent } from './social/social.component';
import { ValuesComponent } from './values/values.component';
import { CareersComponent } from './careers/careers.component';
import { MediaEventsComponent } from './media-events/media-events.component';
import { ProfileComponent } from './profile/profile.component';
import { EventDetailComponent } from './event-detail/event-detail.component';

const routes: Routes = [
	{
		path: '',
		component: AboutComponent,
		children: [
			{ path: '', component: AboutPageComponent },
			{ path: 'corporate-information', component: CorporateComponent },
			// { path: 'management-team', component: ManagementComponent },
			{ path: 'management-team/profile', component: ProfileComponent },
			{ path: 'social-responsibility', component: SocialComponent },
			{ path: 'values', component: ValuesComponent },
			{ path: 'careers', component: CareersComponent },
			{ path: 'media-events', component: MediaEventsComponent },
			{
				path: 'media-events/event-detail/:_id',
				component: EventDetailComponent
			}
		]
	}
];
@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class AboutRoutingModule { }
export const RoutingConponets = [
	AboutPageComponent,
	CorporateComponent,
	ManagementComponent,
	SocialComponent,
	ValuesComponent,
	CareersComponent,
	MediaEventsComponent,
	ProfileComponent,
	EventDetailComponent
];
