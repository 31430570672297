import {
	Component,
	HostListener,
	OnInit,
	Input,
	Pipe,
	ViewChild,
	ElementRef,
	PipeTransform
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { CustomSearchPipe } from '../../custom-search.pipe';
import { HomePageService } from '../../service/home-page.service';
import { of } from '../../../../node_modules/rxjs/observable/of';
import { HeaderComponent } from '../../includes/header/header.component';
import {
	ActivatedRoute,
	Router
} from '../../../../node_modules/@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BusinessService } from '../../service/business.service';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
	transform(text: string, search): string {
		if (search && text) {
			let pattern = search.replace(
				/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
				'\\$&'
			);
			pattern = pattern
				.split(' ')
				.filter(t => {
					return t.length > 0;
				})
				.join('|');
			const regex = new RegExp(pattern, 'gi');

			return text.replace(
				regex,
				match => `<span class="highlight">${match}</span>`
			);
		} else {
			return text;
		}
	}
}
@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
	// @ViewChild( inputKeyword ) input: ElementRef;
	public keywords;
	business: FormGroup;
	hover: string;
	language: string;
	loadMore = [];
	limit = 10;
	offset = 0;
	languages = [
		{ key: 'en', value: 'en' },
		{ key: 'km', value: 'km' },
		{ key: 'zh', value: 'zh' }
	];
	inputKeyword = '';
	// Keyword: string;
	public dataSearch = [];
	public dataSearchResult: number;
	@Input()
	key: string;
	@Input()
	Keyword: string;
	router: any;

	submitted = false;

	emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
	constructor(
		private translate: TranslateService,
		private homePageService: HomePageService,
		private route: ActivatedRoute,
		private navigate: Router,
		public businessService: BusinessService
	) {
		const getLanguage = localStorage.getItem('language') || 'en';
		translate.setDefaultLang(getLanguage);
		translate.use(getLanguage);
		localStorage.setItem('language', getLanguage);
		this.language = getLanguage;
	}

	ngOnInit() {
		const test = this.route.queryParams.subscribe(params => {
			this.inputKeyword = params['key'];
			this.getSearch(this.inputKeyword);
		});

		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

		this.business = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [
				Validators.required,
				Validators.pattern(this.emailPattern)
			]),
			companyName: new FormControl('', Validators.required),
			contactNumber: new FormControl('', Validators.required),
			message: new FormControl('', Validators.required)
		});
	}

	/**
	 * Control validate form
	 */
	get email() {
		return this.business.get('email');
	}
	get companyName() {
		return this.business.get('companyName');
	}
	get contactNumber() {
		return this.business.get('contactNumber');
	}
	get name() {
		return this.business.get('name');
	}
	get message() {
		return this.business.get('message');
	}

	/**
	 * Submit form bussiness
	 */
	businessSubmit(): void {
		this.submitted = true;

		if (this.business.invalid) {
			return;
		}
		this.businessService.businessSubmit(this.business.value).subscribe(res => {
			this.submitted = false;
			$('#bussiness-search').trigger('click');
			this.business.reset();
		});
	}

	getSearch(inputKeyword) {
		if (!this.inputKeyword.trim()) {
			// if not search term, return empty array.
			return of([]);
		}
		this.homePageService
			.getSearches(this.inputKeyword, this.language, this.offset, this.limit)
			.subscribe(data => {
				this.dataSearch = this.dataSearch.concat(data.data);
				this.dataSearchResult = data.metadata.total;
				this.loadMore = this.dataSearch;
				if (this.loadMore.length === this.dataSearchResult) {
					this.loadMore = [];
				}
			});
	}

	/**
	 * Get data from search input
	 * @param inputKeyword
	 */
	change(inputKeyword) {
		if (inputKeyword === '') {
			this.loadMore = [];
		}
		this.dataSearch = [];
		this.dataSearchResult = null;
		this.limit = 10;
		this.offset = 0;
		this.getSearch(inputKeyword);
	}

	/**
	 * Get more data from search input
	 * @param inputKeyword
	 */
	getMore(inputKeyword) {
		this.offset = this.offset + 10;
		this.getSearch(inputKeyword);
	}

	/**
	 * Create link data from search to page
	 * @param type
	 * @param url
	 * @param name
	 */
	navigateLink(type, url, name) {
		if (type === 'Devices') {
			this.navigate.navigate(['/e-hub/devices/device-detail/' + `${url}`]);
		} else if (type === 'Shops') {
			this.navigate.navigate(['/support/find-shop'], {
				queryParams: { localArea: url }
			});
		} else if (type === 'Personal') {
			if (name === 'free-internet') {
				this.navigate.navigate(['/personal/promotion']);
			} else if (name === 'internet-pack') {
				this.navigate.navigate(['/personal/add-ons']);
			} else if (name === 'one-plan') {
				this.navigate.navigate(['/personal/plan']);
			} else if (name === 'tourist-sim') {
				this.navigate.navigate(['/personal/tourism']);
			} else if (name === 'international-call') {
				this.navigate.navigate(['/add-ons/internationalcall']);
			} else {
				this.navigate.navigate(['/personal']);
			}
		} else if (type === 'Management Team') {
			this.navigate.navigate(['/about/management-team']);
		} else if (type === 'Corporate Information') {
			this.navigate.navigate(['/about/corporate-information']);
		}
	}

	/**
	 * Prevent translate
	 * @param keyword
	 */
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
