import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { GlobleService } from './globle.service';

@Injectable()
export class AboutPageService {
	rootUrl: string = this.globalService.apiUrl;
	header: any = this.globalService.getHeader();

	constructor(private http: HttpClient, private globalService: GlobleService) {}

	/**
	 * Get events in media page
	 * @param _languege
	 * @param _limit
	 * @param _offset
	 */
	getEvents(_languege, _limit, _offset): Observable<any> {
		return this.http
			.get(
				`${
					this.rootUrl
				}/articles?lang=${_languege}&limit=${_limit}&offset=${_offset}`,
				{ headers: this.header }
			)
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get Event detail
	 * @param _id
	 * @param _languege
	 */
	getEventDetail(_id, _languege) {
		return this.http
			.get(`${this.rootUrl}/articles/${_id}?lang=${_languege}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get Careers
	 * @param language
	 * @param url
	 */
	getCareers(language, url): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/careers?lang=${language}` + url, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get Careers section one
	getCareersBanner(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/about/careers?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get management team
	getManagementTeam(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/management-team?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get management team detail
	getManagementTeamDetail(language, id): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/management-team/${id}?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get management banner
	getManagementBanner(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/about/management-team?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get corporate information
	getCorporate(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/corporate-information?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}
	// get corporate information banner
	getCorporateBanner(language): Observable<any> {
		return this.http
			.get(
				`${this.rootUrl}/pages/about/corporate-information?lang=${language}`,
				{
					headers: this.header
				}
			)
			.pipe(catchError(this.handleError.bind(this)));
	}
	/**
	 * Get detail careers
	 * @param id
	 * @param language
	 */
	getOneCareer(id, language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/careers/${id}?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	// get main page about
	getMainAbout(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/about/about-main?lang=${language}`, {
				headers: this.header
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// return an ErrorObservable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}
}
