import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  onActivate(event) {
    window.scrollTo();
  }
}
