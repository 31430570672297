import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomePageService } from './service/home-page.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'angularx-social-login';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	keywords = { 'Log In': '', Support: '' };
	hover: string;
	language: string;
	geturl: string;

	constructor(
		private translate: TranslateService,
		private homePageService: HomePageService,
		private router: Router,
		private authService: AuthService
	) {
		const getLanguage = localStorage.getItem('language') || 'en';
		translate.setDefaultLang(getLanguage);
		translate.use(getLanguage);
		localStorage.setItem('language', getLanguage);
		this.language = getLanguage;
		this.getKeywords();

		router.events
			.filter(event => event instanceof NavigationEnd)
			.subscribe((event: NavigationEnd) => {
				window.scroll(0, 0);
			});

		const now = Date.now();
		const expiration = localStorage.getItem('isLoggedinExpire');
		const isLoggedin = localStorage.getItem('isLoggedin');
		if (isLoggedin) {
			if (Number(expiration) < now) {
				this.authService.signOut();
				localStorage.removeItem('phone');
				localStorage.removeItem('main-phone');
				localStorage.removeItem('isLoggedin');
				localStorage.removeItem('phoneNumber');
				localStorage.removeItem('amount');
				localStorage.removeItem('socialRegister');
				localStorage.removeItem('socialId');
				localStorage.removeItem('socialType');
				localStorage.removeItem('allTotal');
				localStorage.removeItem('cart');
				localStorage.removeItem('orderDevices');
				localStorage.removeItem('orderScratchCards');
				localStorage.removeItem('orderSimCards');
				localStorage.removeItem('custName');
				localStorage.removeItem('type');
				localStorage.removeItem('popup');
				localStorage.removeItem('photoLocal1');
				// localStorage.removeItem('hasPromotion');
				localStorage.removeItem('hasUncompletedOrders');
				localStorage.removeItem('orderIds');
				localStorage.removeItem('top-up-price');
				localStorage.removeItem('add-on-amount');
				localStorage.removeItem('add-on-internet');
				localStorage.removeItem('pageUrl');
				localStorage.removeItem('itemsimCardPrice');
				localStorage.removeItem('clickAngpao');
				localStorage.removeItem('useGaugeText');
				localStorage.removeItem('promotionPopUp');
				localStorage.removeItem('showUnlimitedVoiceAndSMS');
				localStorage.removeItem('showBalance');
				localStorage.removeItem('useGaugeText');
				localStorage.removeItem('orderTouristPass');
				localStorage.removeItem('deviceForRent');
				localStorage.removeItem('shopId');
				localStorage.removeItem('orderServiceType');
				localStorage.removeItem('getDetailInfo');
				localStorage.removeItem('hasPhoneNumber');
				localStorage.removeItem('hasCode');
				localStorage.removeItem('hasProfile');
				localStorage.removeItem('cartLists');
				localStorage.removeItem('simCardList');
				localStorage.removeItem('cartDeviceitems');
				localStorage.removeItem('buyScratchCards');
				localStorage.removeItem('buySimCards');
				this.router.navigate(['/login']);
			}
		}
		this.geturl = window.location.pathname;
	}
	getKeywords(): void {
		this.homePageService.getKeywords().subscribe(data => {
			localStorage.removeItem('keyword_object');
			localStorage.setItem('keyword_object', JSON.stringify(data));
			document.dispatchEvent(new Event('updateKeyword', { bubbles: true }));
			this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		});
	}
}
