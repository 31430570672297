import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-bad-gateway-page',
    templateUrl: './bad-gateway-page.component.html',
    styleUrls: ['./bad-gateway-page.component.scss']
})
export class BadGatewayPageComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

    refreshPage() {
        location.reload();
        this.router.navigate(['']);
    }
}
