import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codeNumbers'
})
export class CodeNumbersPipe implements PipeTransform {

  transform(mSISDN: string) {
    if (typeof mSISDN === 'string') {
     return mSISDN.toString().split("").reverse().map((digit, index) =>
     index != 0 && index % 3 === 0 ? ` ${digit}` : digit
   ).reverse().join("");
   }
   return null;
 }

}
