import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { ProductsServicesService } from "../../../../service/products-services.service";
import * as _ from 'lodash';
import { DashboardComponent } from "../../../../admin/dashboard/dashboard.component";
@Component({
	selector: "tariff",
	templateUrl: "./tariff.component.html",
	styleUrls: ["./tariff.component.scss"]
})
export class tariffComponent implements OnInit {

	desktopImage: string;
	mobileImage: string;

	backgroundloykob: string = '/assets/product-services/loy-kob/standard-tariff/background-tariff.jpg';
	circle: string = '/assets/product-services/loy-kob/standard-tariff/circle-tariff.png';

	@ViewChild(DashboardComponent, { static: true }) child: DashboardComponent;
	language: string;
	keywords = {};
	dataPack: any = [];
	sectionTwo: any = {};
	// sectionOne: any;
	// sectionThree: any;
	tableTariff: any;
	infoTable: any = [];
	tariff: any = [];


	newIcon = 'assets/new-en.png';
	loading: string;


	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;

	constructor(
		private productServiceloyKob: ProductsServicesService,
	) { }
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		switch (this.language) {
			case 'km':
				this.newIcon = 'assets/new-kh.png';
				break;
			case 'zh':
				this.newIcon = 'assets/new-en.png';
				break;
			default:
				this.newIcon = 'assets/new-en.png';
		}
		this.getDataPack();
	}
	/**
		* Get data table on internet pack with api
		*/
	getDataPack() {
		this.productServiceloyKob.getPlanPackage(this.language, 'one-plan').subscribe((res) => {
			console.log(res);
			
			this.dataPack = res;
			
			$('.seatel-tariff').css('background-image', `url(${this.dataPack.section2.tariff.image.backgroundImageUrl})`);
			this.desktopImage = this.dataPack.section2.tariff.image.desktopImageUrl;
			this.mobileImage = this.dataPack.section2.tariff.image.mobileImageUrl;
			this.sectionTwo = this.dataPack.section2;
			this.tariff = this.dataPack.section2.tariff;
			
			this.tableTariff = _.values(this.sectionTwo.tariff.info);
			this.infoTable = this.tableTariff;

		});
	}
	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
	}
}
