import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LayoutsComponent } from './layouts.component';
import { LoginComponent } from './login/login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RegisterComponent } from './register/register.component';
import { SearchComponent } from './search/search.component';
import { TopUpComponent } from './top-up/top-up.component';
import { YouTubStarComponent } from './products-services/you-tub-star/you-tub-star.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TopUpNumberComponent } from './top-up-number/top-up-number.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { InternetForAllComponent } from './products-services/internet-for-all/internet-for-all.component';
import { SelfRegistrationProfileComponent } from './self-registration-profile/self-registration-profile.component';
import { EventsComponent } from './events/events.component';

const routes: Routes = [
	{
		path: '',
		component: LayoutsComponent,
		children: [
			// { path: '', component: HomePageComponent },
			// { path: 'home', component: HomePageComponent },
			{
				path: '',
				loadChildren: () =>
					import('./products-services/products-services.module').then(
						(m) => m.ProductsServicesModule
					),
			},
			{
				path: 'personal',
				loadChildren: () =>
					import('./products-services/products-services.module').then(
						(m) => m.ProductsServicesModule
					),
			},
			{
				path: 'e-hub',
				loadChildren: () =>
					import('./devices/device.module').then((m) => m.DeviceModule),
			},
			{
				path: 'business',
				loadChildren: () =>
					import('./business/business.module').then((m) => m.BusinessModule),
			},
			{
				path: 'support',
				loadChildren: () =>
					import('./support/support.module').then((m) => m.SupportModule),
			},
			{
				path: 'about',
				loadChildren: () =>
					import('./about/about.module').then((m) => m.AboutModule),
			},
			{
				path: 'cart',
				loadChildren: () =>
					import('./cart/cart.module').then((m) => m.CartModule),
			},
			{
				path: 'add-ons',
				loadChildren: () =>
					import('./add-ons/add-ons.module').then((m) => m.AddOnsModule),
			},
			{
				path: 'raffle',
				loadChildren: () =>
					import('./raffle/raffle.module').then((m) => m.RaffleModule),
			},
			{
				path: 'internet',
				loadChildren: () =>
					import('./internet/internet.module').then((m) => m.InternetModule),
			},

			{ path: 'search', component: SearchComponent },
			{ path: 'login', component: LoginComponent },
			{ path: 'register', component: RegisterComponent },
			{ path: 'forgot-password', component: ForgotPasswordComponent },
			{ path: 'privacy-policy', component: PrivacyPolicyComponent },
			{ path: 'top-up', component: TopUpComponent },
			{
				path: 'faq',
				loadChildren: () =>
					import('./faq-layout/faq.module').then((m) => m.FaqModule),
			},
			{ path: 'internetforall', component: InternetForAllComponent },
			{ path: 'top-up-number', component: TopUpNumberComponent },
			{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
			{
				path: 'self-registration-profile',
				component: SelfRegistrationProfileComponent,
			},
			{ path: 'lucky-draw' , component: EventsComponent }
		],
	},
];
@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
	declarations: [],
})
export class LayoutsRoutingModule {}

export const RoutingConponets = [
	HomePageComponent,
	ContactComponent,
	AboutComponent,
];
