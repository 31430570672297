import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { HomePageService } from '../../service/home-page.service';
import { DevicesService } from '../../service/devices.service';
// tslint:disable-next-line:import-blacklist
import { Observable, BehaviorSubject } from 'rxjs';
import { FooterService } from '../../service/footer.service';
import { ToImageUrlPipe } from '../../to-image-url.pipe';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	providers: [ToImageUrlPipe]
})
export class FooterComponent implements OnInit, OnDestroy {
	keywords = {};
	brandDevices: any = [];
	brandAll: any = [];
	language: string;
	required: string;
	incorrect: string;
	msgError: string;
	footer: any = '';
	socialLinks: any = '';
	socialIcons: any = '';

	languages = [
		{ key: 'en', value: 'en' },
		{ key: 'km', value: 'km' },
		{ key: 'zh', value: 'zh' }
	];

	email = '';

	firstLoad: Boolean = true;
	private wowSubscription: Subscription;
	constructor(
		public router: Router,
		private wowService: NgwWowService,
		private homePageService: HomePageService,
		// private deviceService: DevicesService,
		private footerService: FooterService,
		private toImageUrl: ToImageUrlPipe
	) {
		this.router.events
			.filter(event => event instanceof NavigationEnd)
			.subscribe(event => {
				this.wowService.init();
			});
	}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.wowSubscription = this.wowService.itemRevealed$.subscribe(
			(item: HTMLElement) => {}
		);
		this.getFooterInfo();
	}

	getFooterInfo() {
		// this.footer = {
		// 	companyName: "South East Asia Telecom Group Ltd",
		// 	companyAddress: "#149, Street. 432, Phnom Penh"
		// };

		this.footerService.getFooterInfo(this.language).subscribe(
			res => {
				const settings = res[0][0];
				this.socialLinks = settings['socialLink'];
				this.socialIcons = settings['socialLink']['socialIcon'];
				if (this.socialIcons === undefined) {
					this.socialIcons = {
						fb: 'default-icon-fb.png',
						linked_in: 'default-icon-linkedin.png',
						instagram: 'default-icon-instagram.png',
						we_chat: 'default-icon-we_chat.png',
						youtube: 'default-icon-youtube.png'
					};
				}
				this.socialIcons.fb = this.toImageUrl.transform(this.socialIcons.fb);
				this.socialIcons.linked_in = this.toImageUrl.transform(
					this.socialIcons.linked_in
				);
				this.socialIcons.instagram = this.toImageUrl.transform(
					this.socialIcons.instagram
				);
				this.socialIcons.we_chat = this.toImageUrl.transform(
					this.socialIcons.we_chat
				);
				this.socialIcons.youtube = this.toImageUrl.transform(
					this.socialIcons.youtube
				);
				this.footer = settings[this.language];
			},
			err => {
				console.log('error', err);
			}
		);
	}

	gotoLocation(e) {
		const el = document.getElementById('location');
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}

	gotoFeedback(e) {
		const el = document.getElementById('feedback');
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}
	goTop(event) {
		window.scroll({ top: 0, behavior: 'smooth' });
	}
	// getKeywords(): void {
	// 	this.firstLoad = false;
	// 	this.homePageService.getKeywords().subscribe(data => {
	// 		// this.keywords = data;
	// 		localStorage.removeItem('keyword_object');
	// 		localStorage.setItem('keyword_object', JSON.stringify(data));
	// 		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
	// 		// console.log(this.keywords);
	// 	});
	// }

	ngOnDestroy(): void {
		this.wowSubscription.unsubscribe();
	}

	// getBrands() {
	// 	this.deviceService.getBrands().subscribe(res => {
	// 		this.brandAll = res;
	// 	});
	// }

	subscribeEmail() {
		if (this.email === '') {
			this.incorrect = '';
			this.required = 'required';
			return;
		} else if (this.validateEmail(this.email) === false) {
			this.incorrect = 'incorrect';
			return;
		}

		this.homePageService
			.subscibeEmail({
				email: this.email
			})
			.subscribe(
				res => {
					(<any>$('#subscriptionPopUp')).modal('show');
					this.msgError = '';
				},
				err => {
					this.msgError = 'This email is already existed.';
					// console.log('errrrr', err);
				}
			);
	}

	checkRequired(email) {
		if (email === '') {
			this.incorrect = '';
			this.required = 'required';
		} else {
			this.required = '';
			this.incorrect = '';
		}
	}

	validateEmail(email) {
		// tslint:disable-next-line:max-line-length
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	preventTranslate(keyword) {
		return this.keywords && this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
