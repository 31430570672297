import { Component, OnInit } from '@angular/core';
import { HomePageService } from '../../service/home-page.service';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
	privacyPolicy: any = {};
	keywords: any;
	language: string;
	textPrivacyPolicy: any;

	constructor(private homePageService: HomePageService) { }

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.getPrivacyPolicy();
	}
	getPrivacyPolicy() {
		this.homePageService.getPrivacyPolicy(this.language).subscribe(data => {
			this.privacyPolicy = data;
			this.textPrivacyPolicy = this.privacyPolicy.section1.title;
		});
	}
	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
