import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedPipesModule} from '../../shared/pipes/shared-pipes.module';

import { LoginComponent, SearchPipe } from './login/login.component';
import { FindShopComponent } from './find-shop/find-shop.component';
import { SupportRoutingModule } from './support-routing.module';
import { AgmCoreModule } from '@agm/core';
import { SupportComponent } from './support.component';
import { SelectModule } from 'ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WhereToTopupComponent } from './where-to-topup/where-to-topup.component';
import {ActivateSimComponent} from './activate-sim/activate-sim.component';
import {SelfRegistrationProfileComponent} from './self-registration-profile/self-registration-profile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectModule,
        ReactiveFormsModule,
        SupportRoutingModule,
				SharedPipesModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBJqTfceDlpziDBdV5fncQDHWnBqSC9pbQ'
        })
    ],
    declarations: [
        SupportComponent,
        LoginComponent,
        FindShopComponent,
        SearchPipe,
        WhereToTopupComponent,
				ActivateSimComponent,
				SelfRegistrationProfileComponent
    ]
})
export class SupportModule {}
