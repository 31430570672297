import { Injectable } from '@angular/core';
import { GlobleService } from './globle.service';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PaymentMethodsService {
    url = this.api.apiUrl;
    header = this.api.getHeader();

    constructor(private api: GlobleService, private http: HttpClient) {}

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log('error', error.error);
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    getPaymentMethods(): Observable<any> {
        return this.http
            .get(`${this.url}/v2/payment-methods`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError.bind(this)));
    }

    requestTransaction(): Observable<any> {
        return this.http
            .get(`${this.url}/v2/payment-methods`, { headers: this.header })
            .pipe(catchError(this.handleError.bind(this)));
    }
}
