import { Component, OnInit } from '@angular/core';
import { CartsServiceService } from '../../../service/carts-service.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DataSharingService } from '../../../service/data-sharing.service';
import { PaymentMethodsService } from '../../../service/payment-methods.service';

@Component({
    selector: 'app-cart-list',
    templateUrl: './cart-list.component.html',
    styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit {
    phoneNumber: string;
    quantity: number;
    totalPrice = 0;
    resCart: any = {};
    carts: any = [];
    simCards: any = [];
    devices: any = [];
    scratchCards: any = [];
    orderSimCards: any = [];
    orderDevices: any = [];
    orderScratchCards: any = [];
    orderItem: any = [];
    removeId: string;
    oldId: string;
    language: string;
    emptyCart = false;
    loading: string;
    paymentMethods: any[];
    public keywords;
    loginType: string;
    hasPromotion: any;
    errQty = false;

    constructor(
        private cartService: CartsServiceService,
        private router: Router,
        private data: DataSharingService,
        private paymentMethod: PaymentMethodsService
    ) {}

    ngOnInit() {
        this.language = localStorage.getItem('language') || 'en';
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        localStorage.removeItem('simNumber');
        localStorage.removeItem('simPrice');
        localStorage.removeItem('discountRate');
        localStorage.removeItem('discountedPrice');
        localStorage.removeItem('simcardPrice');
        localStorage.removeItem('cartLists');
        localStorage.removeItem('simCardList');
        localStorage.removeItem('cartDeviceitems');
        this.phoneNumber = localStorage.getItem('phone');
        this.getCarts();
        this.paymentMethod.getPaymentMethods().subscribe((res) => {
            this.paymentMethods = res;
        });

        if (!this.phoneNumber) {
            this.router.navigate(['/']);
        }
    }

    /**
     * Prevent translate
     * @param keyword
     */
    preventTranslate(keyword) {
        if (this.keywords) {
            if (this.keywords[keyword]) {
                return this.keywords[keyword][this.language];
            }
        }
        return keyword;
    }

    /**
     * Get all cart list
     */
    getCarts() {
        if (!this.phoneNumber) {
            return;
        }
        this.loading = 'loading';
        this.simCards = [];
        this.orderItem = [];
        this.devices = [];
        this.totalPrice = 0;
        this.scratchCards = [];
        this.cartService.getCarts(this.phoneNumber).subscribe(
            (res) => {
                this.resCart = res;
                this.carts = res.data;
                this.updateCardPrices();
                for (const i of this.carts) {
                    this.orderItem.push(i._id);
                    this.totalPrice += i.totalPrice;
                }
                this.loading = '';
                if (this.carts.length === 0) {
                    this.emptyCart = true;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Show update quantity of cart item
     * @param id
     * @param Qty
     */
    showUpdate(id, Qty) {
        this.quantity = Qty;
        if (!this.oldId) {
            this.oldId = id;
        }
        $('#' + this.oldId + 'quantity').addClass('d-none');
        $('#' + this.oldId + 'input').removeAttr('disabled');
        this.oldId = id;

        $('#' + id + 'input').attr('disabled', 'disabled');
        $('#' + id + 'update').css('border-color', '#ccc');
        $('#' + id + 'quantity').removeClass('d-none');
        $('#' + id + 'ok').removeClass('d-none');
        $('#' + id + 'cancel').removeClass('d-none');
    }

    /**
     * Get quantity for update
     * @param Qty
     */
    getQuantity(Qty, id, quota) {
        if (Qty === 'sub' && this.quantity > 1) {
            this.quantity = this.quantity - 1;
            this.errQty = false;
        } else if (Qty === 'add') {
            if (this.quantity >= quota && quota != null) {
                this.errQty = true;
                return;
            }
            $('#' + id + 'update').css('border-color', '#ccc');
            this.quantity = this.quantity + 1;
        } else {
            this.quantity = this.quantity;
        }
    }

    /**
     * Prevent quantity
     * @param quantity
     * @param id
     */
    checkQuantity(quantity, id, quota) {
        this.errQty = false;
        if (this.quantity > quota && quota != null) {
            this.quantity = quota;
            this.errQty = true;
            return;
        }
        if (quantity < 1) {
            $('#' + id + 'update').css('border-color', '#dd2c00');
            return;
        } else {
            $('#' + id + 'update').css('border-color', '#ccc');
        }
    }

    /**
     * Prevent input quantity
     * @param evt
     */
    isNumber(evt) {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    /**
     * Hide and cancel update quantity
     * @param id
     */
    cancelUpdate(id) {
        $('#' + id + 'input').removeAttr('disabled');
        $('#' + id + 'quantity').addClass('d-none');
    }

    /**
     * Submit for update quantity
     * @param id
     * @param quantity
     * @param scratchCardAmount
     * @param deviceUrl
     * @param color
     * @param storage
     * @param type
     * @param colorId
     */
    updateQuantity(id, quantity, scratchCardAmount, deviceUrl, color, storage, type, colorId) {
        if (quantity < 1) {
            $('#' + id + 'update').css('border-color', '#dd2c00');
            return;
        } else {
            $('#' + id + 'update').css('border-color', '#ccc');
        }

        let subCart: any;
        if (type === 'device') {
            subCart = {
                phoneNumber: this.phoneNumber,
                item: {
                    type: type,
                    quantity: quantity,
                    deviceUrl: deviceUrl,
                    color: color,
                    colorId: colorId,
                    storage: storage
                }
            };
        } else if (type === 'scratch-card') {
            subCart = {
                phoneNumber: this.phoneNumber,
                item: {
                    type: type,
                    quantity: quantity,
                    scratchCardAmount: scratchCardAmount
                }
            };
        }

        this.cartService.updateCart(id, subCart).subscribe(
            (res) => {
                this.totalPrice = 0;
                this.getCarts();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Count total price when select item
     * @param id
     * @param price
     */
    getTotalPrice(id, price) {
        if ($('#' + id).prop('checked') === true) {
            this.totalPrice = this.totalPrice + price;
            this.orderItem.push(id);
        } else if ($('#' + id).prop('checked') === false) {
            this.totalPrice = this.totalPrice - price;
            this.orderItem.splice(this.orderItem.indexOf(id), 1);
        }
    }

    /**
     * Call modal remove item
     * @param id
     */
    removeItem(id) {
        this.removeId = id;
        $('#call-comfirm-remove').trigger('click');
    }

    /**
     * Submit remove item
     */
    confirmRemoveItem() {
        const obj = {
            phoneNumber: this.phoneNumber
        };

        this.cartService.deletetCart(this.removeId, this.phoneNumber).subscribe(
            (res) => {
                this.data.updateBadgeCount();
                this.totalPrice = 0;
                this.getCarts();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Buy a sim cart
     * @param id
     */
    buySimCard(id) {
        for (const i of this.carts) {
            if (i._id === id) {
                if (i.planSubscription.addOns.length > 0) {
                    this.orderSimCards.push({
                        _id: i._id,
                        type: i.type,
                        simIcon: this.resCart.simIcon,
                        topUpIcon: this.resCart.topUpIcon,
                        simPriceIcon: this.resCart.simPriceIcon,
                        cardPrice: i.cardPrice,
                        quantity: i.quantity,
                        topUpAmount: i.topUpAmount,
                        totalPrice: i.totalPrice,
                        unitPrice: i.unitPrice,
                        simCardNumber: i.simCardNumber,
                        planSubscription: {
                            addOns: [
                                {
                                    addOnIcon: i.planSubscription.addOns[0].addOnIcon,
                                    addOnName: i.planSubscription.addOns[0].addOnName,
                                    amount: i.planSubscription.addOns[0].amount,
                                    duration: i.planSubscription.addOns[0].duration,
                                    _id: i.planSubscription.addOns[0]._id
                                }
                            ],
                            planIcon: i.planSubscription.planIcon,
                            planName: i.planSubscription.planName,
                            _id: i.planSubscription._id
                        }
                    });
                } else {
                    this.orderSimCards.push({
                        _id: i._id,
                        type: i.type,
                        simIcon: this.resCart.simIcon,
                        topUpIcon: this.resCart.topUpIcon,
                        simPriceIcon: this.resCart.simPriceIcon,
                        cardPrice: i.cardPrice,
                        quantity: i.quantity,
                        topUpAmount: i.topUpAmount,
                        totalPrice: i.totalPrice,
                        unitPrice: i.unitPrice,
                        simCardNumber: i.simCardNumber,
                        planSubscription: {
                            addOns: [],
                            planIcon: i.planSubscription.planIcon,
                            planName: i.planSubscription.planName,
                            _id: i.planSubscription._id
                        }
                    });
                }
                localStorage.setItem('allTotal', String(i.totalPrice));
            }
            localStorage.setItem('orderDevices', JSON.stringify(this.orderDevices));
            localStorage.setItem('orderScratchCards', JSON.stringify(this.orderScratchCards));
            localStorage.setItem('orderSimCards', JSON.stringify(this.orderSimCards));

            this.router.navigate(['/cart/order-summary']);
        }
    }

    /**
     * Buy a device
     * @param id
     */
    buyDevice(id) {
        for (const i of this.carts) {
            if (i._id === id) {
                this.orderDevices.push({
                    _id: i._id,
                    type: i.type,
                    quantity: i.quantity,
                    deviceUrl: i.deviceUrl,
                    brand: i.brand,
                    color: i.color,
                    colorId: i.colorId,
                    model: i.model,
                    storage: i.storage,
                    totalPrice: i.totalPrice,
                    unitPrice: i.unitPrice,
                    thumbnailUrl: i.thumbnailUrl
                });
                localStorage.setItem('allTotal', String(i.totalPrice));
            }
            localStorage.setItem('orderDevices', JSON.stringify(this.orderDevices));
            localStorage.setItem('orderScratchCards', JSON.stringify(this.orderScratchCards));
            localStorage.setItem('orderSimCards', JSON.stringify(this.orderSimCards));

            this.router.navigate(['/cart/order-summary']);
        }
    }

    /**
     * Buy a scratch card
     * @param id
     */
    buyScratchCard(id) {
        for (const i of this.carts) {
            if (i._id === id) {
                this.orderScratchCards.push({
                    imageUrl: this.resCart.scratchCardIcon,
                    quantity: i.quantity,
                    totalPrice: i.totalPrice,
                    type: i.type,
                    unitPrice: i.unitPrice,
                    scratchCardAmount: i.scratchCardAmount,
                    _id: i._id
                });

                localStorage.setItem('allTotal', String(i.totalPrice));
            }
            localStorage.setItem('orderDevices', JSON.stringify(this.orderDevices));
            localStorage.setItem('orderScratchCards', JSON.stringify(this.orderScratchCards));
            localStorage.setItem('orderSimCards', JSON.stringify(this.orderSimCards));

            this.router.navigate(['/cart/order-summary']);
        }
    }

    /**
     * Buy one or more item
     */
    buyItems() {
        for (const i of this.carts) {
            for (const j of this.orderItem) {
                if (i._id === j) {
                    if (i.type === 'sim-card') {
                        if (i.planSubscription.addOns.length > 0) {
                            this.orderSimCards.push({
                                _id: i._id,
                                type: i.type,
                                simIcon: this.resCart.simIcon,
                                topUpIcon: this.resCart.topUpIcon,
                                simPriceIcon: this.resCart.simPriceIcon,
                                quantity: i.quantity,
                                topUpAmount: i.topUpAmount,
                                totalPrice: i.totalPrice,
                                unitPrice: i.unitPrice,
                                cardPrice: i.cardPrice,
                                simCardNumber: i.simCardNumber,
                                planSubscription: {
                                    addOns: [
                                        {
                                            addOnIcon: i.planSubscription.addOns[0].addOnIcon,
                                            addOnName: i.planSubscription.addOns[0].addOnName,
                                            amount: i.planSubscription.addOns[0].amount,
                                            duration: i.planSubscription.addOns[0].duration,
                                            _id: i.planSubscription.addOns[0]._id
                                        }
                                    ],
                                    planIcon: i.planSubscription.planIcon,
                                    planName: i.planSubscription.planName,
                                    _id: i.planSubscription._id
                                }
                            });
                        } else {
                            this.orderSimCards.push({
                                _id: i._id,
                                type: i.type,
                                simIcon: this.resCart.simIcon,
                                topUpIcon: this.resCart.topUpIcon,
                                simPriceIcon: this.resCart.simPriceIcon,
                                cardPrice: i.cardPrice,
                                quantity: i.quantity,
                                topUpAmount: i.topUpAmount,
                                totalPrice: i.totalPrice,
                                unitPrice: i.unitPrice,
                                simCardNumber: i.simCardNumber,
                                planSubscription: {
                                    addOns: [],
                                    planIcon: i.planSubscription.planIcon,
                                    planName: i.planSubscription.planName,
                                    _id: i.planSubscription._id
                                }
                            });
                        }
                    }

                    if (i.type === 'device') {
                        this.orderDevices.push({
                            _id: i._id,
                            type: i.type,
                            quantity: i.quantity,
                            deviceUrl: i.deviceUrl,
                            brand: i.brand,
                            color: i.color,
                            colorId: i.colorId,
                            model: i.model,
                            storage: i.storage,
                            totalPrice: i.totalPrice,
                            unitPrice: i.unitPrice,
                            thumbnailUrl: i.thumbnailUrl
                        });
                    }

                    if (i.type === 'scratch-card') {
                        this.orderScratchCards.push({
                            imageUrl: this.resCart.scratchCardIcon,
                            quantity: i.quantity,
                            totalPrice: i.totalPrice,
                            type: i.type,
                            unitPrice: i.unitPrice,
                            scratchCardAmount: i.scratchCardAmount,
                            _id: i._id
                        });
                    }
                }
            }
        }

        if (this.orderSimCards.length === 0 && this.orderDevices.length === 0 && this.orderScratchCards.length === 0) {
            return;
        }

        localStorage.setItem('orderSimCards', JSON.stringify(this.orderSimCards));
        localStorage.setItem('orderDevices', JSON.stringify(this.orderDevices));
        localStorage.setItem('orderScratchCards', JSON.stringify(this.orderScratchCards));
        localStorage.setItem('allTotal', String(this.totalPrice));

        this.router.navigate(['/cart/order-summary']);
    }

    updateCardPrices() {
        let availableDiscounts = this.carts.reduce((acc, crr) => acc + (crr.type === 'device' ? crr.quantity : 0), 0);
        const simCards = this.carts.filter((cart) => cart.type === 'sim-card');
        if (availableDiscounts > 0) {
            simCards.forEach((simCard) => {
                simCard.totalPrice =
                    availableDiscounts > 0 ? simCard.totalPrice - simCard.cardPrice : simCard.totalPrice;
                simCard.cardPrice = availableDiscounts > 0 ? simCard.discountedCardPrice : simCard.cardPrice;
                availableDiscounts--;
            });
        }
    }
}
