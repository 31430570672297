import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as $ from "jquery";
import { HomePageService } from "../service/home-page.service";
import { FooterComponent } from "../includes/footer/footer.component";
import { letProto } from "rxjs/operator/let";

@Component({
	selector: "app-layouts",
	templateUrl: "./layouts.component.html",
	styleUrls: ["./layouts.component.scss"]
})
export class LayoutsComponent implements OnInit {
	// @ViewChild(FooterComponent) child;
	keywords = { "Log In": "", Support: "" };
	hover: string;
	language: string;
	languages = [
		{ key: "en", value: "en" },
		{ key: "km", value: "km" },
		{ key: "zh", value: "zh" }
	];
	keyword: any;
	public dataSearch;
	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	phoneNumber: string;
	status: string;
	constructor(
		private translate: TranslateService,
		private homePageService: HomePageService
	) {
		const getLanguage = localStorage.getItem("language") || "en";
		translate.setDefaultLang(getLanguage);
		translate.use(getLanguage);
		localStorage.setItem("language", getLanguage);
		this.language = getLanguage;
		this.phoneNumber = localStorage.getItem("main-phone");
	}

	changeLangs($event) {
		this.language = $event.target.value;
		// console.log('leng', this.language);
		this.translate.setDefaultLang(this.language);
		this.translate.use(this.language);
		localStorage.setItem("language", this.language);
		location.reload();
	}
	@HostListener("window:beforeunload", ["$event"])
	beforeunloadHandler(event) {
		this.status = "disconnected";
		localStorage.setItem("status", this.status);
		this.postAppUsages();
	}

	ngOnInit() {
		this.status = "connected";
		this.postAppUsages();
	}

	postAppUsages() {
		// const data = {
		// 	phoneNumber: this.phoneNumber,
		// 	status: this.status
		// };
		// this.homePageService.postAppUsages(data).subscribe(res => {
		// 	// console.log('hi', res);
		// },
		// 	Error => {
		// 		console.log(Error.message);
		// 	}
		// );
	}
	getKeywords(): void {
		this.homePageService.getKeywords().subscribe(data => {
			this.keywords = data;
		});
	}
	getSearch(): void {
		// this.homePageService
		// 	.getSearches(this.keyword, this.language)
		// 	.subscribe(data => {
		// 		this.dataSearch = data;
		// 		console.log("-----", this.dataSearch);
		// 	});
	}
}
