import { Component, OnInit, HostListener } from "@angular/core";
import { ProductsServicesService } from "../../../service/products-services.service";
import { GlobleService } from "../../../service/globle.service";
import { DevicesService } from "../../../service/devices.service";
@Component({
	selector: "app-one-plan",
	templateUrl: "./one-plan.component.html",
	styleUrls: ["./one-plan.component.scss"]
})
export class OnePlanComponent implements OnInit {
	language: string;
	description: string;
	desktopImage: string;
	mobileImage: string;
	des: string;
	keywords: any = {};

	newTouristSimPlan: any;
	toursitSimSectionTwo: any = {};
	toursitSimSectionThree: any = {};
	touristPass: any = [];
	sectionOne: any;
	lists: any;
	sectionTwo: any = [];
	sectionThree: any;
	public fixed: Boolean = false;
	sectionFour: any;
	desktopImageUrl4: any;
	title4: any;
	subTitle4: any;
	sectionFive: any;
	desktopImageUrl5: any;
	title5: any;
	subTitle5: any;
	link5: any;
	lists5: any;
	TouristSim: any;
	TouristSimSection1: any;
	TouristSimSection2: any;
	TouristSimSection3: any;
	TouristSimSection4: any;
	// featureLists: any;
	// TouristSimTitle: any;
	// TouristSimDescription: any;
	// TouristSimSection3Title: any;
	// TouristSimSection3list: any;
	TouristSimSection4Title: any;
	// TouristSimSection4list: any;

	touristSimPlanBg: String =
		"/assets/product-services/new-tourist-sim/Angkorwat.jpg";
	constructor(
		private productService: ProductsServicesService,
		private devicesService: DevicesService,
		private api: GlobleService
	) {}
	@HostListener("window:scroll", [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem("keyword_object"));
		this.language = localStorage.getItem("language") || "en";

		this.getOnePlans();
		// this.getTouristSim();
		// this.getNewTouristPlan();
		// this.touristsPass();
	}

	/**
	 * Get plan from api
	 */
	getOnePlans() {
		this.productService.getOnePlans(this.language).subscribe(res => {
			this.sectionOne = res.section1;
			this.sectionTwo = res.section2;
			$(".seatel-top-up").css(
				"background-image",
				`url(${this.sectionTwo.featureImageUrl})`
			);
			this.lists = this.sectionTwo.featureList;
			this.sectionThree = res.section3;
			$(".seatel-one-plan").css(
				"background-image",
				`url(${this.sectionThree.descriptionImageUrl})`
			);
			this.description = this.sectionThree.description.replace(/- /g, " ");
			this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
			this.mobileImage = `${this.sectionOne.mobileImageUrl}`;
			this.sectionFour = res.section4;

			$(".new-tourist-sim-plan-bg").css({
				"background-image": `url(${this.touristSimPlanBg})`,
				"background-position": "bottom"
			});

			if (window.location.href.indexOf("/tourist") > -1) {
				setTimeout(() => {
					const el = document.getElementById("TouristSIM");
					el.scrollIntoView();
				}, 2000);
			}
		});
	}

	// Get TouristSIM from API
	// getTouristSim() {
		// this.productService.getTouristSim(this.language).subscribe(data => {
		// 	this.TouristSim = data;
		// 	this.TouristSimSection1 = this.TouristSim.section1;
		// 	// $('.trawel-unlimite-2').css(
		// 	// 	'background-image',
		// 	// 	`url(${this.TouristSimSection1.desktopImageUrl})`
		// 	// );
		// 	this.TouristSimSection2 = this.TouristSim.section2;
		// 	// this.TouristSimTitle = this.TouristSimSection2.title;
		// 	// this.TouristSimDescription = this.TouristSimSection2.description;
		// 	// this.featureLists = this.TouristSimSection2.featureList;
		// 	this.TouristSimSection3 = this.TouristSim.section3;
		// 	// this.TouristSimSection3Title = this.TouristSimSection3.title;
		// 	// this.TouristSimSection3list = this.TouristSimSection3.list;
		// 	this.TouristSimSection4 = this.TouristSim.section4;
		// 	// this.TouristSimSection4Title = this.TouristSimSection4.title;
		// 	// this.TouristSimSection4list = this.TouristSimSection4.list;
		// });
	// }

	// getNewTouristPlan() {
	// 	this.productService.getNewTouristSimPlan(this.language).subscribe(data => {
	// 		this.newTouristSimPlan = data;
	// 	});

	// 	this.devicesService.touristsSim(this.language).subscribe(res => {
	// 		// console.log("touristsSim ", res);
	// 		this.toursitSimSectionTwo = res.section2;
	// 		this.toursitSimSectionTwo.desktopImageUrl = res.section1.desktopImageUrl;
	// 		this.toursitSimSectionTwo.mobileImageUrl = res.section1.mobileImageUrl;
	// 		this.toursitSimSectionThree = res.section3;
	// 		// console.log(this.toursitSimSectionTwo);
	// 		$(".new-tourist-sim-plan-bg").css(
	// 			"background-image",
	// 			`url(${res.section3.desktopImageUrl})`
	// 		);
	// 		$(".new-tourist-sim-plan-bg").css("background-repeat", "no-repeat");
	// 	});
	// }

	// touristsPass() {
	// 	this.devicesService.touristsPass().subscribe(data => {
	// 		this.touristPass = data;
	// 		// console.log("touristpass ", this.touristPass);
	// 	});
	// }

	/**
	 * Prevent translate from backend
	 * @param keyword
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
