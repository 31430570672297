import { Pipe, PipeTransform } from '@angular/core';
import { HomePageService } from './service/home-page.service';
import { GlobleService } from './service/globle.service';

@Pipe({ name: 'toImageUrl' })
export class ToImageUrlPipe implements PipeTransform {
    constructor(private global: GlobleService) {}
    transform(value: string) {
        return value.includes('http') || value.includes('https')
            ? value
            : this.global.apiUrl + '/uploads/' + value;
    }
}
