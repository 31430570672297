import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { GlobleService } from "../../../service/globle.service";
import { NgwWowService } from "ngx-wow";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/filter";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HomePageService } from "../../../service/home-page.service";
import { AdminService } from "../../../service/admin.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
declare const FB: any;
@Component({
	selector: "app-personal-home",
	templateUrl: "./personal-home.component.html",
	styleUrls: ["./personal-home.component.scss"]
})
export class PersonalHomeComponent implements OnInit {
	[x: string]: any;
	@HostListener("window:resize", ["$event"])
	public keywords;
	language: string;
	promotions: any;
	bannerUrl: string;
	getSection: any = {};
	imagebackground: any = {};
	getButton: any = {};
	mobileAppSection: any = {};
	code = "yes-cyn";
	getDraw: any = [];
	hasWon: any;
	frmDraw1: FormGroup;
	getCertType: string;
	getCertNumber: string;
	submitteDraw: boolean;
	drawId: any;
	loginType: string;
	itemType: any;
	getItem: any;
	getAngPaoExpire: any;
	knyPromotion: any;
	mainPromotions: any = {};
	rootUrl: string = this.globalService.apiUrl;
	popUp: any = {};
	adsText = "";
	btnText = "";
	imgUrl = "";
	shownAds: boolean;
	constructor(
		private router: Router,
		private homePageService: HomePageService,
		private adminService: AdminService,
		public sanitizer: DomSanitizer,
		private globalService: GlobleService
	) {}

	ngOnInit() {
		this.loginType = localStorage.getItem("type");
		this.keywords = JSON.parse(localStorage.getItem("keyword_object"));
		this.language = localStorage.getItem("language");
		this.isLoggedIn = localStorage.getItem("isLoggedin");
		this.showAdvertise();
		if (this.isLoggedIn === "true") {
			this.isLoggedIn = true;
		} else {
			this.isLoggedIn = false;
		}
		this.getPromotions();
		this.getHomePage();
		this.getMobileApp();
		this.getMain();

		$("#initials").hide();
		$("#addlinkAnimation").show();
		setTimeout(function() {
			$("#initials").show();
			$("#addlinkAnimation").hide();
		}, 7400);

		// For Ang Pao click
		const expires = 2 * 60 * 60;
		const now = Date.now();
		const schedule = now + expires * 1000;
		localStorage.setItem("AngPaoExpire", String(schedule));
		this.getAngPaoExpire = localStorage.getItem("AngPaoExpire");
		if (Number(this.getAngPaoExpire) < now) {
			localStorage.removeItem("clickAngpao");
			localStorage.removeItem("popup1");
		}

		// const popup1 = localStorage.getItem("popup1");
		// if (!popup1) {
		// 	localStorage.setItem("popup1", "true");
		// 	(<any>$("#happyNewYear")).modal("show");
		// }

		this.frmDraw1 = new FormGroup({
			name: new FormControl("", Validators.required),
			phoneNumber: new FormControl("", Validators.required)
		});
		// end For Ang Pao click
	}

	closeAdvertise(autoClose) {
		setTimeout(() => {
			(<any>$("#attention")).modal("hide");
			this.closeAdvertise(autoClose);
			sessionStorage.setItem("shownAds", "true");
			this.shownAds = true;
		}, autoClose * 1000);
	}
	showAdvertise() {
		if (sessionStorage.getItem("shownAds") == "true") {
			(<any>$("#attention")).remove();
			return;
		}
		this.homePageService.getActivePopUp().subscribe(res => {
			if (res !== null) {
				// console.log(res);
				let lang = this.language;
				if (this.language == "km") {
					lang = "kh";
				}
				this.adsText = res[lang].text;
				this.btnText = res[lang].btnText;
				this.popUp = res;
				this.imgUrl = this.rootUrl + "/uploads/" + res[lang].imageUrl.web;
				setTimeout(() => {
					(<any>$("#attention")).modal("show");
					this.closeAdvertise(Number(res["autoClose"]));
				}, Number(res["delayPopup"]) * 1000);
			}
		});
	}

	closeAds() {
		(<any>$("#attention")).modal("hide");
	}
	get g() {
		return this.frmDraw1.controls;
	}
	getMobileApp() {
		this.homePageService.getMobileApp(this.language).subscribe(res => {
			let data: any;
			data = res;
			this.mobileAppSection = data.data[0];
		});
	}

	getPromotions() {
		this.homePageService.getPromotions(this.language).subscribe(res => {
			this.promotions = res;
			// console.log(this.promotions);
		});
	}
	getMain() {
		this.homePageService.getMain().subscribe((res: any) => {
			this.knyPromotion = res.promotions.find(p => p.code === "yes-kny");

			// this.mainPromotions = res.promotions;
			// console.log('mainPromotions', this.mainPromotions.promotions);
			// this.promotionsType = this.mainPromotions.find(p => p.code === 'yes-kny');
			// console.log('this.getPromotions', this.getPromotions);
		});
	}
	openStore(type) {
		const url =
			type === 'ios'
				? this.mobileAppSection.imageIos.imageLink
				: type === 'huawei' ? 'https://appgallery.cloud.huawei.com/ag/n/app/C102169499?locale=en_US&source=appshare&subsource=C102169499' 
				: this.mobileAppSection.imageAndroid.imageLink;
		const win = window.open(url, '_blank');
		win.focus();
	}
	getHomePage() {
		this.homePageService.getHomePage(this.language).subscribe(data => {
			this.getSection = data;
		});
	}
	preventTranslate(keywords) {
		if (this.keywords) {
			if (this.keywords[keywords]) {
				return this.keywords[keywords][this.language];
			} else {
				return keywords;
			}
		} else {
			return keywords;
		}
	}

	zhTips(e) {
		const body = {
			phoneNumber: "",
			loggedIn: this.isLoggedIn
		};
		this.adminService.getPromotionDraw(this.code, body).subscribe(res => {
			this.getDraw = res;
			this.getItem = this.getDraw.item;
			const clickAngpao = localStorage.getItem("clickAngpao");
			if (!clickAngpao) {
				localStorage.setItem("clickAngpao", "true");
				(<any>$("#tipTrue")).modal("show");
				this.hasWon = this.getDraw.hasWon;
				if (this.hasWon === true) {
					$(".showture").removeClass("d-none");
					$(".showfalse").addClass("d-none");
				} else {
					$(".showfalse").removeClass("d-none");
					$(".showture").addClass("d-none");
				}
			} else {
				(<any>$("#tipFalse")).modal("show");
			}
		});
	}
	getLuckyDraw(e) {
		const getId = this.getDraw.item;
		this.drawId = getId._id;
		this.submitteDraw = true;
		if (this.frmDraw1.invalid) {
			return;
		}
		const body = {
			profile: {
				name: this.frmDraw1.get("name").value,
				phoneNumber: this.frmDraw1.get("phoneNumber").value
			}
		};
		this.adminService
			.getPromotionItem(this.drawId, body)
			.subscribe((res: any) => {
				this.getDraw = res;
				this.quote = res.message;
				FB.ui(
					{
						method: "share",
						quote: this.quote,
						hashtag: "#yesseatelCNYpromo",
						href: "https://www.yes.com.kh/luckyangpao"
					},
					function(response) {
						console.log(response);
					}
				);
				localStorage.setItem("itemType", this.getItem.type);
				localStorage.setItem("itemsimCardPrice", this.getItem.simCardPrice);
				this.itemType = localStorage.getItem("itemType");
				if (this.itemType === "sim-card") {
					this.router.navigate(["/e-hub/buy-sim"]);
				}
				(<any>$("#submitInfor")).modal("hide");
			});
	}
}
