import { Component, OnInit, HostListener } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { GlobleService } from '../../../service/globle.service';

@Component({
	selector: 'app-free-data',
	templateUrl: './free-data.component.html',
	styleUrls: ['./free-data.component.scss']
})
export class FreeDataComponent implements OnInit {
	language: string;
	description: string;
	desktopImage: string;
	mobileImage: string;
	keywords: any = {};
	freeData: any = [];
	sectionTwo: any = [];
	steps: any = [];
	stepMobileOne: any;
	stepMobileTwo: any;
	stepMobileThree: any;
	sectionOne: any;
	sectionThree: any;
	sectionFour: any;
	YouTubesectionOne: any;
	YouTubesectionTwo: any = {};
	YouTubedescription: any;
	PromotionYouTube: any = {};

	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	YouTubesectionThree: any;
	YouTubesectionFour: any;
	YouTubesectionThreeTitle: any;
	YouTubesectionThreeSubTitle: any;
	YouTubesectionThreeStep: any = [];
	YouTubesectionFourList: any;
	YouTubeStepMobileOne: any;
	YouTubeStepMobileTwo: any;
	YouTubeStepMobileThree: any;
	YouTubesectionFourDescription: string;
	desktopImageUrl2: any;
	constructor(
		private productService: ProductsServicesService,
		private api: GlobleService
	) { }
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.keywords.desktopUrl = {
			en: '/assets/product-services/EN-Thunderstorm-[1920-x-450px].jpg',
			km: '/assets/product-services/KH-Thunderstorm-[1920-x-450px].jpg',
			zh: '/assets/product-services/CH-Thunderstorm-[1920-x-450px].jpg'
		};
		this.keywords.mobileUrl = {
			en: '/assets/product-services/En-Thunderstorm-[1080-x-900px].jpg',
			km: '/assets/product-services/KH-Thunderstorm-[1080-x-900px].jpg',
			zh: '/assets/product-services/CH-Thunderstorm-[1080-x-900px].jpg'
		};
		(this.keywords.topText = {
			// tslint:disable-next-line:max-line-length
			en:
				'The best 4G xperience is now FREE for you to enjoy for 12 hours everyday from 6am to 6pm. Get your yes sim card and activate any weekly or monthly Internet Pack and the FREE YouTube is yours to enjoy.',
			km:
				'Say yes សម្រាប់លេង YouTubeឥតកំណត់ដោយឥតគិតលុយ! ឥឡូវនេះអ្នកអាចសាកល្បងប្រើអុីនធឺណិត 4Gល្អបំផុតដោយឥតគិតលុយជារៀងរាល់ថ្ងៃចាប់ពីម៉ោង 6ព្រឹកដល់ 6ល្ងាច។ អតិថិជនគម្រោង one គ្រាន់តែភ្ជាប់ការប្រើប្រាស់កញ្ចប់អុីនធឺណិតប្រចាំថ្ងៃឬប្រចាំសប្តាហ៍តែប៉ុណ្ណោះ។',
			zh:
				'现在，您可以免费体验最佳的4G一周啦！完全免费、不限量。Yes,就是YouTube。您只要从最近的yes hub营业厅免费领取yes sim卡，并激活Internet Pack流量包，周包、月包均可[流量包点击]。'
		}),
			(this.keywords.textList1 = {
				// tslint:disable-next-line:max-line-length
				en:
					'FREE UNLIMITED YouTube from 6am – 6pm starts from the moment you activate Internet Pack and lasts till your Internet Pack is valid.',
				km:
					'YouTubeឥតគិតលុយ ចាប់ពីម់ោង 6ព្រឹកដល់6ល្ងាចមានសុពលភាពចាប់ពីពេលដែលអ្នកភ្ជាប់ការប្រើប្រាស់កញ្ចប់អុីនធឺណិតរហូតដល់កញ្ចប់អុីនធឺណិតរបស់អ្នកអស់សុពលភាព។',
				zh:
					'不限量YouTube有效期为7天，24小时为一天，从您激活Internet Pack流量包起即生效。'
			}),
			(this.keywords.textList2 = {
				// tslint:disable-next-line:max-line-length
				en:
					'If last Internet Pack that you have activated is shorter than previous activations, the longest validity is effective for FREE UNLIMITED YouTube.',
				km:
					'ប្រសិនបើសុពលភាពរបស់កញ្ចប់អុីនធឺណិតដែលអ្នកប្រើប្រាស់ចុងក្រោយខ្លីជាងសុពលភាពកញ្ចប់អ៊ីនធឺណិតដែលអ្នកប្រើប្រាស់មុន សុពលភាពរបស់ YouTubeឥតគិតលុយ ចាប់ពីម់ោង 6ព្រឹកដល់6ល្ងាចនឹងយកតាមសុពលភាពកញ្ចប់អុីនធឺណិតដែលវែងជាងគេ។​',
				zh: '  活动有效期从2018年9月12日---18日。'
			}),
			(this.keywords.textList3 = {
				// tslint:disable-next-line:max-line-length
				en: 'This promotion is valid until further notice.',
				km:
					'YouTubeឥតគិតលុយ ចាប់ពីម់ោង 6ព្រឹកដល់6ល្ងាចមានសុពលភាពរហូតដល់មានការជូនដំណឹងបន្ថែម។',
				zh:
					' 如果你参加买一赠一活动（1美元/20GB或者2美元/40GB)，你可以享受从早上6点到下午6点不限量YouTube，直到您的流量包有效期结束。'
			}),
			(this.keywords.textList4 = {
				// tslint:disable-next-line:max-line-length
				en:
					'If you activate a monthly Internet Pack, after the 7 days of UNLIMITED YouTube, you will still be able to enjoy FREE UNLIMITED YouTube from 6am to 6pm every day until the end of Internet Pack validity.',
				km:
					'ប្រសិនបើអ្នកភ្ជាប់កញ្ចប់អីុនធឺណិតប្រចាំខែ នោះបន្ទាប់ពី7ថ្ងៃនៃYouTubeឥតកំណត់ អ្នកនៅតែ​អាចបន្តមើលYouTubeឥតគិតលុយ ឥតកំណត់ចាប់ពីម៉ោង​ 6 ព្រឹកដល់ 6 ល្ងាច​។',
				zh:
					'如果你订购了月包流量包，不限量YouTube7天后，你仍可以享受从早上6点到下午6点不限量YouTube，直到您的流量包有效期结束。'
			});
		this.language = localStorage.getItem('language') || 'en';
		this.getFreeData();
		this.getPromotionYouTube();
	}

	getFreeData() {
		this.productService.getFreeData(this.language).subscribe(res => {
			// console.log('Free data', res);
			this.freeData = res;
			this.sectionOne = this.freeData.section1;
			this.sectionTwo = this.freeData.section2;
			this.sectionThree = this.freeData.section3;
			this.sectionFour = this.freeData.section4;

			this.description = this.sectionThree.description.replace(/- /g, ' ');

			this.steps = this.sectionTwo.steps;
			this.stepMobileOne = this.steps[0].title;
			this.stepMobileTwo = this.steps[1].title;
			this.stepMobileThree = this.steps[2].title;

			// console.log('data 3', this.freeData);
			// console.log('data 1', this.stepMobileOne);
			// console.log('data 2', this.stepMobileTwo);
			// console.log('data 3', this.stepMobileThree);

			// $('.free-data-bg').css(
			// 	'background-image',
			// 	`url(${this.api.apiUrl}/uploads/${
			// 	this.sectionFour.desktopImageUrl
			// 	})`
			// );
		});
	}
	getPromotionYouTube() {
		this.productService.getPromotionYouTube(this.language).subscribe(data => {
			this.PromotionYouTube = data;
			this.YouTubesectionOne = this.PromotionYouTube.section1;
			this.YouTubesectionTwo = this.PromotionYouTube.section2;
			this.YouTubedescription = this.YouTubesectionTwo.description.replace(
				/- /g,
				' '
			);
			this.desktopImageUrl2 = this.YouTubesectionTwo.desktopImageUrl;
			$('.trawel-unlimite-2').css(
				'background-image',
				`url(${this.desktopImageUrl2})`
			);
			this.YouTubesectionThree = this.PromotionYouTube.section3;
			this.YouTubesectionThreeTitle = this.YouTubesectionThree.title;
			this.YouTubesectionThreeSubTitle = this.YouTubesectionThree.subTitle;
			this.YouTubesectionThreeStep = this.YouTubesectionThree.steps;
			this.YouTubeStepMobileOne = this.YouTubesectionThreeStep[0].title;
			this.YouTubeStepMobileTwo = this.YouTubesectionThreeStep[1].title;
			this.YouTubeStepMobileThree = this.YouTubesectionThreeStep[2].title;
			this.YouTubesectionFour = this.PromotionYouTube.section4;
			this.YouTubesectionFourList = this.YouTubesectionFour.list;
			this.YouTubesectionFourDescription = this.YouTubesectionFour.description.replace(/- /g, ' ');
			this.desktopImage = `${this.YouTubesectionOne.desktopImageUrl}`;
			this.mobileImage = `${this.YouTubesectionOne.mobileImageUrl}`;
			$('.free-data-bg').css(
				'background-image',
				`url(${this.YouTubesectionFour.desktopImageUrl})`
			);
		});
	}
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
