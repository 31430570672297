import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { BadGatewayPageComponent } from './bad-gateway-page/bad-gateway-page.component';

const routes: Routes = [
    {
        path: '',
        component: NotFoundComponent,
        children: [
            { path: '', component: NotFoundPageComponent },
            { path: 'bad-gateway', component: BadGatewayPageComponent }
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes), CommonModule],
    exports: [RouterModule],
    declarations: []
})
export class NotFoundRoutingModule {}
export const RoutingConponets = [
    NotFoundComponent,
    NotFoundPageComponent,
    BadGatewayPageComponent
];
