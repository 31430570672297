import { Component, OnInit, HostListener } from "@angular/core";
import { ProductsServicesService } from "../../../service/products-services.service";
import { GlobleService } from "../../../service/globle.service";
import { DevicesService } from "../../../service/devices.service";
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: "app-rik-reay",
	templateUrl: "./rik-reay.component.html",
	styleUrls: ["./rik-reay.component.scss"]
})
@Pipe({ name: 'safeHtml' })

export class RikReayComponent implements OnInit {
	language: string;
	description: string;
	desktopImage: string;
	mobileImage: string;
	des: string;
	keywords: any = {};
	public fixed: Boolean = false;
	sectionOne: any;
	sectionTwo: any;
	sectionThree: any;
	sectionFour: any;
	sectionOneTitle : string;
	contentSectionOne: string;
	contentSectionTwo: string;
	contentSectionThree: string;
	contentSectionFour: string;
	sectionTwoImg : string;
	sectionThreeTitle: string;
	sectionFourTitle: string;
	sectionThreeTableImg: string;
	sectionThreeTableTitle: string;
	mainTitle: string;
	backgroundImage: string;

	touristSimPlanBg: String =
		"/assets/product-services/new-tourist-sim/Angkorwat.jpg";
	constructor(
		private productService: ProductsServicesService,
		private devicesService: DevicesService,
		private api: GlobleService,
		private sanitizer: DomSanitizer
	) {}
	@HostListener("window:scroll", [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem("keyword_object"));
		this.language = localStorage.getItem("language") || "en";

		this.getRikReay();
	}

	/**
	 * Get plan from api
	 */
	getRikReay() {
		this.productService.getRikReay(this.language).subscribe(res => {
			console.log(res);
			this.mainTitle = res.mainTitle
			// this.backgroundImage = res.backgroundImg
			this.backgroundImage = res.desktopImageUrl
			this.sectionOne = res.mainContent[0]
			this.sectionOneTitle = this.sectionOne.title
			this.contentSectionOne = this.transform(this.sectionOne.content);
			this.sectionTwo = res.mainContent[1]
			this.contentSectionTwo = this.transform(this.sectionTwo.content)
			this.sectionTwoImg = this.sectionTwo.imgurl
			this.sectionThree = res.mainContent[2]
			this.sectionThreeTitle = this.sectionThree.title
			this.sectionThreeTableTitle = this.sectionThree.tableTitle
			this.sectionThreeTableImg = this.sectionThree.tableBgimage
			this.contentSectionThree = this.transform(this.sectionThree.content)
			this.sectionFour = res.mainContent[3]
			this.sectionFourTitle = this.sectionFour.title
			this.contentSectionFour = this.transform(this.sectionFour.content)


		});
	}


	transform(value: string): string {
		return this.sanitizer.bypassSecurityTrustHtml(value) as string;
	  }

	/**
	 * Prevent translate from backend
	 * @param keyword
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
