import { Component, OnInit, HostListener } from "@angular/core";
import { AboutPageService } from "../../../service/about-page.service";

@Component({
	selector: "app-corporate",
	templateUrl: "./corporate.component.html",
	styleUrls: ["./corporate.component.scss"]
})
export class CorporateComponent implements OnInit {
	language: string;
	corporate: any;
	public keywords;
	CorporateBanner: any = [];
	constructor(private aboutPageService: AboutPageService) {}

	ngOnInit() {
		this.language = localStorage.getItem("language");
		this.keywords = JSON.parse(localStorage.getItem("keyword_object"));

		this.getCorporate();
		this.getCorporateBanner();
	}

	getCorporate() {
		this.aboutPageService.getCorporate(this.language).subscribe(data => {
			this.corporate = data.data;
			// console.log(data);
		});
	}
	getCorporateBanner() {
		this.aboutPageService.getCorporateBanner(this.language).subscribe(data => {
			this.CorporateBanner = data.section1;
		});
	}
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}
}
