import { Component, OnInit, HostListener } from '@angular/core';
import 'rxjs/add/operator/filter';
import { AboutPageService } from '../../../service/about-page.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
@Component({
    selector: 'app-media-events',
    templateUrl: './media-events.component.html',
    styleUrls: ['./media-events.component.scss']
})
export class MediaEventsComponent implements OnInit {
    articles: any = [];
    totals: any;
    language: string;
    limit = 9;
    offset = 0;
    // tslint:disable-next-line:no-inferrable-types
    public fixed: boolean = false;
    constructor(
        private aboutPageService: AboutPageService,
        private translate: TranslateService
    ) {
        const getLanguage = localStorage.getItem('language') || 'en';
        translate.setDefaultLang(getLanguage);
        translate.use(getLanguage);
        localStorage.setItem('language', getLanguage);
        this.language = getLanguage;
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.scrollY > 450) {
            this.fixed = true;
        } else if (this.fixed && window.scrollY < 500) {
            this.fixed = false;
        }
    }
    ngOnInit() {
        this.getEvents();
    }

    getEvents(): void {
        this.aboutPageService
            .getEvents(this.language, this.limit, this.offset)
            .subscribe(res => {
                console.log(res.data);
                this.articles = this.articles.concat(res.data);
                this.totals = res.metadata.total;
                console.log('Total', this.totals);
            });
    }

    getMore() {
        this.offset = this.offset + 9;
        this.getEvents();
    }
}
