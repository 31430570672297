import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { CartComponent } from './cart.component';
import { CartListComponent } from './cart-list/cart-list.component';
import { OrderSummaryStepComponent } from './order-summary-step/order-summary-step.component';
import { SuccessBuyDeviceComponent } from './success-buy-device/success-buy-device.component';
// import { AuthGuard } from '../../admin/guard/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: CartComponent,
		children: [
			{ path: 'cart-list', component: CartListComponent },
			{ path: 'order-summary', component: OrderSummaryStepComponent },
			{ path: 'success', component: SuccessBuyDeviceComponent }
		]
		// required login for user buy now and Add to card
		// canActivate: [AuthGuard]
	}
];
@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class CartRoutingModule {}
