import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: AdminComponent,
		children: [
			{ path: 'dashboard', component: DashboardComponent },
			{
				path: '',
				loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
			}
		],
		canActivate: [AuthGuard]
	}
];
@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
	declarations: []
})
export class AdminRoutingModule {}
export const RoutingConponets = [DashboardComponent, ProfileComponent];
