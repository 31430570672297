import { Component, OnInit, HostListener } from '@angular/core';
import { IOption } from 'ng-select';
import { FindShopService } from '../../../service/find-shop.service';
import { ActivatedRoute } from '@angular/router';
class Provinces implements IOption {
	label: string;
	value: string;
}

class Types implements IOption {
	label: string;
	value: string;
}

@Component({
	selector: 'app-find-shop',
	templateUrl: './find-shop.component.html',
	styleUrls: ['./find-shop.component.scss']
})
export class FindShopComponent implements OnInit {
	limit = 5;
	offset = 0;
	lat = 11.571523;
	lng = 104.898126;
	zoom = 7;
	style = '';
	scrollwheel: false;
	maxWidth: 100;
	language: string;
	search: string;
	currentItem: string;
	keywords: string;
	metaData: any;

	shops: any = [];
	shopsLocation: any = [];
	allShopsLocation: any = [];
	shopsLocationName: any;
	allShops: any = [];
	totals: number;
	noLoadmore = false;

	provinceNames = [];
	shopList = [];
	origalShop = [];

	selectedProvince = 'All Seatel Shop';
	selectedType = 'all shop type';
	type = '';

	provinces: Array<Provinces> = [];

	types: Array<Types> = [
		{ label: 'All Shop Type', value: 'all shop type' },
		{ label: 'Self-Run', value: 'self-run' },
		{ label: 'Activation Hub', value: 'activation-hub' },
		{ label: 'E-hub', value: 'e-hub' }
		// { label: 'Cooperative', value: 'cooperative' }
	];

	icons = {
		Head_office: '/assets/pink-map-small.png',
		Cooperative: '/assets/blue-map-small.png'
	};
	previous = null;
	loading: string;

	constructor(
		private shopService: FindShopService,
		private router: ActivatedRoute
	) {
		this.language = localStorage.getItem('language') || 'en';
		this.search = this.router.snapshot.queryParamMap.get('localArea') || '';

		/**
		 * Check search from support page
		 */
		if (this.search) {
			this.selectedProvince = this.search;
			this.currentItem = this.search;
			this.loadMoreBylocation(this.search);
		} else {
			this.getAllShop();
		}
	}

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.getLocation();

		/**
		 * Translate in local
		 */
		if (this.language === 'en') {
			this.provinces = [
				{ label: 'All yes hub', value: 'All Seatel Shop' },
				{ label: 'Phnom Penh', value: 'Phnom Penh' },
				{ label: 'Banteay Meanchey', value: 'Banteay Meanchey' },
				{ label: 'Battambang', value: 'Battambang' },
				{ label: 'Kampong Cham', value: 'Kampong Cham' },
				{ label: 'Kampong Chhnang', value: 'Kampong Chhnang' },
				{ label: 'Kampong Speu', value: 'Kampong Speu' },
				{ label: 'Kampong Thom', value: 'Kampong Thom' },
				{ label: 'Kampot', value: 'Kampot' },
				{ label: 'Kandal', value: 'Kandal' },
				{ label: 'Koh Kong', value: 'Koh Kong' },
				{ label: 'Kep', value: 'Kep' },
				{ label: 'Kratié', value: 'Kratié' },
				{ label: 'Mondulkiri', value: 'Mondulkiri' },
				{ label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
				{ label: 'Pailin', value: 'Pailin' },
				{ label: 'Preah Sihanouk', value: 'Preah Sihanouk' },
				{ label: 'Preah Vihear', value: 'Preah Vihear' },
				{ label: 'Pursat', value: 'Pursat' },
				{ label: 'Prey Veng', value: 'Prey Veng' },
				{ label: 'Ratanakiri', value: 'Ratanakiri' },
				{ label: 'Siem Reap', value: 'Siem Reap' },
				{ label: 'Stung Treng', value: 'Stung Treng' },
				{ label: 'Svay Rieng', value: 'Svay Rieng' },
				{ label: 'Takéo', value: 'Takéo' },
				{ label: 'Tboung Khmum', value: 'Tboung Khmum' }
			];

			this.types = [
				{ label: 'All Shop Type', value: 'all shop type' },
				{ label: 'Self-Run', value: 'self-run' },
				{ label: 'Activation Hub', value: 'activation-hub' },
				{ label: 'E Hub', value: 'self-run' }
			];
		} else if (this.language === 'km') {
			this.provinces = [
				{ label: 'yes hub ទាំងអស់', value: 'All Seatel Shop' },
				{ label: 'ភ្នំពេញ', value: 'Phnom Penh' },
				{ label: 'បន្ទាយមានជ័យ', value: 'Banteay Meanchey' },
				{ label: 'បាត់ដំបង', value: 'Battambang' },
				{ label: 'កំពង់ចាម', value: 'Kampong Cham' },
				{ label: 'កំពង់ឆ្នាំង', value: 'Kampong Chhnang' },
				{ label: 'កំពង់ស្ពឺ', value: 'Kampong Speu' },
				{ label: 'កំពង់ធំ', value: 'Kampong Thom' },
				{ label: 'កំពត', value: 'Kampot' },
				{ label: 'កណ្តាល', value: 'Kandal' },
				{ label: 'កោះកុង', value: 'Koh Kong' },
				{ label: 'កែប', value: 'Kep' },
				{ label: 'ក្រចេះ', value: 'Kratié' },
				{ label: 'មណ្ឌលគីរី', value: 'Mondulkiri' },
				{ label: 'ឧត្តរមានជ័យ', value: 'Oddar Meanchey' },
				{ label: 'បៃលិន', value: 'Pailin' },
				{ label: 'ព្រះសីហនុ', value: 'Preah Sihanouk' },
				{ label: 'ព្រះវិហារ', value: 'Preah Vihear' },
				{ label: 'ពោធិ៍សាត់', value: 'Pursat' },
				{ label: 'ព្រៃវែង', value: 'Prey Veng' },
				{ label: 'រតនគីរី', value: 'Ratanakiri' },
				{ label: 'សៀមរាប', value: 'Siem Reap' },
				{ label: 'ស្ទឹងត្រែង', value: 'Stung Treng' },
				{ label: 'ស្វាយរៀង', value: 'Svay Rieng' },
				{ label: 'តាកែវ', value: 'Takéo' },
				{ label: 'ត្បូងឃ្មុំ', value: 'Tboung Khmum' }
			];

			this.types = [
				{ label: 'ប្រភេទហាងទាំងអស់', value: 'all shop type' },
				{ label: 'រត់ដោយខ្លួនឯង', value: 'self-run' },
				{ label: 'Activation Hub', value: 'activation-hub' },
				{ label: 'E Hub', value: 'self-run' }
			];
		} else if (this.language === 'zh') {
			this.provinces = [
				{ label: 'yes hub 所有', value: 'All Seatel Shop' },
				{ label: '金边', value: 'Phnom Penh' },
				{ label: '波比', value: 'Banteay Meanchey' },
				{ label: '马德望', value: 'Battambang' },
				{ label: '甘榜湛', value: 'Kampong Cham' },
				{ label: '磅清扬', value: 'Kampong Chhnang' },
				{ label: '磅士碑', value: 'Kampong Speu' },
				{ label: '磅通', value: 'Kampong Thom' },
				{ label: '贡布', value: 'Kampot' },
				{ label: '甘丹', value: 'Kandal' },
				{ label: '戈公', value: 'Koh Kong' },
				{ label: '白马', value: 'Kep' },
				{ label: '桔井', value: 'Kratié' },
				{ label: '蒙多基里省', value: 'Mondulkiri' },
				{ label: 'Oddar Meanchey', value: 'Oddar Meanchey' },
				{ label: '拜林', value: 'Pailin' },
				{ label: '西哈努克', value: 'Preah Sihanouk' },
				{ label: '柏威夏', value: 'Preah Vihear' },
				{ label: '菩萨', value: 'Pursat' },
				{ label: '猎物冯', value: 'Prey Veng' },
				{ label: '腊塔纳基里', value: 'Ratanakiri' },
				{ label: '暹粒市', value: 'Siem Reap' },
				{ label: '特本克蒙', value: 'Stung Treng' },
				{ label: '甘拉', value: 'Svay Rieng' },
				{ label: '猛男', value: 'Takéo' },
				{ label: '特本克蒙', value: 'Tboung Khmum' }
			];

			this.types = [
				{ label: '所有商店类型', value: 'all shop type' },
				{ label: 'Self-Run', value: 'self-run' },
				{ label: 'Activation Hub', value: 'activation-hub' },
				{ label: 'E Hub', value: 'self-run' }
			];
		}
	}

	/**
	 * Create grid responsive on page
	 */
	resizeAllGridItems() {
		const allItems = document.getElementsByClassName('item');
		for (let x = 0; x < allItems.length; x++) {
			this.resizeGridItem(allItems[x]);
		}
		this.loading = '';
	}

	resizeInstance(instance) {
		const item = instance.elements[0];
		this.resizeGridItem(item);
	}

	resizeGridItem(item) {
		const grid = document.getElementsByClassName('grid')[0];
		// tslint:disable-next-line:radix
		const rowHeight = parseInt(
			window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
		);
		// tslint:disable-next-line:radix
		const rowGap = parseInt(
			window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
		);
		const rowSpan = Math.ceil(
			(item.querySelector('.content').getBoundingClientRect().height + rowGap) /
			(rowHeight + rowGap)
		);
		item.style.gridRowEnd = 'span ' + rowSpan;
	}

	/**
	 * Get current location
	 */
	getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				this.lat = position.coords.latitude;
				this.lng = position.coords.longitude;
			});
		}
	}

	/**
	 * Load more data filter by location
	 * @param location
	 */
	loadMoreBylocation(location) {
		this.loading = 'loading';
		this.selectedProvince = location;
		this.currentItem = location;

		this.shopService.getMoreBylocation(this.language, this.offset, location, this.type).subscribe(res => {
			this.shopList = [];
			// console.log(res);
			this.offset += 10;
			this.shopsLocation = res;
			this.shopsLocationName = location;
			this.totals = this.shopsLocation.metadata.total;
			// tslint:disable-next-line:forin
			for (const item in this.shopsLocation.data) {
				this.allShopsLocation.push(this.shopsLocation.data[item]);
			}

			if (this.allShopsLocation.length === 0) {
				this.noLoadmore = true;
			} else {
				this.noLoadmore = false;
			}
			this.loading = '';
		}, err => {
			console.log(err);
		});
	}

	/**
	 * Get all shop from backend
	 */
	getAllShop() {
		this.loading = 'loading';
		this.search = '';
		const ehub = false;
		return this.shopService.getShops(this.language, this.limit, this.type, ehub).subscribe(res => {
				this.allShops = [];
				this.allShopsLocation = [];
				this.offset = 0;
				this.noLoadmore = false;
				setTimeout(() => {
					this.metaData = res.metadata.total;
					this.shops = res.data;

					this.provinceNames = Object.keys(this.shops);
					// tslint:disable-next-line:forin
					for (const item in this.shops) {
						this.allShops.push({
							province: item,
							shops: this.shops[item]
						});
					}
					this.origalShop = this.allShops;
					if (this.origalShop.length === 0) {
						this.noLoadmore = true;
					} else {
						this.noLoadmore = false;
					}
					this.filter();
				});
			});
	}

	/**
	 * Filter data by location from backend
	 * @param search
	 */
	onSelectedProvince(search) {
		this.selectedProvince = search;
		this.lat = 11.571523;
		this.lng = 104.898126;
		this.zoom = 7;
		if (this.previous !== null) {
			this.previous.close();
		}
		this.previous = null;
		if (this.selectedProvince === 'All Seatel Shop') {
			this.getAllShop();
		} else {
			this.allShopsLocation = [];
			this.offset = 0;
			this.loadMoreBylocation(this.selectedProvince);
		}
	}

	/**
	 * Filter data by type from backend
	 * @param type
	 */
	onSelectedType(type: Types) {
		this.selectedType = `${type.value}`;
		if (this.selectedType === 'all shop type') {
			this.type = '';
		} else {
			this.type = `${type.value}`;
		}
		if (this.selectedProvince === 'All Seatel Shop') {
			this.getAllShop();
		} else {
			this.allShopsLocation = [];
			this.offset = 0;
			this.loadMoreBylocation(this.selectedProvince);
		}
		this.previous = null;
	}

	/**
	 * Filter data to render by location on page
	 */
	filter() {
		setTimeout(() => {
			if (this.selectedProvince === 'All Seatel Shop') {
				this.shopList = this.origalShop;
			} else {
				this.shopList = this.origalShop.filter(
					x => x.province === this.selectedProvince
				);
			}
			if (this.selectedType !== 'all shop type') {
				const temp = [];
				for (const item of this.shopList) {
					const myShops = [];
					for (const shop of item.shops) {
						if (shop.type === this.selectedType) {
							myShops.push(shop);
						}
					}
					if (myShops.length) {
						temp.push({
							province: item.province,
							shops: myShops
						});
					}
				}
				this.shopList = temp;
			}
		});

		setTimeout(() => this.resizeAllGridItems());
	}

	/**
	 * Open new info window on pin click and close old
	 * @param lat
	 * @param lng
	 * @param infowindow
	 */
	positionChanged(lat, lng, infowindow) {
		this.lat = lat;
		this.lng = lng;
		this.zoom = 10;

		if (this.previous === infowindow) {
			return;
		}

		if (this.previous !== null) {
			this.previous.close();
		}

		this.previous = infowindow;
	}

	/**
	 * Prevent translate
	 * @param keyword
	 */
	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
