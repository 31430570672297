import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'toPrice'
})
export class ToPricePipe implements PipeTransform {
    constructor() {}

    transform(content: string) {
        let getPrice: number;
        if (content.includes('$')) {
            getPrice = Number(content.replace('$', ''));
        } else {
            const str = Number(content.replace(/[^0-9.]/g, ''));
            getPrice = str / 100;
        }
        return getPrice;
    }
}
