import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { AuthService } from 'angularx-social-login';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	isActive = true;
	showMenu = '';
	language: string;
	public keywords;
	notifications: 0;
	message;
	phone: string;
	mainPhone: string;
	selected: string;
	type: string;
	offset = 0;
	total = 0;
	loginType: string;

	constructor(
		private router: Router,
		private adminService: AdminService,
		private authService: AuthService
	) {
		this.phone = localStorage.getItem('phone');
		this.mainPhone = localStorage.getItem('main-phone');
		this.selected = this.phone;
	}

	ngOnInit() {
		this.loginType = localStorage.getItem('type');
		this.language = localStorage.getItem('language');
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		// this.msgService.getPermission();
		// this.msgService.receiveMessage();
		// this.message = this.msgService.currentMessage;
		// console.log('mess', this.message);
		this.type = localStorage.getItem('type');
		// const popup = localStorage.getItem('popup');
		// if (!popup) {
		//     console.log('11111', popup);
		//     localStorage.setItem('popup', 'true');
		//     (<any>$('#happyNewYear')).modal('show');
		// }

		this.getNotification();
	}

	/**
	 * Prevent translate
	 * @param keyword
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
	getNotification() {
		this.adminService.getBageNotification(this.phone).subscribe(data => {
			// console.log('data', data);
			this.notifications = data.notificationCount;
		});
	}

	addExpandClass(element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
	}

	eventCalled() {
		this.isActive = !this.isActive;
	}

	/**
	 * Sign out
	 */
	signOut() {
		this.authService.signOut();
		location.reload();
		localStorage.removeItem('phone');
		localStorage.removeItem('main-phone');
		localStorage.removeItem('isLoggedin');
		localStorage.removeItem('phoneNumber');
		localStorage.removeItem('amount');
		localStorage.removeItem('socialRegister');
		localStorage.removeItem('socialId');
		localStorage.removeItem('socialType');
		localStorage.removeItem('allTotal');
		localStorage.removeItem('cart');
		localStorage.removeItem('orderDevices');
		localStorage.removeItem('orderScratchCards');
		localStorage.removeItem('orderSimCards');
		localStorage.removeItem('custName');
		localStorage.removeItem('popup');
		localStorage.removeItem('photoLocal1');
		// localStorage.removeItem('hasPromotion');
		localStorage.removeItem('hasUncompletedOrders');
		localStorage.removeItem('orderIds');
		localStorage.removeItem('top-up-price');
		localStorage.removeItem('add-on-amount');
		localStorage.removeItem('add-on-internet');
		localStorage.removeItem('pageUrl');
		localStorage.removeItem('itemsimCardPrice');
		localStorage.removeItem('useGaugeText');
		localStorage.removeItem('promotionPopUp');
		localStorage.removeItem('showUnlimitedVoiceAndSMS');
		localStorage.removeItem('showBalance');
		localStorage.removeItem('useGaugeText');
		localStorage.removeItem('orderTouristPass');
		localStorage.removeItem('deviceForRent');
		localStorage.removeItem('shopId');
		localStorage.removeItem('orderServiceType');
		localStorage.removeItem('getDetailInfo');
		localStorage.removeItem('hasPhoneNumber');
		localStorage.removeItem('hasCode');
		localStorage.removeItem('hasProfile');
		localStorage.removeItem('simType');
		localStorage.removeItem('cartLists');
		localStorage.removeItem('simCardList');
		localStorage.removeItem('cartDeviceitems');
		localStorage.removeItem('buyScratchCards');
		localStorage.removeItem('buySimCards');
		this.router.navigate(['/']);
	}
}
