import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsServicesService } from '../../../service/products-services.service';

@Component({
    selector: 'app-value-pack',
    templateUrl: './value-pack.component.html',
    styleUrls: ['./value-pack.component.scss']
})
export class ValuePackComponent implements OnInit {
    keywords: any;
    language: string;
    valuePack: any = {};
    valuePackSection1: any = {};
    valuePackSection2: any = {};
    valuePackSection3: any = {};
    description: any;
    isLoggedin: string;
    phone: string;
    fee: string;
    data: any;
    validity: string;
    type: string;
    loading: string;
    valuePacks: any;
    errorMsg: string;
    amount: any;
    getId: any = {};
    topUpPrice: number;
    valueTable: any = {};

    constructor(
        private productService: ProductsServicesService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.language = localStorage.getItem('language');
        this.isLoggedin = localStorage.getItem('isLoggedin');
        this.phone = localStorage.getItem('phone');
        this.topUpPrice = Number(localStorage.getItem('top-up-price'));
        this.getValuePack();
        this.getParamId();
    }
    getValuePack() {
        this.productService.getValuePack(this.language).subscribe((data) => {
            this.valuePack = data;
            console.log(data);
            this.valuePackSection1 = this.valuePack.section1;
            this.valuePackSection2 = this.valuePack.section2;
            this.valueTable = this.valuePackSection2.table;
            this.valuePackSection3 = this.valuePack.section3;
            this.description = this.valuePackSection3.description.replace(/- /g, ' ');
            $('.one-plan-bg').css('background-image', `url(${this.valuePackSection3.descriptionImageUrl})`);
        });
    }
    getParamId() {
        this.route.queryParams.subscribe((params) => {
            const el = document.getElementById('valuePack');
            this.getId = params['id'];
            if (this.getId === 'select-value') {
                el.scrollIntoView();
            }
            // if (this.getId === 'buy-more') {
            // 	el.scrollIntoView();
            // }
        });
    }

    activateValuePack(fee, data, validity) {
        if (!this.phone || !this.isLoggedin) {
            (<any>$('#preventLogin')).modal('show');
        } else {
            (<any>$('#exampleModalCenter2')).modal('show');
        }

        this.fee = String(fee);
        this.data = data;
        this.validity = String(validity);
    }

    /**
     * Select adds-on for buy new sim
     * @param amount
     * @param internet
     */
    selectValuePack(fee, data, validity) {
        localStorage.setItem('add-on-fee', fee);
        localStorage.setItem('add-on-data', data);
        localStorage.setItem('add-on-validity', validity);
        this.router.navigate(['/e-hub/buy-sim-fill-info']);
    }
    /**
     * Submit buy internet
     */
    onSubmitActivate() {
        this.loading = 'loading';
        this.type = 'value-pack';
        this.phone = localStorage.getItem('phone');
        this.productService
            .buyData({ phoneNumber: this.phone, amount: +this.fee.replace('$', ''), type: this.type })
            .subscribe(
                (data) => {
                    this.valuePacks = data;
                    localStorage.setItem('packType', this.type);
                    localStorage.setItem('fee', this.fee);
                    localStorage.setItem('data', this.data);
                    localStorage.setItem('phone', this.phone);
                    this.loading = '';
                    this.router.navigate(['/success']);
                },
                (err) => {
                    this.loading = '';
                    this.errorMsg = err.error.message;
                    (<any>$('#errorMsg')).modal('show');
                }
            );
    }
    /**
     * Prevent translate
     * @param keywords
     */
    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }
}
