import { Component, OnInit } from '@angular/core';
import { HomePageService } from '../../service/home-page.service';

@Component({
	selector: 'app-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
	TermAndConditions: any;
	constructor(private homePageService: HomePageService) { }

	ngOnInit() {
		// this.getTermAndConditions();
	}
	// getTermAndConditions() {
	// 	this.homePageService.getTermAndConditions().subscribe(data => {
	// 		this.TermAndConditions = data;
	// 		console.log('data', data);
	// 	});
	// }
}
