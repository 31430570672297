import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import * as $ from 'jquery';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterServiceService } from '../../service/register-service.service';
import { LoginServiceService } from '../../service/login-service.service';
import {
	SocialUser,
	AuthService,
	FacebookLoginProvider
} from 'angularx-social-login';
import { environment } from '../../../environments/environment';
import { AdminService } from '../../service/admin.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	frmRegister: FormGroup;
	sumitted = false;
	sumittedCode = false;
	misCfmPwd = '';
	typeRegister: string;
	errorMsg: string;
	cfmCodeError: string;
	code: string;
	phoneNumber: string;
	language: string;
	public keywords;

	emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
	phonePattern = /^\d+$/;

	accessToken = null;
	client_id = '6d25027f54164aaf805b64d4e8777186';
	client_secret = 'c6bf6f2361b54f59b64dd2228c6273b4';
	redirect_uri = environment.redirectUrl + '/';
	socialType: string;
	checkSocial: string;
	private user: SocialUser;
	private loggedIn: boolean;
	pageUrl: string;

	constructor(
		private registerService: RegisterServiceService,
		private router: Router,
		private loginService: LoginServiceService,
		private authService: AuthService,
		private profileService: AdminService
	) { }

	ngOnInit() {
		this.checkSocial = localStorage.getItem('socialType');
		this.language = localStorage.getItem('language');
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.phoneNumber = localStorage.getItem('phone');
		this.pageUrl = localStorage.getItem('pageUrl');
		if (localStorage.getItem('type') === 'off-net' && this.phoneNumber && !this.pageUrl) {
			this.router.navigate(['/user/profile']);
		} else if (this.phoneNumber && !this.pageUrl) {
			this.router.navigate(['/user/dashboard']);
		} else if (this.phoneNumber && this.pageUrl) {
			this.router.navigate([this.pageUrl]);
		}


		this.frmRegister = new FormGroup({
			phoneNumber: new FormControl('', [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			email: new FormControl('', Validators.pattern(this.emailPattern)),
			password: new FormControl('', Validators.required),
			confirmPassword: new FormControl('', [
				Validators.required,
				Validators.pattern(this.misCfmPwd)
			])
		});
	}

	/**
	 *  Translate
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}

	/**
	 *  Validation form register
	 */
	get frmValidate() {
		return this.frmRegister.controls;
	}

	/**
	 *  Validation confirm password
	 */
	misCfmPwdPattern() {
		if (
			this.frmRegister.get('password').value !==
			this.frmRegister.get('confirmPassword').value
		) {
			this.misCfmPwd = 'true';
		} else {
			this.misCfmPwd = '';
		}
	}

	/**
	 * Sign In with Facebook
	 */
	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
		this.authService.authState.subscribe(user => {
			this.user = user;
			this.loggedIn = user != null;

			if (this.user) {
				localStorage.setItem('socialRegister', 'social-register');
				localStorage.setItem('socialId', this.user.id);
				localStorage.setItem('socialType', 'facebook');
				this.checkSocial = localStorage.getItem('socialType');
			}

		});
	}

	/**
	 * Sign In with Instagram
	 */
	signInWithInstagram() {
		const popupWidth = 600,
			popupHeight = 700,
			popupLeft = (window.screen.width - popupWidth) / 2,
			popupTop = (window.screen.height - popupHeight) / 2;
		const popup = window.open(
			'https://api.instagram.com/oauth/authorize/?client_id=' +
			this.client_id +
			'&redirect_uri=' +
			this.redirect_uri +
			'&response_type=token',
			'',
			'width=' +
			popupWidth +
			',height=' +
			popupHeight +
			',left=' +
			popupLeft +
			',top=' +
			popupTop +
			''
		);
		const interval = setInterval(() => {
			try {
				if (popup.location.hash.length) {
					clearInterval(interval);
					this.accessToken = popup.location.hash.slice(14);
					this.loginService.instagramLogin(this.accessToken).subscribe(res => {
						let data;
						data = res;
						this.user = data.data;
						if (this.user) {
							localStorage.setItem('socialRegister', 'social-register');
							localStorage.setItem('socialId', this.user.id);
							localStorage.setItem('socialType', 'instagram');
							this.checkSocial = localStorage.getItem('socialType');
						}
					});
					popup.close();
				}
			} catch (evt) { }
			if (popup.closed) {
				clearInterval(interval);
			}
		}, 100);

	}

	/**
	 *  Submit form register
	 */
	onSubmit() {
		this.sumitted = true;

		this.typeRegister = localStorage.getItem('socialRegister');

		if (!this.typeRegister) {
			this.typeRegister = 'register';
		}

		if (this.frmRegister.invalid) {
			return;
		}

		const registerBody = {
			phoneNumber: this.frmRegister.get('phoneNumber').value,
			email: this.frmRegister.get('email').value,
			type: this.typeRegister
		};
		this.registerService.requestCode(registerBody).subscribe(
			res => {
				$('#confirm-load').trigger('click');
				this.code = '';
				this.cfmCodeError = '';
				this.sumittedCode = false;
			},
			err => {
				this.errorMsg = err.error.message;
			}
		);

	}

	/**
	 *  Check if agree term
	 */
	checkCondition() {
		if ($('#term').prop('checked') === true) {
			$('#btn-register').prop('disabled', false);
		} else {
			$('#btn-register').prop('disabled', true);
		}
	}

	/**
	 *  Submit confirm code
	 */
	confirmCode() {
		this.sumittedCode = true;
		if (this.code === '') {
			return;
		}
		const pageUrl = localStorage.getItem('pageUrl');
		this.typeRegister = '';
		this.typeRegister = localStorage.getItem('socialRegister');

		let body: any;
		if (!this.typeRegister) {
			this.typeRegister = 'register';
			body = {
				code: this.code,
				phoneNumber: this.frmRegister.get('phoneNumber').value,
				email: this.frmRegister.get('email').value,
				password: this.frmRegister.get('password').value,
				action: this.typeRegister,
				platform: 'web'
			};
		} else {
			body = {
				code: this.code,
				phoneNumber: this.frmRegister.get('phoneNumber').value,
				email: this.frmRegister.get('email').value,
				password: this.frmRegister.get('password').value,
				socialId: localStorage.getItem('socialId'),
				socialType: localStorage.getItem('socialType'),
				action: this.typeRegister,
				platform: 'web'
			};
		}

		this.registerService.confirmCode(body).subscribe(
			res => {
				this.profileService
					.getProfile(this.frmRegister.get('phoneNumber').value)
					.subscribe(resp => {
						let data: any;
						data = resp;

						localStorage.removeItem('socialRegister');
						localStorage.removeItem('socialId');
						localStorage.removeItem('socialType');

						const expires = 2 * 60 * 60;
						const now = Date.now();
						const schedule = now + expires * 1000;
						localStorage.setItem('isLoggedinExpire', String(schedule));

						localStorage.setItem('type', data.type);
						localStorage.setItem('isLoggedin', 'true');
						localStorage.setItem(
							'main-phone',
							this.toLocalPhoneNumber(this.frmRegister.get('phoneNumber').value)
						);
						localStorage.setItem(
							'phone',
							this.toLocalPhoneNumber(this.frmRegister.get('phoneNumber').value)
						);
						$('#confirm-load-close').trigger('click');

						// if (data.type === 'off-net') {
						// 	this.router.navigate(['/user/profile']);
						// } else if (pageUrl) {
						// 	this.router.navigate([pageUrl]);
						// } else {
						// 	this.router.navigate(['/user/dashboard']);
						// }
						location.reload();
					});
			},
			err => {
				this.cfmCodeError = err.error.message;
			}
		);
	}

	/**
	 * 855189494385 => 0189494385
	 * @param mSISDN phone number
	 */
	toLocalPhoneNumber(mSISDN: string) {
		if (typeof mSISDN === 'string') {
			mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
			mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
		}
		return mSISDN;
	}
}
