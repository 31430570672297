import { Component, OnInit } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';
import { HomePageService } from '../../../service/home-page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { concat } from 'rxjs/operator/concat';
import { IOption } from 'ng-select';

@Component({
    selector: 'app-internet-pack',
    templateUrl: './internet-pack.component.html',
    styleUrls: ['./internet-pack.component.scss']
})
export class InternetPackageComponent implements OnInit {
    valuePack: any = {};
    dataInternetPack1: any = {};
    dataInternetPack2: any = {};
    dataInternetPack3: any = {};
    getId: any = {};
    isLoggedin: string;
    phone: string;
    fee: string;
    data: any;
    validity: string;
    type: string;
    loading: string;
    dataPacks: any;
    errorMsg: string;

    language: string;
    public keywords;

    constructor(
        private productService: ProductsServicesService,
        private homePageService: HomePageService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    preventTranslate(keyword) {
        if (this.keywords) {
            if (this.keywords[keyword]) {
                return this.keywords[keyword][this.language];
            }
        }
        return keyword;
    }

    ngOnInit() {
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.language = localStorage.getItem('language');
        this.isLoggedin = localStorage.getItem('isLoggedin');
        this.phone = localStorage.getItem('phone');

        this.getInternetPack();
        this.getParamId();
    }

    getInternetPack() {
        this.productService.getDataPack(this.language).subscribe((res) => {
            this.valuePack = res;
            this.dataInternetPack1 = this.valuePack.section1;
            this.dataInternetPack2 = this.valuePack.section2;
            this.dataInternetPack3 = this.valuePack.section3;
            console.log('view pack', this.valuePack.section3);
        });
    }

    getParamId() {
        this.route.queryParams.subscribe((params) => {
            const el = document.getElementById('internet-pack');
            this.getId = params['id'];
            if (this.getId === 'internetpackage') {
                el.scrollIntoView();
            }

            // if (this.getId === 'buy-more') {
            // 	el.scrollIntoView();
            // }
        });
    }
    activateDataPack(fee, data, validity) {
        if (!this.phone || !this.isLoggedin) {
            (<any>$('#preventLogin')).modal('show');
        } else {
            (<any>$('#exampleModalCenter2')).modal('show');
        }

        this.fee = String(fee);
        this.data = data;
        this.validity = String(validity);
    }

    termCondition() {
        $('#text').html(this.dataInternetPack3.description);
        (<any>$('#termConditionModal')).modal('show');
    }

    onSubmitActivate() {
        this.loading = 'loading';
        this.type = 'internet-pack';
        this.phone = localStorage.getItem('phone');
        this.productService
            .buyData({
                phoneNumber: this.phone,
                amount: +this.fee.replace('$', ''),
                type: this.type
            })
            .subscribe(
                (data) => {
                    this.dataPacks = data;
                    localStorage.setItem('packType', this.type);
                    localStorage.setItem('fee', this.fee);
                    localStorage.setItem('data', this.data);
                    localStorage.setItem('phone', this.phone);
                    this.loading = '';
                    this.router.navigate(['/success']);
                },
                (err) => {
                    this.loading = '';
                    this.errorMsg = err.error.message;
                    (<any>$('#errorMsg')).modal('show');
                }
            );
    }
}
