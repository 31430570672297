import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessPlanComponent } from './business-plan/business-plan.component';
import { BusinessRoutingModule } from './business-routing.module';
import { PlanComponent } from './plan/plan.component';
import { BusinessComponent } from './business.component';

import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        BusinessRoutingModule,
        ReactiveFormsModule
    ],
    declarations: [
        BusinessComponent,
        BusinessPlanComponent,
        PlanComponent,

    ]
})
export class BusinessModule { }
