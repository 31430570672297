import { Component, OnInit, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharingService } from '../../service/data-sharing.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    gaugeType = 'arch';
    gaugeLabel = 'Balance Left';
    gaugeCap = 'butt';
    gaugeThick = 20;
    gaugeSize = 240;
    gaugeBgColor = '#bcbec0';

    gaugeAppend = 'MB';
    gaugeTwoColor = '#ec008c';
    gaugeTwoLabel = 'Internet Left';
    gaugeAppendCur = '$';

    gaugeTwoValue = 0;
    gaugeTwoMax = 100;

    showMax: number;
    showAppend = 'MB';
    phone: string;
    phoneUnlink: string;
    mainPhone: string;
    selected: string;
    msgErr: string;
    data: string;
    main: any = {};
    profile: any = {};
    profiles: any = [];

    submitted = false;
    submittedCode = false;

    frmPhoneLink: FormGroup;
    frmCode: FormGroup;
    phonePattern = /^\d+$/;
    language: string;
    loading: string;
    public keywords;
    private fragment: string;
    hasUncompletedOrders: string;
    orderIds: any = [];
    useGaugeText: string;
    getTouristSim: any;
    promotionYesIfa: any;
    promotions: any;
    getYesIfa: any;
    useValue: any;

    constructor(
        private adminService: AdminService,
        private router: Router,
        private dataService: DataSharingService,
        private myElement: ElementRef
    ) {
        this.phone = localStorage.getItem('phone');
        this.mainPhone = localStorage.getItem('main-phone');
        this.selected = this.phone;
    }

    ngOnInit() {
        if (localStorage.getItem('type') === 'off-net') {
            this.router.navigate(['/user/profile']);
        }
        this.language = localStorage.getItem('language') || 'en';
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        localStorage.removeItem('packType');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('amount');
        localStorage.removeItem('socialRegister');
        localStorage.removeItem('fee');
        localStorage.removeItem('data');
        this.getProfiles();

        this.frmPhoneLink = new FormGroup({
            newPhoneNumber: new FormControl('', [
                Validators.required,
                Validators.pattern(this.phonePattern),
                Validators.minLength(9),
                Validators.maxLength(15)
            ])
        });

        this.frmCode = new FormGroup({
            code: new FormControl('', Validators.required)
        });
    }

    /**
     * Prevent Translation
     */
    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }

    gotoInternetPack(event): void {
        if (this.useValue === 'true') {
            this.router.navigate(['/add-ons/valuepack'], {
                queryParams: { id: event.target.id }
            });
        } else {
            this.router.navigate(['/personal/add-ons'], {
                queryParams: { id: event.target.id }
            });
            localStorage.setItem('plan', this.main.subsPlanCode);
            localStorage.setItem('simType', this.main.type);
        }
    }

    /**
     * Control validation on add link form
     */
    get f() {
        return this.frmPhoneLink.controls;
    }

    /**
     * Control validation on confirm code form
     */
    get c() {
        return this.frmCode.controls;
    }

    /**
     * Switch phone number or add link phone
     */
    changePhone(phone) {
        if (phone === 'add') {
            (<any>$('#addPhoneLink')).modal('show');

            this.selected = this.phone;
            this.msgErr = '';

            this.frmCode.reset();
            this.frmPhoneLink.reset();
            this.submitted = false;
            this.submittedCode = false;

            return;
        }

        if (this.selected === phone) {
            return;
        }
        this.selected = phone;

        this.gaugeTwoValue = 0;
        this.gaugeTwoMax = 100;
        this.phone = phone;
        localStorage.setItem('phone', this.phone);
        this.getMain();
    }

    /**
     * Request code for add link phone number
     */
    addLinkPhone() {
        this.submitted = true;

        if (this.frmPhoneLink.invalid) {
            return;
        }

        const data = {
            phoneNumber: this.mainPhone,
            newPhoneNumber: this.frmPhoneLink.get('newPhoneNumber').value,
            type: 'link-phone-number'
        };

        this.adminService.requestCode(data).subscribe(
            (res) => {
                (<any>$('#addPhoneLink')).modal('hide');
                this.msgErr = '';

                setTimeout(() => {
                    (<any>$('#confirmCode')).modal('show');
                }, 500);
            },
            (err) => {
                this.msgErr = err.error.message;
            }
        );
    }

    /**
     * Call modal remove phone number
     */
    confirm(phone) {
        this.phoneUnlink = phone;
        (<any>$('#removePhone')).modal('show');
    }

    /**
     * submit remove phone number
     */
    unLinkPhone() {
        this.adminService.unlinkPhoneNumber(this.mainPhone, this.phoneUnlink).subscribe(
            (res) => {
                this.getProfiles();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * Submit and confirm code for add link phone number
     */
    confirmCode() {
        this.submittedCode = true;

        if (this.frmCode.invalid) {
            return;
        }

        const data = {
            code: this.frmCode.get('code').value,
            phoneNumber: this.mainPhone,
            newPhoneNumber: this.frmPhoneLink.get('newPhoneNumber').value,
            action: 'link-phone-number'
        };

        this.adminService.confirmCode(data).subscribe(
            (res) => {
                localStorage.setItem('phone', this.frmPhoneLink.get('newPhoneNumber').value);
                this.phone = localStorage.getItem('phone');
                this.selected = this.phone;
                this.getMain();
                this.getProfiles();
                setTimeout(() => {
                    (<any>$('#confirmCode')).modal('hide');
                }, 500);
            },
            (err) => {
                this.msgErr = 'Your code is not match.';
            }
        );
    }

    /**
     * Get phone number and all link phone number
     */
    getProfiles() {
        this.adminService.getProfiles(this.mainPhone).subscribe(
            (res) => {
                this.profiles = res;
                if (this.phone === this.phoneUnlink) {
                    localStorage.setItem('phone', this.mainPhone);
                    this.phone = this.mainPhone;
                    this.selected = this.mainPhone;
                }
                this.getMain();
            },
            (err) => {
                console.log(err);
            }
        );
    }
    goToSuccess() {
        this.adminService.getMain(this.phone).subscribe((res) => {
            this.main = res;
            localStorage.setItem('orderIds', this.main.orderIds);
            this.orderIds = localStorage.getItem('orderIds');
            this.router.navigate(['/cart/success']);
        });
    }
    /**
     * Get Dashboard
     */
    getMain() {
        this.loading = 'loading';
        this.adminService.getMain(this.phone).subscribe((res) => {
            this.dataService.updateBadgeCount();
            this.main = res;
            // console.log("this.main");
            // console.log(this.main);
            this.promotions = this.main.promotions;
            this.promotionYesIfa = this.promotions.find((c) => c.code === 'yes-ifa');
            if (this.promotionYesIfa && this.promotionYesIfa.code === 'yes-ifa') {
                const promotionPopUp = localStorage.getItem('promotionPopUp');
                if (!promotionPopUp) {
                    (<any>$('#promotionYesIfa')).modal('show');
                    localStorage.setItem('promotionPopUp', 'true');
                }
            }

            localStorage.setItem('custName', this.main.custName);
            localStorage.setItem('hasUncompletedOrders', this.main.hasUncompletedOrders);
            localStorage.setItem('cardPrice', this.main.cardPrice);
            localStorage.setItem('useGaugeText', this.main.useGaugeText);
            localStorage.setItem('useValue', this.main.useValue);
            this.useValue = localStorage.getItem('useValue');
            this.useGaugeText = localStorage.getItem('useGaugeText');
            if (this.useGaugeText === 'true') {
                this.getTouristSim = this.main.mainGaugeText;
            }

            if (this.main.remainingData >= 1024) {
                let remainingDataInGB: any;
                let remainingDataInTB: any;
                let totalDataGB: any;
                let totalDataTB: any;
                remainingDataInGB = this.main.remainingData / 1024;
                totalDataGB = this.main.totalData / 1024;

                if (remainingDataInGB >= 1024) {
                    remainingDataInTB = remainingDataInGB / 1024;
                    totalDataTB = totalDataGB / 1024;
                    setTimeout(() => {
                        this.gaugeTwoValue = remainingDataInTB.toFixed(1);
                    }, 2000);
                    this.gaugeTwoMax = totalDataTB.toFixed(1);
                    this.gaugeAppend = 'TB';
                } else {
                    setTimeout(() => {
                        this.gaugeTwoValue = remainingDataInGB.toFixed(1);
                    }, 2000);
                    this.gaugeTwoMax = totalDataGB.toFixed(1);
                    this.gaugeAppend = 'GB';
                }
            } else {
                setTimeout(() => {
                    this.gaugeTwoValue = this.main.remainingData;
                }, 2000);
                this.gaugeTwoMax = this.main.totalData;
                this.gaugeAppend = 'MB';
            }

            this.gaugeTwoMax > 0 ? (this.gaugeTwoMax = this.gaugeTwoMax) : (this.gaugeTwoMax = 100);

            if (this.main.totalData >= 1024) {
                let totalDataGB: any;
                let totalDataTB: any;
                totalDataGB = this.main.totalData / 1024;

                if (totalDataGB >= 1024) {
                    totalDataTB = totalDataGB / 1024;
                    this.showMax = totalDataTB.toFixed(1);
                    this.showAppend = 'TB';
                } else {
                    this.showMax = totalDataGB.toFixed(1);
                    this.showAppend = 'GB';
                }
            } else {
                this.showMax = this.main.totalData;
                this.showAppend = 'MB';
            }

            this.hasUncompletedOrders = localStorage.getItem('hasUncompletedOrders');
            if (this.hasUncompletedOrders === 'true') {
                (<any>$('#hasUncompletedOrders')).modal('show');
            }
            this.loading = '';
        });
    }
}
