import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { GlobleService } from './globle.service';

@Injectable()
export class FindShopService {
	rootUrl: string = this.globalService.apiUrl;
	header: any = this.globalService.getHeader();

	constructor(private http: HttpClient, private globalService: GlobleService) { }

	/**
	 * Get all shop by limit and filter by type
	 * @param languege
	 * @param limit
	 * @param type
	 */
	getShops(languege, limit, type, ehub): Observable<any> {
		return this.http.get(
			`${
			this.rootUrl
			}/shops?lang=${languege}&limit=${limit}&list=web&type=${type}&ehub=${ehub}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get shops by location and filter by search and type
	 * @param languege
	 * @param offset
	 * @param search
	 * @param type
	 */
	getMoreBylocation(languege, offset, search, type) {
		return this.http.get(
			`${
			this.rootUrl
			}/shops?lang=${languege}&searchByLocation=${search}&offset=${offset}&type=${type}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get all shop by search
	 * @param languege
	 * @param search
	 */
	getAllShops(languege, search): Observable<any> {
		return this.http.get(
			`${
			this.rootUrl
			}/shops?lang=${languege}&list=web&searchByLocation=${search}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get all shop by type self-run
	 * @param languege
	 * @param search
	 */
	getSelfRunShops(languege, search): Observable<any> {
		return this.http.get(
			`${
			this.rootUrl
			}/shops?lang=${languege}&searchByLocation=${search}&type=self-run&limit=30`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get shop by search
	 * @param languege
	 * @param input
	 */
	getSearchShop(languege, input): Observable<any> {
		return this.http.get(
			`${this.rootUrl}/shops?search=${input}&lang=${languege}`,
			{
				headers: this.header
			}
		);
	}
	/**
	 * Get shop by search
	 * @param languege
	 * @param input
	 */
	getSearchNameShop(languege): Observable<any> {
		return this.http.get(
			`${this.rootUrl}/shops?airport=true&lang=${languege}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Submit feedback support page
	 * @param feedback
	 */
	feedback(feedback) {
		const postFeedback = '/feedback';
		return this.http.post(this.rootUrl + postFeedback, feedback, {
			headers: this.header
		});
	}
	// get Support page
	getSupport(language): Observable<any> {
		return this.http
			.get(`${this.rootUrl}/pages/support/contact-us?lang=${language}`, {
				headers: this.header
			});

	}
}
