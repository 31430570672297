import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "minuteSeconds"
})
export class MinuteSecondsPipe implements PipeTransform {
	transform(secs: number): string {
		if (secs >= 60) {
			const minFloat = secs / 60;
			const min = Math.ceil(minFloat);
			return min + "";
		}
		return secs + "";
	}
}

@Pipe({
	name: "utcDate"
})
export class UtcDatePipe implements PipeTransform {
	transform(value: string): any {
		if (!value) {
			return "";
		}

		const dateValue = new Date(value);

		const dateWithNoTimezone = new Date(
			dateValue.getUTCFullYear(),
			dateValue.getUTCMonth(),
			dateValue.getUTCDate(),
			dateValue.getUTCHours() + 7,
			dateValue.getUTCMinutes(),
			dateValue.getUTCSeconds()
		);

		return dateWithNoTimezone;
	}
}

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({ name: "fileSize" })
export class FileSizePipe implements PipeTransform {
	private units = ["MB", "GB", "TB"];

	transform(bytes: number = 0, precision: number = 2): string {
		if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
			return "?";
		}

		let unit = 0;

		while (bytes >= 1024) {
			bytes /= 1024;
			unit++;
		}

		return `${bytes.toFixed(+precision)}  ${this.units[unit]} `;
	}
}
@Pipe({
	name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
	transform(value: number): string {
		const hours: number = Math.floor(value / 3600);
		const minutes: number = Math.floor((value % 3600) / 60);
		return (
			("0" + minutes).slice(-2) +
			":" +
			("00" + Math.floor(value - minutes * 60)).slice(-2)
		);
	}
}
