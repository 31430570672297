import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';

import { DashboardComponent } from '../../../admin/dashboard/dashboard.component';
import { AdminService } from '../../../service/admin.service';
import { GlobleService } from '../../../service/globle.service';
import { ProductsServicesService } from '../../../service/products-services.service';

@Component({
    selector: 'app-data-pack',
    templateUrl: './data-pack.component.html',
    styleUrls: ['./data-pack.component.scss']
})
export class DataPackComponent implements OnInit {
    @ViewChild(DashboardComponent, { static: true }) child: DashboardComponent;
    language: string;
    description: string;
    desktopImage: string;
    mobileImage: string;
    keywords = {};

    dataPack: any = [];
    sectionTwo: any = {};
    daily: any = [];
    weekly: any = [];
    monthly: any = [];

    sectionOne: any;
    sectionThree: any;

    dailyPriceFeeOne: number;
    dailyPriceFeeTwo: number;
    weeklyPriceFeeOne: number;
    weeklyPriceFeeTwo: number;
    monthlyPriceFeeOne: number;
    monthlyPriceFeeTwo: number;

    dailyFeeOne: any;
    dailyFeeTwo: any;
    weeklyFeeOne: any;
    weeklyFeeTwo: any;
    monthlyFeeOne: any;
    monthlyFeeTwo: any;

    dailyDataOne: any;
    dailyDataTwo: any;
    weeklyDataOne: any;
    weeklyDataTwo: any;
    monthlyDataOne: any;
    monthlyDataTwo: any;

    dailyActiveOne: any;
    dailyActiveTwo: any;
    weeklyActiveOne: any;
    weeklyActiveTwo: any;
    monthlyActiveOne: any;
    monthlyActiveTwo: any;

    dailyValidityOne: any;
    dailyValidityTwo: any;
    weeklyValidityOne: any;
    weeklyValidityTwo: any;
    monthlyValidityOne: any;
    monthlyValidityTwo: any;
    phone: string;
    plan: string;
    profile: any = {};
    type: string;
    getAmount: string;
    errorMsg: string;
    succeedMsg: string;
    isLoggedin: string;
    getId: any = {};
    topUpPrice: number;
    newIcon = 'assets/new-en.png';
    fee: string;
    internet: string;
    amount: string;
    buyMore: string;
    loading: string;
    checkSimType: string;

    // tslint:disable-next-line:no-inferrable-types
    public fixed: boolean = false;
    internetPlans: any;
    getType: any;
    constructor(
        private productService: ProductsServicesService,
        private api: GlobleService,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.scrollY > 450) {
            this.fixed = true;
        } else if (this.fixed && window.scrollY < 500) {
            this.fixed = false;
        }
    }
    ngOnInit() {
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        this.language = localStorage.getItem('language');
        this.checkSimType = localStorage.getItem('type');
        this.getInternetPlans();
        this.getType = localStorage.getItem('simType');
        if (this.checkSimType !== 'off-net') {
            this.internetPlans = localStorage.getItem('internetPlans');
        }
        switch (this.language) {
            case 'km':
                this.newIcon = 'assets/new-kh.png';
                break;
            case 'zh':
                this.newIcon = 'assets/new-en.png';
                break;
            default:
                this.newIcon = 'assets/new-en.png';
        }

        this.plan = localStorage.getItem('plan');
        this.phone = localStorage.getItem('phone');
        this.isLoggedin = localStorage.getItem('isLoggedin');
        this.topUpPrice = Number(localStorage.getItem('top-up-price'));

        this.getDataPack();
        this.getParamId();
    }
    getParamId() {
        this.route.queryParams.subscribe((params) => {
            const el = document.getElementById('InternetPack');
            this.getId = params['id'];

            if (this.getId === 'select-internet') {
                el.scrollIntoView();
            }
            if (this.getId === 'buy-more') {
                el.scrollIntoView();
            }
        });
    }

    /**
     * Check internet price
     * @param price
     */
    checkInternetPrice(price) {
        let getPrice: number;
        if (price.includes('$')) {
            getPrice = Number(price.replace('$', ''));
        } else {
            const str = Number(price.replace(/[^0-9.]/g, ''));
            getPrice = str / 100;
        }
        return getPrice;
    }

    getInternetPlans() {
        this.phone = localStorage.getItem('phone');
        this.productService.getDataPackInternetPlan(this.phone).subscribe((data) => {
            this.internetPlans = data;
            localStorage.setItem('internetPlans', this.internetPlans);
        });
    }

    /**
     * Show popup buy internet
     * @param fee
     * @param internet
     * @param amount
     */
    buyInternetPack(fee, internet, addOn) {
        if (!this.phone || !this.isLoggedin) {
            (<any>$('#preventLogin')).modal('show');
        } else {
            (<any>$('#exampleModalCenter2')).modal('show');
        }

        this.fee = fee;
        this.internet = internet;
        this.buyMore = addOn;
        this.amount = String(this.checkInternetPrice(fee));
    }
    /**
     * Submit buy internet
     */
    onSubmitBuy() {
        this.loading = 'loading';
        this.type = 'internet-pack';
        this.phone = localStorage.getItem('phone');
        const buyMoreFilter = this.buyMore ? { buyMore: this.buyMore } : {};

        this.productService
            .buyData({ phoneNumber: this.phone, amount: +this.amount, type: this.type, ...buyMoreFilter })
            .subscribe(
                (data) => {
                    this.dataPack = data;
                    this.loading = '';
                    localStorage.setItem('packType', this.type);
                    localStorage.setItem('data', this.dataPack);
                    this.router.navigate(['/success']);
                },
                (err) => {
                    this.loading = '';
                    this.errorMsg = err.error.message;
                    (<any>$('#errorMsg')).modal('show');
                }
            );
    }

    /**
     * Select adds-on for buy new sim
     * @param amount
     * @param internet
     */
    selectedAddOn(amount, internet, validity) {
        localStorage.setItem('add-on-amount', amount);
        localStorage.setItem('add-on-internet', internet);
        localStorage.setItem('add-on-validity', validity);
        this.router.navigate(['/e-hub/buy-sim-fill-info']);
    }

    /**
     * Get data table on internet pack with api
     */
    getDataPack() {
        let subsplan = 'internet-pack';
        switch (this.plan) {
            case 'VIPFortune_Plan_Subsplan':
                subsplan = 'vip-fortune-pack';
                break;
            case 'Fortune_Plan_Subsplan':
                subsplan = 'fortune-pack';
                break;
            case 'VIPOnePlan_Subsplan':
                subsplan = 'vip-one-plan';
                break;
            case 'PREMIER25':
                subsplan = 'internet-pack';
                break;
        }
        this.productService.getPlanPackage(this.language, subsplan).subscribe((res) => {
            this.dataPack = res;
            this.sectionOne = this.dataPack.section1;
            this.sectionTwo = this.dataPack.section2;
            this.sectionThree = this.dataPack.section3;
            this.description = this.sectionThree.description.replace(/- /g, ' ');
            this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
            this.mobileImage = `${this.sectionOne.mobileImageUrl}`;

            const table = _.chain(this.sectionTwo.table)
                .groupBy('internetPack')
                .map((value, key) => value)
                .value();

            this.daily = table[0];
            this.weekly = table[1];
            this.monthly = table[2];

            $('.one-plan-bg').css('background-image', `url(${this.sectionThree.descriptionImageUrl})`);
        });
        // this.productService.getDataPack(this.language).subscribe((res) => {
        // 		this.dataPack = res;
        // 		this.sectionOne = this.dataPack.section1;
        // 		this.sectionTwo = this.dataPack.section2;
        // 		this.sectionThree = this.dataPack.section3;
        // 		this.description = this.sectionThree.description.replace(/- /g, ' ');
        // 		this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
        // 		this.mobileImage = `${this.sectionOne.mobileImageUrl}`;

        // 		const table = _.chain(this.sectionTwo.table)
        // 				.groupBy('internetPack')
        // 				.map((value, key) => value)
        // 				.value();

        // 		this.daily = table[0];
        // 		this.weekly = table[1];
        // 		this.monthly = table[2];

        // 		$('.one-plan-bg').css('background-image', `url(${this.sectionThree.descriptionImageUrl})`);
        // });
    }

    /**
     * Prevent translate
     * @param keywords
     */
    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }
}
