import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-layout',
  templateUrl: './faq-layout.component.html',
  styleUrls: ['./faq-layout.component.scss']
})
export class FaqLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
