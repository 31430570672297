import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { BusinessComponent } from './business.component';
import { BusinessPlanComponent } from './business-plan/business-plan.component';
import { PlanComponent } from './plan/plan.component';

const routes: Routes = [
    {
        path: '',
        component: BusinessComponent,
        children: [
            { path: '', component: PlanComponent }
            // { path: 'plan', component: PlanComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), CommonModule],
    exports: [RouterModule],
    declarations: []
})
export class BusinessRoutingModule {}
