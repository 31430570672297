import { Component, OnInit, HostListener } from '@angular/core';
import * as $ from 'jquery';
import { HomePageService } from '../../../service/home-page.service';

@Component({
	selector: 'app-roaming',
	templateUrl: './roaming.component.html',
	styleUrls: ['./roaming.component.scss']
})
export class RoamingComponent implements OnInit {
	// originalData: Roaming[] = [];
	results: Roaming[] = [];
	countries: Country[] = [];
	roamingAsia: Country[] = [];
	roamingEurope: Country[] = [];
	roamingNorthAmerica: Country[] = [];
	roamingSouthhAmerica: Country[] = [];
	roamingOceanic: Country[] = [];
	roamingAfrica: Country[] = [];
	countryAsia = [];
	countryEurope = [];
	countryNorthAmerica = [];
	countrySouthAmerica = [];
	countryOceanic = [];
	countryAfrica = [];
	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	public selectedMobilePlan;
	public showButtons: any = {
		q1: false,
		q2: false,
		q3: false,
		q4: false,
		q5: false,
		q6: false
	};
	public showFull: any = {
		fullWidth1: false,
		fullWidth2: false
	};
	constructor(private homePageService: HomePageService) {}
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.getCountry();
	}

	getCountry() {
		this.homePageService.getJSON().subscribe((data: Roaming[]) => {
			// this.countries = data;
			this.roamingAsia = data.find(x => x.continent === 'Asia').countries;
			this.roamingEurope = data.find(x => x.continent === 'Europe').countries;
			this.roamingNorthAmerica = data.find(
				x => x.continent === 'NorthAmerica'
			).countries;
			this.roamingSouthhAmerica = data.find(
				x => x.continent === 'SouthhAmerica'
			).countries;
			this.roamingOceanic = data.find(x => x.continent === 'Oceanic').countries;
			this.roamingAfrica = data.find(x => x.continent === 'Africa').countries;
			this.results = data;
			this.countries = [...data.map(x => x.countries)] as any;
			this.countryAsia = this.results.find(
				x => x.continent === 'Asia'
			).countries;
			this.countryEurope = this.results.find(
				x => x.continent === 'Europe'
			).countries;
			this.countryNorthAmerica = this.results.find(
				x => x.continent === 'NorthAmerica'
			).countries;
			this.countrySouthAmerica = this.results.find(
				x => x.continent === 'SouthhAmerica'
			).countries;
		});
	}
	changeValue($event, continent, country) {
		this.countries = this.results.find(
			x => x.continent === continent
		).countries;
		if (country) {
			const temp = this.results.filter(x => x.continent === continent);
			const roaming = temp.map(x => ({
				continent: continent,
				countries: x.countries.filter(c => c.country === country)
			}));
			if (continent === 'Asia') {
				this.roamingAsia = roaming.find(x => x.continent === 'Asia').countries;
			}
			if (continent === 'Europe') {
				this.roamingEurope = roaming.find(
					x => x.continent === 'Europe'
				).countries;
			}
			if (continent === 'NorthAmerica') {
				this.roamingNorthAmerica = roaming.find(
					x => x.continent === 'NorthAmerica'
				).countries;
			}
			if (continent === 'SouthhAmerica') {
				this.roamingSouthhAmerica = roaming.find(
					x => x.continent === 'SouthhAmerica'
				).countries;
			}
			if (continent === 'Oceanic') {
				this.roamingOceanic = roaming.find(
					x => x.continent === 'Oceanic'
				).countries;
			}
			if (continent === 'Africa') {
				this.roamingAfrica = roaming.find(
					x => x.continent === 'Africa'
				).countries;
			}
		} else {
			this.roamingAsia = this.results.find(
				x => x.continent === 'Asia'
			).countries;
			this.roamingEurope = this.results.find(
				x => x.continent === 'Europe'
			).countries;
			this.roamingNorthAmerica = this.results.find(
				x => x.continent === 'NorthAmerica'
			).countries;
			this.roamingSouthhAmerica = this.results.find(
				x => x.continent === 'SouthhAmerica'
			).countries;
			this.roamingOceanic = this.results.find(
				x => x.continent === 'Oceanic'
			).countries;
			this.roamingAfrica = this.results.find(
				x => x.continent === 'Africa'
			).countries;
		}
	}

	toggleButton(e) {
		this.showButtons.q1 = false;
		this.showButtons.q2 = false;
		this.showButtons.q3 = false;
		this.showButtons.q4 = false;
		this.showButtons.q5 = false;
		this.showButtons.q6 = false;
		this.showButtons[e] = !this.showButtons[e];
		this.showFull[e] = !this.showFull[e];
		// this.showButtons[e].addClass('full-w');
		$('.add-main').addClass('new-templ');
		// $('.add-event').removeClass('col-md-4');
	}
	toggleclose(e) {
		this.showButtons[e] = !this.showButtons[e];
		this.showFull[e] = !this.showFull[e];
		$('.add-main').removeClass('new-templ');
	}
	gotoAsia() {
		const el = document.getElementById('Asia');
		window.scroll(500, 500);
	}
	gotoEurope() {
		const el = document.getElementById('Europe');
		window.scroll(500, 500);
	}
	gotoNorthAmerica() {
		const el = document.getElementById('NorthAmerica');
		window.scroll(500, 500);
	}
	gotoSouthAmerica() {
		const el = document.getElementById('Asia');
		window.scroll(500, 500);
	}
	gotoOceanic() {
		const el = document.getElementById('Europe');
		window.scroll(500, 500);
	}
	gotoAfrica() {
		const el = document.getElementById('NorthAmerica');
		window.scroll(500, 500);
	}
}

interface Country {
	country: string;
	Network_Partners: string[];
	International_Calls: string;
	Calls_to_Cambodia: string;
	Local_Calls: string;
	Outgoing_SMS: string;
	Receiving_calls: string;
	Internet_10KB: string;
	Setellite_Calls: string;
	postpaid_roaming: boolean;
	prepaid_roaming: boolean;
}

interface Roaming {
	continent: string;
	countries: Country[];
}
