import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { GlobleService } from './globle.service';

@Injectable()
export class BusinessService {
	rootUrl: string = this.globalService.apiUrl;
	header: any = this.globalService.getHeader();

	constructor(private http: HttpClient, private globalService: GlobleService) {}

	businessSubmit(data): Observable<any> {
		return this.http
			.post(`${this.rootUrl}/businesses`, data, {
				headers: this.header
			})
			.pipe(catchError(this.handleError));
	}
	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// return an ErrorObservable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}
}
