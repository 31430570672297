import { Component, OnInit, HostListener } from '@angular/core';
import { AboutPageService } from '../../../service/about-page.service';
declare var $: any;

@Component({
	selector: 'app-careers',
	templateUrl: './careers.component.html',
	styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
	public fixed: Boolean = false;
	language: string;
	keywords: string;
	languages: string;
	limits: number;
	offset: Number = 0;
	getPage: string;
	total: number;
	public getCareer: any;

	getCareerDetail: any = {};
	responsibilities = [];
	qualifications = [];
	careerBanner: any = [];
	constructor(private aboutPageService: AboutPageService) { }

	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 450) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.getCareers();
		this.getCareersBanner();
	}

	openModal(modalId, careerId) {
		this.responsibilities = this.qualifications = [];
		if (careerId) {
			this.aboutPageService
				.getOneCareer(careerId, this.language)
				.subscribe(data => {
					this.getCareerDetail = data;
					this.responsibilities = this.getCareerDetail.responsibilities
						.split('• ')
						.filter(v => v !== '');
					this.qualifications = this.getCareerDetail.qualifications
						.split('• ')
						.filter(v => v !== '');
				});
		}

		$(modalId).modal('show');
	}
	getCareers() {
		const url = '&limit=10' + '&offset=' + this.offset;
		this.aboutPageService.getCareers(this.language, url).subscribe(data => {
			this.getCareer = data;
		});
	}
	getCareersBanner() {
		this.aboutPageService.getCareersBanner(this.language).subscribe(data => {
			this.careerBanner = data.section1;
		});
	}
	pageChanged(p, offset, limit) {
		this.offset = limit * p - limit;
		const url = '&limit=10' + '&offset=' + this.offset;
		this.aboutPageService.getCareers(this.language, url).subscribe(data => {
			this.getCareer = data;
			const el = document.getElementById('goCareer');
			el.scrollIntoView({ behavior: 'smooth', block: 'start' });
		});
	}

	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}
}
