import { Component, OnInit, HostListener } from '@angular/core';
import { ProductsServicesService } from '../../../service/products-services.service';

@Component({
	selector: 'app-product-service',
	templateUrl: './product-service.component.html',
	styleUrls: ['./product-service.component.scss']
})
export class ProductServiceComponent implements OnInit {
	language: string;
	public keywords;
	products: any = [];
	header: any = {};
	onePlan: any = {};
	dataPack: any = {};
	freeData: any = {};
	callInternational: any = {};
	keyword = {
		oneplanto: {
			en: 'to',
			km: 'សម្រាប់ការតេ',
			zh: '免费与柬埔寨及全球15亿人通话，全免费，不限量！'
		},
		free: {
			en: 'free ',
			km: 'ឥតគិតលុយ',
			zh: ' '
		},
		unlimited: {
			en: 'unlimited ',
			km: 'ឥតកំណត់',
			zh: ' '
		},
		callsToBillionPeople: {
			en: 'calls to 1.5 billion people.',
			km: 'ទៅកាន់មនុស្ស 1.5 ពាន់លាននាក់។',
			zh: ' '
		},
		InternetFor: {
			en: 'Internet for',
			km: 'រយះពេល ',
			zh: ' '
		},
		InternetTo: {
			en: 'to',
			km: 'សម្រាប់អុីនធឺណិត ',
			zh: '购买新手机至'
		},
		InternetDayWhenYouBuyNewPhone: {
			en: 'days when you buy a new phone.',
			km: 'ថ្ងៃនៅពេលទិញទូរស័ព្ទថ្មី។ ',
			zh: '天 '
		},
		toCall: {
			en: 'to call',
			km: 'ហើយតេ ',
			zh: '随时随地打 '
		},
		anyone: {
			en: 'anyone',
			km: 'ទៅកាន់ ',
			zh: ' '
		},
		inTheWorld: {
			en: 'in the world',
			km: 'ពិភពលោក',
			zh: '电话给世界'
		},
		anytime: {
			en: 'anytime',
			km: 'គ្រប់ពេល',
			zh: ''
		},
		youLike: {
			en: 'you like.',
			km: 'អ្នកត្រូវការ ',
			zh: '上的任何人'
		}
	};

	constructor(private productService: ProductsServicesService) { }
	@HostListener('window:scroll', [])
	onWindowScroll() {
		// console.log(window.scrollY);
		if (window.scrollY > 5) {
			$('.banner-pro-serv').addClass('fixed');
		} else if (window.scrollY < 5) {
			$('.banner-pro-serv').removeClass('fixed');
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language') || 'en';
		this.getProducts();
	}
	preventTranslateLocal(keyword) {
		return this.keyword[keyword]
			? this.keyword[keyword][this.language]
			: keyword;
	}

	preventTranslate(keyword) {
		if (this.keywords) {
			if (this.keywords[keyword]) {
				return this.keywords[keyword][this.language];
			}
		}
		return keyword;
	}

	getProducts() {
		this.productService.getProducts(this.language).subscribe(res => {
			this.products = res;
			this.header = this.products.find(p => p.name === 'header');
			this.onePlan = this.products.find(p => p.name === 'oneplan');
			this.dataPack = this.products.find(p => p.name === 'datapack');
			this.freeData = this.products.find(p => p.name === 'freedata');
			this.callInternational = this.products.find(
				p => p.name === 'international-call'
			);

			$('.talk-free-unlimited').css(
				'background-image',
				`url(${this.onePlan.imageUrl})`
			);
			$('.data-pack').css(
				'background-image',
				`url(${this.dataPack.imageUrl})`
			);
			$('.free-data').css(
				'background-image',
				`url(${this.freeData.imageUrl})`
			);
			$('.call-international').css(
				'background-image',
				`url(${this.callInternational.imageUrl})`
			);
			// console.log('one plan', this.onePlan);
			// console.log('data pack', this.dataPack);
			// console.log('free data', this.freeData);
		});
	}
}
