import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ProductsServicesV2Service } from '../../../../service/products-services-v2.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { DashboardComponent } from '../../../../admin/dashboard/dashboard.component';

@Component({
	selector: 'app-one-plan',
	templateUrl: './loy-kob-page.component.html',
	styleUrls: ['./loy-kob-page.component.scss']
})
export class LoyKobPageComponent implements OnInit {
	description: string;
	desktopImage: string;
	mobileImage: string;
	desktopBottomImageUrl: string;
	mobileBottomImageUrl: string;
	active: string = 'Active';
	reactive: string = 'Reactive';

	image: any = {
		backgroundloykob: '/assets/product-services/loy-kob/background-loykob.png',
		loykobprice: '/assets/product-services/loy-kob/price.png',
		loykobcircle: '/assets/product-services/loy-kob/circle.png'
	};

	@ViewChild(DashboardComponent, { static: true }) child: DashboardComponent;
	language: string;
	keywords = {};

	dataPack: any = [];
	sectionTwo: any = {};
	daily: any = [];
	weekly: any = [];
	monthly: any = [];

	sectionOne: any;
	sectionThree: any;

	dailyPriceFeeOne: number;
	dailyPriceFeeTwo: number;
	weeklyPriceFeeOne: number;
	weeklyPriceFeeTwo: number;
	monthlyPriceFeeOne: number;
	monthlyPriceFeeTwo: number;

	dailyFeeOne: any;
	dailyFeeTwo: any;
	weeklyFeeOne: any;
	weeklyFeeTwo: any;
	monthlyFeeOne: any;
	monthlyFeeTwo: any;

	dailyDataOne: any;
	dailyDataTwo: any;
	weeklyDataOne: any;
	weeklyDataTwo: any;
	monthlyDataOne: any;
	monthlyDataTwo: any;

	dailyActiveOne: any;
	dailyActiveTwo: any;
	weeklyActiveOne: any;
	weeklyActiveTwo: any;
	monthlyActiveOne: any;
	monthlyActiveTwo: any;

	dailyValidityOne: any;
	dailyValidityTwo: any;
	weeklyValidityOne: any;
	weeklyValidityTwo: any;
	monthlyValidityOne: any;
	monthlyValidityTwo: any;
	phone: string;
	plan: string;
	profile: any = {};
	type: string;
	getAmount: string;
	errorMsg: string;
	isLoggedin: string;
	getId: any = {};
	topUpPrice: number;
	newIcon = 'assets/new-en.png';
	fee: string;
	code: string;
	internet: string;
	amount: string;
	buyMore: string;
	loading: string;
	checkSimType: string;
	packageCode: string;
	canSubscribe:boolean;

	// tslint:disable-next-line:no-inferrable-types
	public fixed: boolean = false;
	UserId: string;
	getType: any;
	constructor(
		private productServiceloyKob: ProductsServicesV2Service,
	) { }
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (window.scrollY > 450) {
			this.fixed = true;
		} else if (this.fixed && window.scrollY < 500) {
			this.fixed = false;
		}
	}
	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.checkSimType = localStorage.getItem('type');
		this.getUserId();
		this.getType = localStorage.getItem('simType');
		if (this.checkSimType !== 'off-net') {
			this.UserId = localStorage.getItem('UserId');
		}
		switch (this.language) {
			case 'km':
				this.newIcon = 'assets/new-kh.png';
				break;
			case 'zh':
				this.newIcon = 'assets/new-en.png';
				break;
			default:
				this.newIcon = 'assets/new-en.png';
		}

		this.plan = localStorage.getItem('plan');
		this.phone = localStorage.getItem('phone');
		this.isLoggedin = localStorage.getItem('isLoggedin');
		this.topUpPrice = Number(localStorage.getItem('top-up-price'));

		this.getDataPack();
		this.getUserSubscript();
		this.getAppProducts();
	}

	/**
	 * Check internet price
	 * @param price
	 */
	checkInternetPrice(price) {
		let getPrice: number;
		if (price.includes('$')) {
			getPrice = Number(price.replace('$', ''));
		} else {
			const str = Number(price.replace(/[^0-9.]/g, ''));
			getPrice = str / 100;
		}
		return getPrice;
	}

	getUserId() {
		this.phone = localStorage.getItem('phone');
		this.productServiceloyKob.getUserId(this.phone).subscribe((data) => {
			this.UserId = data._id;
			localStorage.setItem('UserId', this.UserId);
		});
	}

	getUserSubscript() {
		const userId = localStorage.getItem('UserId');
		this.productServiceloyKob.getUserSubscriptionLoykob(userId).subscribe((data) => {
			this.packageCode = data.packageCode;
			console.log(this.packageCode);
		});
	}

	getAppProducts(){
		this.phone = localStorage.getItem('phone');
		this.productServiceloyKob.getAppProducts(this.phone).subscribe((res)=>{
			const checkCanSubs = _.find(res, (data)=> data.code === "loy-kobs");
			if(checkCanSubs){
				const canSubs:boolean = checkCanSubs.canSubscribe;
				this.canSubscribe = canSubs == false? true: false;
			}
		})
	}

	/**
	 * Show popup buy internet
	 * @param fee
	 * @param internet
	 * @param amount
	 */
	buyInternetPack(fee, internet, code) {
		if (!this.phone || !this.isLoggedin) {
			(<any>$('#preventLogin')).modal('show');
		} else {
			(<any>$('#exampleModalCenter2')).modal('show');
		}
		this.fee = fee;
		this.code = code;
		this.internet = internet;
		this.amount = String(this.checkInternetPrice(fee));
	}
	/**
	 * Submit buy internet
	 */
	onSubmitBuy() {
		this.loading = 'loading';
		this.productServiceloyKob
			.buyLoykob({ userId: this.UserId, packageCode: this.code, amount: +this.amount })
			.subscribe(
				(data) => {
					this.dataPack = data;
					this.loading = '';
					localStorage.setItem('packType', this.type);
					localStorage.setItem('data', this.dataPack);
					swal({
						title: 'Successful',
						text: this.preventTranslate('Your subscription was successful.'),
						type: 'success',
						confirmButtonColor: '#ec008c',
					});
				},
				(err) => {
					this.loading = '';
					this.errorMsg = err.error.message;
					swal({

						title: 'Error',
						text: this.errorMsg,
						type: 'error',
						confirmButtonColor: '#ec008c',
					});
				}
			);
	}

	/**
	 * Get data table on internet pack with api
	 */
	getDataPack() {
		this.productServiceloyKob.getPlanPackageV2(this.language, 'loy-kobs').subscribe((res) => {
			this.dataPack = res;
			this.sectionOne = this.dataPack.section1;
			this.sectionTwo = this.dataPack.section2;
			this.sectionThree = this.dataPack.section3;
			this.description = this.sectionThree.description.replace(/- /g, ' ');
			this.desktopImage = `${this.sectionOne.desktopImageUrl}`;
			this.mobileImage = `${this.sectionOne.mobileImageUrl}`;
			$('.seatel-top-up').css('background-image', `url(${this.sectionOne.backgroundLoykobUrl})`);
			this.desktopBottomImageUrl = `${this.sectionOne.featureImageUrl}`;
			this.mobileBottomImageUrl = `${this.sectionOne.featureImageUrl}`;

			const table = _.chain(this.sectionTwo.table)
				.groupBy('datapack')
				.map((value, key) => value)
				.value();

			this.weekly = table[0];
			this.monthly = table[1];
			console.log(this.UserId);

			console.log('cccc', this.sectionOne.backgroundLoykobUrl);
			console.log('desktop', this.desktopImage);
			console.log('mobile', this.mobileImage);
		});
	}

	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
	}

}
