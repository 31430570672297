import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoykobComponent } from './loy-kob.component';
import { addOnPackageComponent } from './add-on-package/add-on-package.component';
import { LoykobRoutingModule } from './loy-kob-routing.module';
import { SharedPipesModule } from '../../../shared/pipes/shared-pipes.module';
import { LoyKobPageComponent } from './loy-kob-page/loy-kob-page.component';

@NgModule({
	imports: [CommonModule, LoykobRoutingModule, SharedPipesModule],
	declarations: [LoykobComponent, addOnPackageComponent, LoyKobPageComponent]
})
export class LoykobModule {}
