import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { AboutPageService } from '../../../service/about-page.service';
@Component({
	selector: 'app-about-page',
	templateUrl: './about-page.component.html',
	styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
	keywords = {};
	language: string;
	mainAbout: any;
	mainAboutSection1: any = [];
	mainAboutSection2: any = [];

	constructor(private translate: TranslateService, private aboutPageService: AboutPageService) {
		const getLanguage = localStorage.getItem('language') || 'en';
		translate.setDefaultLang(getLanguage);
		translate.use(getLanguage);
		localStorage.setItem('language', getLanguage);
		this.language = getLanguage;
	}

	ngOnInit() {
		setTimeout(() => {
			this.showVideo();
		}, 2500);
		this.getMainAbout();

		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
	}
	getMainAbout() {
		this.aboutPageService.getMainAbout(this.language).subscribe(data => {
			this.mainAbout = data;
			this.mainAboutSection1 = this.mainAbout.section1;
			this.mainAboutSection2 = this.mainAbout.section2;
		});
	}
	showVideo() {
		$('#display').css('display', 'none');
		$('#player').css('display', 'block');
	}

	playVideo() {
		$('#play-video').trigger('click');
	}

	preventTranslate(keyword) {
		return this.keywords && this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}
}
