import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AddOnsRoutingModule } from './add-ons-routing.module';
import { InternationalCallComponent } from './international-call/international-call.component';
import { AddOnsComponent } from './add-ons.component';
import { ValuePackComponent } from './value-pack/value-pack.component';
import { InternetPackageComponent } from './internet-pack/internet-pack.component';
import { SpecialNumberComponent } from './special-number/special-number.component';
import { SelectModule } from 'ng-select';
import { TengbPromotionComponent } from './tengb-promotion/tengb-promotion.component';


@NgModule({
    declarations: [
        ValuePackComponent,
        InternationalCallComponent,
        AddOnsComponent,
        InternetPackageComponent,
        SpecialNumberComponent,
        TengbPromotionComponent
    ],
    imports: [CommonModule, AddOnsRoutingModule, SelectModule, FormsModule]
})
export class AddOnsModule {}
