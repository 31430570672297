import { Injectable } from '@angular/core';
import { GlobleService } from './globle.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class CartsServiceService {
	url = this.api.apiUrl;
	header: any = this.api.getHeader();

	constructor(private api: GlobleService, private http: HttpClient) { }

	/**
	 * Get cart with api
	 * @param phoneNumber
	 */
	getCarts(phoneNumber): Observable<any> {
		return this.http.get(
			this.url + '/carts?phoneNumber=' + phoneNumber + '&limit=100',
			{
				headers: this.header
			}
		);
	}

	/**
	 * Delete cart with api
	 * @param id
	 * @param phone
	 */
	deletetCart(id, phone): Observable<any> {
		return this.http.delete(
			this.url + '/carts/items/' + id + '?phoneNumber=' + phone,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Update cart with api
	 * @param id
	 * @param obj
	 */
	updateCart(id, obj: any): Observable<any> {
		return this.http.put(this.url + '/carts/items/' + id, obj, {
			headers: this.header
		});
	}

	/**
	 * Add cart with api
	 * @param obj
	 */
	addCart(obj: any): Observable<any> {
		console.log('aaa', obj);
		return this.http.post(this.url + '/carts', obj, {
			headers: this.header
		});
	}

	/**
	 * Get certification in success page with api
	 */
	getCertification() {
		return this.http.get(this.url + '/certifications', {
			headers: this.header
		});
	}

	/**
	 * Submit order in order summary page with api
	 * @param obj
	 */
	order(obj) {
		return this.http.post(`${this.url}/orders`, obj, {
			headers: this.header
		});
	}

	/**
	 * Update order in success page with api
	 * @param orderId
	 * @param obj
	 */
	updateOrder(obj) {
		return this.http.put(`${this.url}/orders`, obj, {
			headers: this.header
		});
	}
	putDelivery(phone, obj) {
		return this.http.put(`${this.url}/users/${phone}/order-service-info`, obj, {
			headers: this.header
		});
	}
	getDelivery(phone) {
		return this.http.get(`${this.url}/users/${phone}/order-service-info`, {
			headers: this.header
		});
	}
}
