import { Component, OnInit, HostListener } from "@angular/core";
import { ProductsServicesService } from "../../../service/products-services.service";
import { GlobleService } from "../../../service/globle.service";
import { DevicesService } from "../../../service/devices.service";
@Component({
	selector: "app-one-plan",
	templateUrl: "./loy-kob.component.html",
	styleUrls: ["./loy-kob.component.scss"]
})
export class LoykobComponent implements OnInit {

	constructor() { }
  
	ngOnInit() {
	}
  
  }
  