import { Injectable } from '@angular/core';
import { GlobleService } from './globle.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UploadServiceService {
	url = this.api.apiUrl;

	constructor(private api: GlobleService, private http: HttpClient) { }

	/**
	 * Upload files to server
	 * @param files
	 * @param type
	 */
	uploadFile(files: FileList, type = 'any') {
		const data = new FormData();
		for (let i = 0; i <= files.length; i++) {
			data.append('files', files[i]);
		}
		data.append('type', type);
		return this.http.post(this.url + '/uploads/images', data, {
			headers: {
				'x-api-key':
					'eyJhbGciOiJIUzI1NiJ9.c2VhdGVsLWFwaS1kZXY.NsFhP0C7pqeONbcGAf42lQLLe6AIgy1QdOUOrg0uqcI'
			}
		});
	}
}
