import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginServiceService } from '../../service/login-service.service';

import { AuthService } from 'angularx-social-login';
import { FacebookLoginProvider, LinkedInLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { environment } from '../../../environments/environment';
import { CartsServiceService } from '../../service/carts-service.service';
import { DataSharingService } from '../../service/data-sharing.service';
import { MessagingService } from '../../service/messaging.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    keywords = {};
    language: string;
    socialType: string;
    submitted = false;
    msg: string;
    accessToken = null;
    code = null;
    client_id = '1205132979976871'; // 990602627938098
    client_secret = '0c204ee074383edc3ddae657e2d67358';
    redirect_uri = environment.redirectUrl + '/';
    phoneNumber: string;

    private user: SocialUser;
    private loggedIn: boolean;

    previent = {};

    frmLogin: FormGroup;
    phonePattern = /^\d+$/;
    pageUrl: string;
    getCartList: any = {};
    cartList: any;
    getSimCardList: any = [];
    itemId: string;
    cartDeviceitems: any;
    buyCartList: any;
    orderScratchCards: any = [];
    getUrlscratchCardIcon: any;
    getUrl: any;
    buyScratchCards: any = [];
    buySimCards: any = [];
    simCards: any = [];
    devices: any = [];
    scratchCards: any = [];
    orderSimCards: any = [];
    orderDevices: any = [];
    quantity: number;
    totalPrice = 0;
    resCart: any = {};
    carts: any = [];
    orderItem: any = [];
    emptyCart = false;
    firebaseToken;
    constructor(
        private router: Router,
        private loginService: LoginServiceService,
        private authService: AuthService,
        private cartService: CartsServiceService,
        private data: DataSharingService,
        private messagingService: MessagingService
    ) {}

    ngOnInit() {
        /**
         * Check if Already Logged in
         */
        this.messagingService.requestPermission();
        this.firebaseToken = localStorage.getItem('firebaseToken');
        this.pageUrl = localStorage.getItem('pageUrl');
        this.phoneNumber = localStorage.getItem('phone');
        const isLoggedin = localStorage.getItem('isLoggedin');
        const confirmProfile = localStorage.getItem('confirmProfile');

        // const identifyUser = this.loginService.identifyUser();
        // console.log(identifyUser);

        if (localStorage.getItem('type') === 'off-net' && this.phoneNumber && !this.pageUrl) {
            this.router.navigate(['/user/profile']);
        } else if (this.phoneNumber && !this.pageUrl) {
            if (confirmProfile === '/user/profile/confirm-user') {
                this.router.navigate([confirmProfile]);
            } else {
                this.router.navigate(['/user/dashboard']);
            }
        } else if (isLoggedin && this.phoneNumber && this.pageUrl) {
            this.getCartList = JSON.parse(localStorage.getItem('cartLists'));
            this.buyScratchCards = JSON.parse(localStorage.getItem('buyScratchCards'));
            if (this.buyScratchCards) {
                this.cartService.getCarts(this.phoneNumber).subscribe((res) => {
                    this.buyCartList = res;
                    this.getUrl = this.buyCartList.scratchCardIcon;
                    this.buyScratchCards.forEach((x) => {
                        x.imageUrl = this.getUrl;
                    });
                    localStorage.setItem('buyScratchCards', JSON.stringify(this.buyScratchCards));
                });
            }
            this.buySimCards = JSON.parse(localStorage.getItem('buySimCards'));
            if (this.buySimCards) {
                this.cartService.getCarts(this.phoneNumber).subscribe((res) => {
                    this.buyCartList = res;
                    this.buySimCards.forEach((x) => {
                        x.simIcon = this.buyCartList.simIcon;
                        x.topUpIcon = this.buyCartList.topUpIcon;
                        x.simPriceIcon = this.buyCartList.simPriceIcon;
                    });
                    localStorage.setItem('buySimCards', JSON.stringify(this.buySimCards));
                });
            }
            this.getSimCardList = JSON.parse(localStorage.getItem('simCardList'));
            this.cartDeviceitems = JSON.parse(localStorage.getItem('cartDeviceitems'));
            this.itemId = localStorage.getItem('itemId');
            if (this.getCartList) {
                this.cartList = {
                    phoneNumber: this.phoneNumber,
                    items: this.getCartList
                };
                this.cartService.addCart(this.cartList).subscribe(
                    (res) => {
                        this.data.updateBadgeCount();
                        this.getCarts();
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            } else if (this.getSimCardList) {
                this.getSimCardList = {
                    phoneNumber: this.phoneNumber,
                    dynamicCardPrice: true,
                    promotionItem: this.itemId,
                    items: this.getSimCardList
                };
                this.cartService.addCart(this.getSimCardList).subscribe(
                    (res) => {
                        this.data.updateBadgeCount();
                        this.getCarts();
                    },
                    (err) => {
                        console.log(err);
                        (<any>$('#prventSamePhoneNumber')).modal('show');
                    }
                );
            } else if (this.cartDeviceitems) {
                this.cartDeviceitems = {
                    phoneNumber: this.phoneNumber,
                    items: this.cartDeviceitems
                };
                this.cartService.addCart(this.cartDeviceitems).subscribe(
                    (res) => {
                        this.data.updateBadgeCount();
                        this.getCarts();
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            }
            setTimeout(() => {
                this.router.navigate([this.pageUrl]);
            }, 1000);
        }

        if (isLoggedin && this.phoneNumber && this.pageUrl) {
            this.router.navigate([this.pageUrl]);
        }

        this.language = localStorage.getItem('language') || 'en';
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));

        this.frmLogin = new FormGroup({
            phoneNumber: new FormControl('', [
                Validators.required,
                Validators.pattern(this.phonePattern),
                Validators.minLength(9),
                Validators.maxLength(15)
            ]),
            password: new FormControl('', Validators.required)
        });

        localStorage.removeItem('socialRegister');
        localStorage.removeItem('socialId');
        localStorage.removeItem('socialType');
        localStorage.removeItem('forgot-step');
        localStorage.removeItem('forgot-phoneNumber');
        localStorage.removeItem('forgot-code');
    }

    /**
     * Form control validation
     */
    get f() {
        return this.frmLogin.controls;
    }
    /**
     * Get all cart list
     */
    getCarts() {
        if (!this.phoneNumber) {
            return;
        }
        this.simCards = [];
        this.orderItem = [];
        this.devices = [];
        this.totalPrice = 0;
        this.scratchCards = [];
        this.cartService.getCarts(this.phoneNumber).subscribe(
            (res) => {
                this.resCart = res;
                this.carts = res.data;
                this.updateCardPrices();
                for (const i of this.carts) {
                    this.orderItem.push(i._id);
                    this.totalPrice += i.totalPrice;
                }
                if (this.carts.length === 0) {
                    this.emptyCart = true;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
    updateCardPrices() {
        let availableDiscounts = this.carts.reduce((acc, crr) => acc + (crr.type === 'device' ? crr.quantity : 0), 0);
        const simCards = this.carts.filter((cart) => cart.type === 'sim-card');
        if (availableDiscounts > 0) {
            simCards.forEach((simCard) => {
                simCard.totalPrice =
                    availableDiscounts > 0 ? simCard.totalPrice - simCard.cardPrice : simCard.totalPrice;
                simCard.cardPrice = availableDiscounts > 0 ? simCard.discountedCardPrice : simCard.cardPrice;
                availableDiscounts--;
            });
        }
    }
    /**
     * Translation
     */
    preventTranslate(keywords) {
        return this.keywords && this.keywords[keywords] ? this.keywords[keywords][this.language] : keywords;
    }

    /**
     * Sign in with facebook
     */
    signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
        this.authService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = user != null;

            if (this.user) {
                this.socialType = this.user.provider.toLowerCase();
                this.socialLogin(this.user.id, this.socialType);
            }
        });
    }

    /**
     * Sign in with Instagram
     */
    signInWithInstagram() {
        const popupWidth = 600,
            popupHeight = 700,
            popupLeft = (window.screen.width - popupWidth) / 2,
            popupTop = (window.screen.height - popupHeight) / 2;
        const popup = window.open(
            'https://api.instagram.com/oauth/authorize/?client_id=' +
                this.client_id +
                '&redirect_uri=' +
                this.redirect_uri +
                '&response_type=token',
            '',
            'width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupLeft + ',top=' + popupTop + ''
        );
        const interval = setInterval(() => {
            try {
                if (popup.location.hash.length) {
                    clearInterval(interval);
                    this.accessToken = popup.location.hash.slice(14);
                    this.loginService.instagramLogin(this.accessToken).subscribe((res) => {
                        let data;
                        data = res;
                        this.user = data.data;
                        if (this.user) {
                            this.socialType = 'instagram';
                            this.socialLogin(this.user.id, this.socialType);
                        }
                    });
                    popup.close();
                }
            } catch (evt) {}
            if (popup.closed) {
                clearInterval(interval);
            }
        }, 100);
    }

    signOut(): void {
        this.authService.signOut();
    }

    /**
     * Login with Phone number
     */
    async onLoggedin() {
        this.submitted = true;
        const phoneNumber = this.frmLogin.get('phoneNumber').value;
        const password = this.frmLogin.get('password').value;

        if (this.frmLogin.invalid) {
            return;
        }
        const response = await this.loginService.login(phoneNumber, password,this.firebaseToken);
        if (!response.phoneNumber) {
            this.msg = response.message;
            return;
        }
        /**
         * Set expire login to localStorage
         */
        const expires = 2 * 60 * 60;
        const now = Date.now();
        const schedule = now + expires * 1000;

        localStorage.setItem('isLoggedinExpire', String(schedule));
        localStorage.setItem('isLoggedin', 'true');
        localStorage.setItem('main-phone', this.toLocalPhoneNumber(phoneNumber));
        localStorage.setItem('phone', this.toLocalPhoneNumber(phoneNumber));
        if (response.type === 'on-net') {
            localStorage.setItem('type', response.subscriptionPlan.type);
        }
        if (response.type === 'postpaid') {
            console.log('postpad ok');
        }
        // console.log(response);
        location.reload();

        // if (this.pageUrl) {
        //     this.router.navigate([this.pageUrl]);
        // } else if (response.type === 'off-net') {
        //     this.router.navigate(['/user/profile']);
        // } else {
        //     this.router.navigate(['/user/dashboard']);
        // }
    }

    /**
     * Social login
     * @param socialId verify social Id
     * @param socialType social type
     */
    socialLogin(socialId, socialType) {
        this.loginService.socialLogin(socialId, socialType).subscribe((res) => {
            let checkStatus;
            let data: any;

            data = res;

            checkStatus = data.status;

            if (checkStatus === false) {
                this.router.navigate(['/register']);
                this.msg = data.message;
                localStorage.setItem('socialRegister', 'social-register');
                localStorage.setItem('socialId', socialId);
                localStorage.setItem('socialType', socialType);
            } else if (checkStatus === true) {
                const expires = 2 * 60 * 60;
                const now = Date.now();
                const schedule = now + expires * 1000;
                localStorage.setItem('isLoggedinExpire', String(schedule));
                localStorage.setItem('isLoggedin', 'true');
                localStorage.setItem('main-phone', this.toLocalPhoneNumber(data.phoneNumber));
                localStorage.setItem('phone', this.toLocalPhoneNumber(data.phoneNumber));
                localStorage.setItem('type', data.type);
                if (data.type === 'off-net') {
                    this.router.navigate(['/user/profile']);
                } else {
                    this.router.navigate(['/user/dashboard']);
                }
                location.reload();
            }
        });
    }

    /**
     * 855189494385 => 0189494385
     * @param mSISDN phone number
     */
    toLocalPhoneNumber(mSISDN: string) {
        if (typeof mSISDN === 'string') {
            mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
            mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
        }

        console.log(mSISDN);

        return mSISDN;
    }
}
