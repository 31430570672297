/**
 * 0189494385 => 855189494385
 * 189494385  => 855189494385
 * 855189494385 => 855189494385
 */
export const ToPhoneNumber = (v: string) => {
	if (typeof v === 'string') {
		v = v.slice(0, 4) === '8550' ? v.slice(3) : v;
		v = v.slice(0, 1) === '0' ? v.slice(1) : v;
		return v.slice(0, 3) !== '855' ? '855' + v : v;
	}
};
