// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	// apiUrl: 'https://api.yes.com.kh',
	apiUrl: 'http://api-dev.yes.com.kh',
	// apiUrl: 'http://localhost:3000', // Local Machine
	// apiUrl: 'http://192.168.17.210:3000'

	// redirectUrl: 'https://yes.com.kh'
	redirectUrl: 'http://dev.yes.com.kh',

	firebase: {
		apiKey: "AIzaSyAQ56EH-LN7X0tAOhPEPHwgjYVbM3Ui7CU",
		authDomain: "demonotification-45836.firebaseapp.com",
		projectId: "demonotification-45836",
		storageBucket: "demonotification-45836.appspot.com",
		messagingSenderId: "832316377454",
		appId: "1:832316377454:web:6915ccac4e610075b7197f",
		measurementId: "G-C92YVFYBVZ"
	  }
};
